import React, { Fragment } from "react";
import Dialog from '@material-ui/core/Dialog';
import "./BetterDialog.scss";

export default class BetterDialog extends React.Component {
	render() {
		let { visible, open, isScrollable, label, title, titleClassName, children, ...rest } = this.props;

		if (visible === false || open === false)
			return (<Fragment />)

		let overflow = "auto";

		if (isScrollable === false) {
			overflow = "hidden";
		}

		if (!Array.isArray(children)) {
			children = [children]
		}

		const mainChildren = [];
		const buttonChildren = [];

		React.Children.forEach(children, (child) => {
			if (child && child.type === BetterDialogButtons) {
				buttonChildren.push(child);
				return;
			}

			mainChildren.push(child);
		});

		return (
			<Dialog open maxWidth={false} {...rest}>
				{title ? (
					<div className="dialog-header">
						<h3 className={titleClassName} >{title}</h3>
					</div>
				)
					: (<Fragment />)
				}
				<div style={{ overflow: overflow }} onKeyDown={this.onKeyDown}>
					<div className="dialog-inner">
						<div style={{ overflow: overflow }}>
							{mainChildren}
						</div>
					</div>
				</div>
				{buttonChildren && buttonChildren.length > 0 ? (
					<div className="dialog-footer">
						{buttonChildren}
					</div>
				)
					: (<Fragment />)
				}
			</Dialog >
		);
	}

	onKeyDown = (e) => {
		if (e && e.key === "Escape") {
			if (!e.isPropagationStopped() && !e.isDefaultPrevented()) {
				e.preventDefault();
				e.stopPropigation = true;
			}
		}
	}
}

export class BetterDialogButtons extends React.Component {
	render() {
		return (
			<div className="button-bar" {...this.props}>
				{this.props.children}
			</div>
		);
	}
}