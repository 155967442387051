import React from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import queryString from "query-string";
import _ from "lodash";

export default class DownloadFile extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		const queryStrings = queryString.parse(window.location.search);
		let isView = queryStrings["view"];
		isView = isView && (isView === "1" || isView.toLowerCase() === "true")

		this.state = {
			isInitialized: false,
			isLoading: true,
			entitySummary: null,
			fileDetails: null,
			isView
		};

		this.downloadFile = this.downloadFile.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	render() {
		const actionBase = this.state.isView ? "Viewing" : "Downloading";
		const action = `${actionBase} File`;
		const heading = `${action}: ${(this.state.fileDetails ? this.state.fileDetails.Filename : "")}...`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		return (
			<Layout config={this.props.config} subtitle={heading} showCuiBanner={((this.state.entitySummary || {}).Tags || []).includes("CUI")}>
				<h1>{heading}</h1>
				<LoadingWrapper isLoading={this.state.isLoading}>
					{this.state.fileDetails ?
						<div>
							<h4>Entity: '{this.state.entitySummary.Name}'</h4>
							<h4>{actionBase} '{this.state.fileDetails.Filename}'...</h4>
						</div>
						: <span>File not found. Invalid entity or file GUID.</span>}
				</LoadingWrapper>
			</Layout >
		);
	}

	initialize() {
		this.apiService.getFileDetails(this.props.match.params.entityGuid, this.props.match.params.fileGuid,
			(response) => {
				const entitySummary = response.data.Entity;
				const fileDetails = response.data.FileDetails;

				_.defer(() => { this.downloadFile(fileDetails.EntityGuid, fileDetails.FileGuid, this.state.isView) });

				this.setState({
					isLoading: false,
					entitySummary,
					fileDetails
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);
		this.setState({ isInitialized: true });
	}

	downloadFile(entityGuid, fileGuid, isView) {
		this.apiService.downloadFile(entityGuid, fileGuid, isView)
	}
}
