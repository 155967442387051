import React, { Fragment } from "react";
import _ from "lodash";
import clsx from "clsx";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default class TextArea extends React.Component {
	constructor(props) {
		super(props);

		this.onKeyDown = this.onKeyDown.bind(this);
		this.ref = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.autoFocus && prevProps.autoFocus !== this.props.autoFocus) {
			if (this.ref.current)
				_.defer(() => { this.ref.current.focus(); }); // Set focus
		}
	}

	render() {
		let { errorMessage, value, previousValue, showDirty, forceShowDirty, className, ...rest } = this.props;

		value = this.getStringValue(value);
		previousValue = this.getStringValue(previousValue);

		if ((forceShowDirty || (showDirty && previousValue !== value)) && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<Fragment>
				<TextareaAutosize
					ref={this.ref} value={value}
					className={className}
					onKeyDown={(e) => this.onKeyDown(e, this.props.onKeyDown, this.props.onChange, previousValue)}
					{...rest}
				/>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}

	getStringValue(value) {
		if (value === undefined || value === null)
			value = "";
		else
			value = `${value}`; // Cast to a string

		return value;
	}

	onKeyDown(e, onKeyDown, onChange, newValue) {
		if (e && e.key === "Escape") {
			e.target.value = newValue;
			if (onChange)
				onChange(e);

			e.preventDefault();
			return false;
		}

		if (onKeyDown)
			onKeyDown(e);
	}
}