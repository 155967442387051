import React from "react";
import Select from "./Select"
import clsx from "clsx";

export default class SelectFileType extends React.Component {
	constructor(props) {
		super(props);

		this.getFileTypeOptions = this.getFileTypeOptions.bind(this);
	}

	render() {
		let { fileTypeNames, value, previousValue, showDirty, className, ...rest } = this.props;

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<Select
				className={className}
				value={value}
				options={this.getFileTypeOptions(fileTypeNames)}
				{...rest} />);
	}

	getFileTypeOptions(fileTypeNames) {
		return (fileTypeNames || []).map((fileTypeName, key) => {
			return { value: fileTypeName, key: key, label: fileTypeName === "" ? "-" : fileTypeName };
		});
	}
}