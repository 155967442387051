import React from "react";
import { Redirect } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useApiService } from "../Hooks/useApiService";
import LoadingWrapper from "../Shared/LoadingWrapper";
import { handleApiError } from "../Shared/ErrorHandlers";
import { UserContext } from "../Contexts/UserContext";
import setStateAsync from "../Helpers/setStateAsync";
import _ from "lodash";

export default class Layout extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			isLoading: !(this.props.requireWebUserSession === false),
			doesNeedToLogin: false,
			activeGetWebUserSessionPromise: null,
			returnUrl: null,
			prevWebUserSession: null
		};

		this.apiService = useApiService(props.config.apiBaseUrl);
		this.setStateAsync = setStateAsync.bind(this);
	}

	componentDidMount() {
		if (this.props.useApi === false)
			return;

		this.verifyWebUserSession();
	}

	componentDidUpdate() {
		if (this.props.useApi === false)
			return;

		// Track webUserSession changes to avoid a conflict between possible new login session and any existing session that we might be available from the server
		const webUserSession = this.context.getWebUserSession();
		if (webUserSession !== this.state.prevWebUserSession
			&& (this.state.prevWebUserSession == null || (this.state.prevWebUserSession !== webUserSession && (!webUserSession || !webUserSession.needsRefresh)))) {
			// The webUserSession changed...
			const newState = { prevWebUserSession: webUserSession };
			if (this.state.prevWebUserSession !== null) {
				// Ignoring any active API request for getWebUserSession to prevent the background update from clobbering a login or logout event by
				newState.activeGetWebUserSessionPromise = null
			}
			this.setState(newState);
		}
	}

	verifyWebUserSession() {
		document.title = this.props.config.appName + (!!this.props.subtitle ? ` - ${this.props.subtitle}` : "");

		const webUserSession = this.context.getWebUserSession();

		if (!!webUserSession) {
			this.setState({ isLoading: false });

			if (!webUserSession.needsRefresh)
				return; // The user session exists and does not need to be refreshed
		}

		// The web user session is missing or needs refresh. Try to get it now
		const promise = this.apiService.getWebUserSession(
			(response) => {
				if (promise !== this.state.activeGetWebUserSessionPromise)
					return;

				// Get the server to warm up an extra API instance to prevent potential cold starts
				//_.delay(this.apiService.ping, 20); // The short delay ensures any other application API request is executed first

				this.context.setWebUserSession(response.data.WebUserSession);
				this.setState({ isLoading: false, activeGetWebUserSessionPromise: null });
			},
			async (error) => {
				if (promise !== this.state.activeGetWebUserSessionPromise)
					return;

				console.error(error);
				if (!!error.response) {
					if (error.response.status === 401) {
						// User is not logged in. Redirect to the login page.

						this.setState({
							isLoading: false,
							activeGetWebUserSessionPromise: null,
							doesNeedToLogin: true,
							returnUrl: this.props.requireWebUserSession !== false ? `${window.location.pathname}${window.location.search}${window.location.hash}` : ""
						});

						this.context.setWebUserSession(null);
						return;
					}
				}

				await this.setStateAsync.bind(this)({ isLoading: false, isServiceFailed: true });
				_.defer(() => { handleApiError(error); });
			}
		);

		this.setState({ activeGetWebUserSessionPromise: promise });
	}

	render() {
		document.title = this.props.config.appName + (!!this.props.subtitle ? ` - ${this.props.subtitle}` : "");

		if (!this.state.isLoading && this.state.doesNeedToLogin && this.props.requireWebUserSession !== false)
			return (<Redirect to={`/login?returnUrl=${encodeURI(this.state.returnUrl)}`} />);

		if (this.state.isServiceFailed)
			return (
				<div>
					<h2>Unable to communicate with the web API server.</h2>
					<h2>Please refresh or try again later.</h2>
				</div>
			);

		return (
			<LoadingWrapper isLoading={this.state.isLoading} style={{ margin: "0 50% 0 50%", position: "absolute", top: "30%" }}>
				<Header
					config={this.props.config}
					disableUserPermissions={this.props.requireWebUserSession === false}
					redirectIfSingleMenuItem={this.props.redirectIfSingleMenuItem}
					showCuiBanner={this.props.showCuiBanner}
					useApi={this.props.useApi} />
				<div className="main fw-vp">
					<div className="app">
						{this.props.children}
					</div>
				</div >
				<Footer
					appName={this.props.config.appName}
					appVersion={this.props.config.appVersion}
					appCommitHash={this.props.config.appCommitHash}
				/>
			</LoadingWrapper>
		);
	}
}
