import React, { Fragment } from "react";
import clsx from "clsx";

export default class Select extends React.Component {
	render() {
		let { errorMessage, value, previousValue, showDirty, options, className, ...rest } = this.props;

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<Fragment>
				<select className={className} value={value} {...rest}>
					{this.getOptionElements(options)}
				</select>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}

	getOptionElements(options) {
		if (!options)
			return [];

		return options.map((option) => {
			return (<option value={option ? option.value || "" : ""} key={option ? (option.key !== undefined ? option.key || "" : option.value || "") : ""}> {option ? option.label || "" : ""}</option >);
		});
	}

}