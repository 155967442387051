import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "./BetterDialog";
import ViasatLogoSpinner from "./ViasatLogoSpinner";
import _ from "lodash";
import "./Auth.scss";

export default class Auth extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastClickTime: null,
			clickCount: 0,
			showDialog: false
		};
		this.maxClickDelayMs = 1000;
		this.clickCountRequired = 10;
	}

	handleClick = (e) => {
		let showDialog = false;
		let clickCount = 1;
		if (!this.state.lastClickTime || this.state.lastClickTime + this.maxClickDelayMs >= new Date().getTime()) {
			clickCount = this.state.clickCount + 1;
		}

		if (clickCount === this.clickCountRequired) {
			showDialog = true;
			_.delay(() => this.setState({ allowTitleSelect: true }), 1000);
			if (window.getSelection) {
				window.getSelection().removeAllRanges();
			}
		}

		this.setState({ allowTitleSelect: false, lastClickTime: new Date().getTime(), clickCount, showDialog });

		return false;
	}


	render() {
		return (
			<Fragment>
				<span onClick={this.handleClick} {...this.props}>{this.props.children}</span>
				<BetterDialog titleClassName={`glitchy ${this.state.allowTitleSelect ? "" : "prevent-select"}`} title={atob("Q3JlYXRlZCBieSBEb3VnbGFzIEtyYWhtZXI=")} open={this.state.showDialog}>
					<div style={{ textAlign: "center", margin: "20px", overflow: "hidden" }} >
						<ViasatLogoSpinner className="glitchy2" />
					</div>
					<BetterDialogButtons style={{ textAlign: "center" }}>
						<button className="glitchy3" onClick={() => this.setState({ showDialog: false })}>Close</button>
					</BetterDialogButtons>
				</BetterDialog>
			</Fragment>
		);
	}
}