import React, { Fragment } from "react";
import Layout from "../../Layout/Layout";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import EntryRow from "../../Shared/EntryRow";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "../../Shared/Select";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class UserProfileChooseUser extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			domainOptions: null,
			virtualDomainNames: null,
			selectedDomainName: null,
			isSelectedVirtualDomain: null,
			selectedUsername: null,
			selectedUserSummary: null,
			users: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.getDomainOptions = this.getDomainOptions.bind(this);
		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);
		this.isValidNewUser = this.isValidNewUser.bind(this);	

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	getDomainOptions() {
		if (this.state.domainOptions)
			return this.state.domainOptions;

		if (!this.state.isGettingDomainOptions) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingDomainOptions: true }));

			this.apiService.getDomains("",
				(response) => {
					const domainNames = ["", ...response.data.DomainNames, ...response.data.VirtualDomainNames];
					const virtualDomainNames = [...response.data.VirtualDomainNames];
					const domainOptions = domainNames.map(domainName => { return { value: domainName, label: domainName } });

					this.setState({
						isGettingDomainOptions: false,
						domainOptions,
						virtualDomainNames
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingDomainOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers(this.state.selectedDomainName, inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					return {
						value: domainUser.Username,
						label: `${domainUser.FirstName || ""} ${domainUser.LastName || ""} (${domainUser.Username})${domainUser.IsExistingAppUser ? "" : (" " + String.fromCharCode(0x2021))}`, // 0x2021 = double dagger (Dagger)
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
			}
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const values = { [name]: value };

		if (name === "selectedUsername") {
			values.selectedUserSummary = e.target.data;
		}
		else if (name === "selectedDomainName") {
			values.selectedUsername = null;
			values.selectedUserSummary = null;
			values.isSelectedVirtualDomain = this.state.virtualDomainNames.includes(value);
		}

		this.setState(values);
	}

	isValidNewUser(inputValue, selectValue, selectOptions, accessors) {
		if (!this.state.isSelectedVirtualDomain)
			return false; // only virtual users can be added

		if (!inputValue)
			return false; // Zero-length not allowed

		if (selectOptions && selectOptions.some(o => o.value.toLowerCase() === inputValue.toLowerCase()))
			return false; // Cannot create if it already exists

		if (!this.context.hasPermission("*", `Add Users (${this.state.selectedDomainName})`))
			return false; // Cannot create if no permission

		return true;
	}

	render() {
		if (this.state.selectedDomainName && this.state.selectedUsername) {
			const redirectUrl = `/userProfile/${this.state.selectedDomainName}/${this.state.selectedUsername}`;
			this.props.history.push(redirectUrl);
			return null;
		}

		const formatOptionLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}

		return (
			<Layout config={this.props.config} subtitle="Choose user to view/edit profile">
				<h1>Choose a user to view/edit profile</h1>
				<div className="entry-table-container user-entity-profile-edit-modal">
					<fieldset>
						<legend>User Search</legend>
						<div className="entry-table-inner-container">
							<table>
								<tbody>
									<EntryRow label="* Domain" title="Domain">
										<LoadingWrapper isLoading={!this.state.domainOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
											<Select
												name="selectedDomainName"
												value={this.state.selectedDomainName ?? ""}
												onChange={this.handleDataChange}
												options={this.getDomainOptions()}
												autoFocus={!this.state.selectedDomainName}
											/>
											{!this.state.isSelectedVirtualDomain ? <Fragment /> :
												<span> (virtual domain)</span>
											}
										</LoadingWrapper>
									</EntryRow>

									<EntryRow label="* Username" innerDivStyle={{ overflow: "visible" }}>
										<ComboBoxAsync
											name="selectedUsername"
											value={this.state.selectedUsername}
											loadOptions={_.debounce(this.loadUsernameOptions, 250)}
											isSearchable={true}
											onChange={this.handleDataChange}
											noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
											formatOptionLabel={formatOptionLabel}
											isValidNewOption={this.isValidNewUser}
											disabled={!this.state.selectedDomainName}
											autoFocus={!!this.state.selectedDomainName}
										/>
									</EntryRow>
									<EntryRow label="">
										&Dagger; = User does not currently have access to this application.
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
			</Layout>
		);
	}
}
