import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "./BetterDialog";
import TextArea from "./TextArea";
import { extractDomElement } from "../Helpers/SharedFunctions";

export default class ShowJsonModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			json: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.save = this.save.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const json = JSON.stringify(this.props.value, null, 4)
			this.setState({
				json,
				previousJson: json
			});
		}
	}

	render() {
		if (!this.props.isOpen)
			return <Fragment />;

		return (
			<BetterDialog title={this.props.heading || "JSON"} open={this.props.isOpen}>
				<TextArea
					name="json"
					value={this.state.json}
					previousValue={this.state.previousJson}
					showDirty={this.props.showDirty}
					title="JSON"
					onChange={this.handleDataChange}
					disabled={this.props.disabled}
					style={{ width: "800px" }}
				/>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment />
						: <button onClick={this.save}>OK</button>
					}
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		this.setState({ [name]: value });
	}

	save() {
		let value;
		try {
			value = JSON.parse(this.state.json);
		}
		catch (ex) {
			console.error(ex);
			alert(`Error parsing JSON: ${ex}`);
			return;
		}

		if (this.props.setValue(value))
			this.props.closeModal();
	}
}
