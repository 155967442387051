import React, { Fragment } from "react";
import TextArea from "./TextArea";
import { extractDomElement } from "../Helpers/SharedFunctions";
import "./RegExTester.scss";

export default class RegExTester extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditing: false,
			editingProperties: {},
			testValues: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.save = this.save.bind(this);
	}

	render() {
		let { regEx, testValues, previousTestValues, name, onChange, showDirty, ...rest } = this.props;

		regEx = regEx || "";
		let testValuesArray = testValues;

		if (!Array.isArray(testValuesArray)) {
			testValuesArray = (testValuesArray || "").split("\n").map(str => str.trim()).filter(str => !!str);
		}

		let isRegExValid = true;
		let regExObj;

		try {
			let isRegExCaseSensitive = true;
			if (regEx.startsWith("(?i)")) {
				regEx = regEx.substr(4);
				isRegExCaseSensitive = false;
			}

			regExObj = new RegExp(regEx, isRegExCaseSensitive ? undefined : "i");
		}
		catch {
			isRegExValid = false;
		}

		return (
			<div {...rest} style={{ margin: ".5em 1em" }}>
				<div>RegEx is <span style={{ "fontWeight": "bold", color: (isRegExValid ? "#008e00" : "#d00000") }}>{isRegExValid ? "" : "IN"}VALID</span>{regEx ? "" : " (empty - always match)"}</div>
				<div>RegEx builder tool: <a href="https://regex101.com/" target="_blank" rel="noopener noreferrer">regex101.com</a></div>
				<div className="RegExTesterContent">
					{this.state.isEditing ?
						(<Fragment>
							<TextArea
								name={name}
								autoFocus={true}
								onChange={this.handleDataChange}
								value={this.state.editingProperties[name]}
								previousValue={previousTestValues}
								showDirty={showDirty}
							/>
							<div>Enter one test value per line.</div>
						</Fragment>)
						:
						<Fragment>
							{testValuesArray && testValuesArray.length > 0 ?
								<table style={{ "userSelect": "text" }}>
									<tbody>
										<tr>
											<th>Test Value</th><th>Result</th>
										</tr>
										<Fragment>
											{testValuesArray.map((testValue, testValueIndex) => {
												const regexMatchResult = isRegExValid ? regExObj.exec(testValue) : null;
												const isPass = isRegExValid ? !!regexMatchResult : false;
												let regexMatchResultStr = (isPass ? "Match" : "No Match");
												let regexMatchResultArray = null;
												if (isPass && regexMatchResult.groups) {
													const regexMatchResultObjectKeys = Object.keys(regexMatchResult.groups);

													if (regexMatchResultObjectKeys.length > 0) {
														regexMatchResultStr += ` (${regexMatchResultObjectKeys.length} named groups) `;
														regexMatchResultArray = regexMatchResultObjectKeys.map((k) => [k, regexMatchResult.groups[k]]);
													}
												}

												return (
													<tr key={testValueIndex} className={(isPass ? "pass" : "fail")}>
														<td>{testValue}</td>
														<td style={{ "whiteSpace": "break-spaces" }}>
															{regexMatchResultStr}
															{regexMatchResultArray ? <br /> : <Fragment />}
															{regexMatchResultArray ? regexMatchResultArray.map((regexMatchKeyValue, regexMatchIndex) => {
																return (<Fragment key={regexMatchIndex}>
																	<span> </span>
																	<span style={{ "whiteSpace": "nowrap" }}>· <span style={{ "fontWeight": "bold" }}>{regexMatchKeyValue[0]}</span>:&nbsp;'<span style={{ "fontWeight": "normal" }}>{regexMatchKeyValue[1]}</span>'</span>
																</Fragment>);
															})
																: <Fragment />}
														</td>
													</tr>
												);
											})}
										</Fragment>
									</tbody>
								</table>
								:
								<span>No RegEx test values defined.</span>
							}
						</Fragment>
					}
				</div>
				{
					onChange ?
						<div className="button-bar">
							{this.state.isEditing ?
								<Fragment>
									<button onClick={this.save}>OK</button>
									<button onClick={() => this.setState({ isEditing: false })}>Cancel</button>
								</Fragment>
								:
								<button onClick={() => this.setState({ isEditing: true, editingProperties: { ...this.state.editingProperties, [name]: this.props.testValues } })}>Edit RegEx Test Values</button>
							}
						</div>
						: <Fragment />
				}
			</div >
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingProperties = {
			...this.state.editingProperties,
			[name]: value
		};

		this.setState({ editingProperties });
	}

	save() {
		this.setState({ isEditing: false });
		this.props.onChange({ target: { name: this.props.name, value: this.state.editingProperties[this.props.name] } });
	}
}