import React, { Fragment } from "react";
import clsx from "clsx";

export default class Radio extends React.Component {
	render() {
		let { errorMessage, children, label, title, showDirty, value, optionValue, checked, previousValue, className, ...rest } = this.props;

		if (checked !== false && checked !== true) {
			checked = value === optionValue; // Allow use of checked or selectedValue
		}
		else {
			value = checked; // handle boolean values
		}

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<Fragment>
				<label className={className} title={title}><input type="radio" value={optionValue} checked={checked} {...rest} /> {label}</label>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}
}