import React, { Fragment } from "react";
import clsx from "clsx";
import AsyncCreatableSelect from 'react-select/async-creatable';

export default class ComboBoxAsync extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOption: null
		};

		this.onChangeClosure = this.onChangeClosure.bind(this);
	}

	render() {
		let { errorMessage, value, previousValue, showDirty, className, disabled, name, onChange, title, isValidNewOption, ...rest } = this.props;

		if (value === null || value === undefined) {
			value = { value: null, label: null };
		}
		else if (typeof value !== "object") {
			if (this.state.selectedOption && this.state.selectedOption.value === value) {
				value = this.state.selectedOption;
			}
			else {
				value = { value: value, label: value };
			}
		}

		if (previousValue === null || previousValue === undefined) {
			previousValue = { value: null, label: null };
		}
		else if (typeof previousValue !== "object") {
			previousValue = { value: previousValue, label: previousValue };
		}

		value.value = this.getStringValue(value.value);
		previousValue.value = this.getStringValue(previousValue.value);

		className = clsx(className, "combo-box", this.props.isMulti ? "combo-box-multi" : "combo-box-single");

		if (showDirty && previousValue.value !== value.value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		isValidNewOption = isValidNewOption || (() => false);

		return (
			<Fragment>
				<div title={title}>
					<AsyncCreatableSelect
						name={name}
						value={value}
						className={className}
						isDisabled={disabled}
						onChange={this.onChangeClosure(onChange, name)}
						isValidNewOption={isValidNewOption}
						menuPosition="fixed" // force the options to float
						{...rest}
					/>
				</div>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}

	onChangeClosure(onChange, name) {
		if (!onChange)
			return undefined;

		return (selectedOption) => {
			if (!selectedOption)
				selectedOption = { value: "", data: null }

			const e = {
				target: {
					name,
					type: "select",
					value: selectedOption.value,
					data: selectedOption.data
				}
			};
			this.setState({ selectedOption });
			onChange(e);
		}
	};

	getStringValue(value) {
		if (value === undefined || value === null)
			value = "";
		else
			value = `${value}`; // Cast to a string

		return value;
	}
}