import React from "react";
import { Link } from "react-router-dom";
import {
	Typography,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button
} from "@material-ui/core";
import indigo from "@material-ui/core/colors/indigo";

export default function RouteNotFound() {
	return (
		<div
			className="page-not-found-container"
			style={{ backgroundColor: indigo[900] }}>
			<Card>
				<CardHeader title="Page Not Found" />
				<CardContent>
					<Typography variant="body1">
						The link you have entered or clicked does not exist.
					</Typography>
				</CardContent>
				<CardActions>
					<Link to="/">
						<Button size="small" color="primary">
							Back to the site
						</Button>
					</Link>
				</CardActions>
			</Card>
		</div>
	);
}
