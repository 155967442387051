import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import FileTypeEditModal from "./FileTypeEditModal";
import CopyFileTypeFromEntityModal from "./CopyFileTypeFromEntityModal";
import clsx from "clsx";
import _ from "lodash";

export default class FileTypeList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.fileTypeListRowContextMenuName = `FileTypeListRowContextMenu_${uniqueName}`;

		this.state = {
			selectedRowKeyList: [],
			showFileTypeEditModal: false,
			showCopyFileTypeFromEntityModal: false,
			editingFileTypeDetails: null,
			openShowJsonModal: false
		};

		this.tableColumns = [
			{ fieldName: "Name", displayName: "File Type" },
			{ fieldName: "AllowDuplicateFilenames", displayName: "Allow Duplicates?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Allow Duplicates" : "No Duplicates" },
			{ fieldName: "KeepDays", displayName: "Keep Days", className: "number", customValue: (rowObject, fieldName) => (rowObject[fieldName] ?? -1) >= 0 ? parseInt(rowObject[fieldName]).toLocaleString() : "∞" },
			{ fieldName: "IsFileBundle", displayName: "Is File Bundle?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Bundle" : "---" },
			{ fieldName: "Enabled", displayName: "Enabled?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Enabled" : "Disabled" },
			{ fieldName: "SyncWithSystemFileType", displayName: "System?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "System" : "" },
			{
				fieldName: "MetadataFields", displayName: "Metadata Fields", customValue: (rowObject, fieldName) => {
					const metadataFields = rowObject[fieldName] || [];
					return metadataFields.map((metadataField) => metadataField.FieldName).join(", ");
				}
			}
		];

		this.closeFileTypeEditModal = this.closeFileTypeEditModal.bind(this);
		this.closeCopyFileTypeFromEntityModal = this.closeCopyFileTypeFromEntityModal.bind(this);
		this.setFileTypeDetails = this.setFileTypeDetails.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.copyFromEntity = this.copyFromEntity.bind(this);
		this.getRowObjects = this.getRowObjects.bind(this);
		this.getRowObject = this.getRowObject.bind(this);
		this.add = this.add.bind(this);
		this.addFromObjects = this.addFromObjects.bind(this);
		this.addFromObject = this.addFromObject.bind(this);
		this.edit = this.edit.bind(this);
		this.delete = this.delete.bind(this);
	}

	render() {
		const canEdit = !this.props.disabled && this.props.allowMenu !== false && (this.props.isNew || this.context.hasPermission(this.props.entityGuid, "Change Entity Details"));

		return (
			<div className={clsx("list-table-container", "entity-profile-file-type-list", this.props.className)} >
				<fieldset style={this.props.showFieldset === false ? { border: 0, margin: 0, padding: 0 } : {}}>
					{this.props.showFieldset !== false ? <legend>File Types and Validation</legend> : <Fragment />}
					<div className="entry-table-inner-container" >
						<SelecTable className="profile-table"
							columns={this.tableColumns}
							rowObjects={this.props.fileTypeDetailsList}
							getRowKey={(rowData) => rowData.Name}
							selectMode={!canEdit && !this.props.forceMultiSelect ? "single" : "multi"}
							contextMenuId={this.props.allowMenu !== false ? this.fileTypeListRowContextMenuName : null}
							setSelectedRowKeys={this.setSelectedRowKeys}
							onDoubleClick={this.props.onDoubleClick ? (...args) => this.props.onDoubleClick(args) : (...args) => this.edit(args[2])}
						/>
						<div className="button-bar">
							{this.props.disabled || !canEdit ? <Fragment /> :
								<Fragment>
									<button onClick={this.add}>Add New...</button>
									<button onClick={this.copyFromEntity}>Copy From Entity...</button>
								</Fragment >
							}
							<button onClick={() => this.setState({ openShowJsonModal: true })} title="Show JSON.">Show JSON</button>
						</div>
						<ShowJsonModal
							heading="File Types and Validation (JSON)"
							isOpen={this.state.openShowJsonModal}
							closeModal={() => this.setState({ openShowJsonModal: false })}
							value={this.props.fileTypeDetailsList}
							setValue={(value) => { this.props.setFileTypeDetailsList(value); this.setState({ openShowJsonModal: false }); }}
							showDirty={!this.props.isNew}
							disabled={this.props.disabled}
						/>
						<ContextMenu id={this.fileTypeListRowContextMenuName}>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}
								disabled={this.state.selectedRowKeyList.length !== 1}>View{!canEdit ? "" : "/Edit"}...</MenuItem>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}
								disabled={this.props.disabled || this.state.selectedRowKeyList.length !== 1}>Duplicate...</MenuItem>
							<MenuItem divider />
							<MenuItem onClick={(e, data) => _.defer(() => { this.delete(data.selectedRowKeys); })}
								disabled={this.props.disabled || this.state.selectedRowKeyList.length === 0} >Delete</MenuItem>
						</ContextMenu>
						<FileTypeEditModal
							isOpen={this.state.showFileTypeEditModal}
							closeModal={this.closeFileTypeEditModal}
							editingFileTypeDetails={this.state.editingFileTypeDetails}
							setFileTypeDetails={this.setFileTypeDetails}
							fileTypeNames={(this.props.fileTypeDetailsList || []).map(d => d.Name).filter((s) => s !== (this.state.editingFileTypeDetails || {}).Name)}
							isNew={this.props.isNew}
							isSystemFileTypeEntity={this.props.entityGuid === this.context.systemFileTypesEntityGuid}
							config={this.props.config}
							disabled={this.props.disabled}
						/>
						<CopyFileTypeFromEntityModal
							isOpen={this.state.showCopyFileTypeFromEntityModal}
							closeModal={this.closeCopyFileTypeFromEntityModal}
							setFileTypeDetailsList={this.addFromObjects}
							config={this.props.config}
						/>
					</div>
				</fieldset>
			</div>
		);
	}

	setFileTypeDetails(fileTypeDetails, fileTypeDetailsOriginalName) {
		if (!fileTypeDetails.Name) {
			alert("File type name cannot be empty.");
			return false;
		}

		const fileTypeDetailsList = [
			...(this.props.fileTypeDetailsList || [])
		];

		if (fileTypeDetails.Name !== fileTypeDetailsOriginalName) {
			// Check for duplicates
			let savedFileTypeDetails = null;
			if (fileTypeDetailsList.some((thisFileTypeDetails) => {
				savedFileTypeDetails = thisFileTypeDetails;
				return thisFileTypeDetails.Name.toLowerCase() === fileTypeDetails.Name.toLowerCase();
			})) {
				alert(`Duplicate file type names are not allowed. File type '${savedFileTypeDetails.Name}' already exists.`);
				return false;
			}
		}

		fileTypeDetailsOriginalName = fileTypeDetailsOriginalName || fileTypeDetails.Name;
		let isUpdated = false;

		for (let i = fileTypeDetailsList.length - 1; i >= 0; i--) {
			const thisFileTypeDetails = fileTypeDetailsList[i];
			if (thisFileTypeDetails.Name === fileTypeDetailsOriginalName) {
				fileTypeDetailsList[i] = fileTypeDetails;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			fileTypeDetailsList.push(fileTypeDetails);

		if (this.props.setFileTypeDetailsList)
			this.props.setFileTypeDetailsList(fileTypeDetailsList);

		return true;
	}

	setSelectedRowKeys(selectedRowKeys) {
		const selectedRowKeyList = Object.getOwnPropertyNames(selectedRowKeys);
		this.setState({ selectedRowKeyList });

		if (this.props.setSelectedFileTypeDetailsList) {
			const fileTypeDetailsList = this.getRowObjects(selectedRowKeyList);
			this.props.setSelectedFileTypeDetailsList(fileTypeDetailsList);
		}
	}

	add() {
		this.edit(null);
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		let fileTypeDetails = this.getRowObject(selectedRowKey);

		if (createCopy)
			fileTypeDetails = { ...fileTypeDetails, Name: null };

		this.addFromObject(fileTypeDetails);
	}

	getRowObjects(rowKeys) {
		const fileTypeDetailsList = this.props.fileTypeDetailsList || [];
		const matchingFileTypeDetails = fileTypeDetailsList.filter((fileTypeDetails) => rowKeys.includes(fileTypeDetails.Name));

		return matchingFileTypeDetails;
	}

	getRowObject(rowKey) {
		const matchingFileTypeDetails = this.getRowObjects([rowKey])

		const fileTypeDetails = matchingFileTypeDetails.length > 0 ? matchingFileTypeDetails[0] : { Enabled: true };
		return fileTypeDetails;
	}

	addFromObject(fileTypeDetails) {
		this.setState({ editingFileTypeDetails: fileTypeDetails, showFileTypeEditModal: true });
	}

	addFromObjects(fileTypeDetailsList) {
		const targetFileTypeDetailsList = [
			...(this.props.fileTypeDetailsList || [])
		];

		let namesChanged = 0;

		for (let i = 0; i < fileTypeDetailsList.length; i++) {
			const fileTypeDetails = { ...fileTypeDetailsList[i] };
			let nameIncrements = 0;
			while (targetFileTypeDetailsList.find(d => d.Name === fileTypeDetails.Name)) {
				// Fix duplicate name
				fileTypeDetails.Name = this.incrementName(fileTypeDetails.Name);
				nameIncrements++;
			}

			if (nameIncrements > 0)
				namesChanged++;

			targetFileTypeDetailsList.push(fileTypeDetails);
		}

		if (this.props.setFileTypeDetailsList)
			this.props.setFileTypeDetailsList(targetFileTypeDetailsList);

		this.setState({ showCopyFileTypeFromEntityModal: false });

		if (namesChanged > 0)
			_.defer(() => alert(`${fileTypeDetailsList.length > 1 ? namesChanged : "The"} file type name${namesChanged > 1 ? "s were" : " was"} changed to prevent duplicates.`));
	}

	copyFromEntity() {
		this.setState({ showCopyFileTypeFromEntityModal: true });
	}

	delete(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected file type${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const fileTypeDetailsList = this.props.fileTypeDetailsList.filter((fileTypeDetails) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === fileTypeDetails.Name));

			if (this.props.setFileTypeDetailsList)
				this.props.setFileTypeDetailsList(fileTypeDetailsList);
		}
	}

	closeFileTypeEditModal() {
		this.setState({ showFileTypeEditModal: false });
	}

	closeCopyFileTypeFromEntityModal() {
		this.setState({ showCopyFileTypeFromEntityModal: false });
	}

	incrementName(name) {
		if (name === null || name === undefined)
			name = "";

		const splitName = name.split(" ");
		const nameEnding = splitName[splitName.length - 1];
		if (isNaN(nameEnding))
			return name + " 2";

		const number = parseInt(nameEnding, 10);

		return `${name.substring(0, name.length - nameEnding.length)}${number + 1}`;
	}
}