import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import EntryRow from "../../Shared/EntryRow";
import Select from "../../Shared/Select";
import ComboBox from "../../Shared/ComboBox";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class UserGroupEntityProfileEditModal extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.usableRolesTableColumns = [
			{ fieldName: 0, displayName: "Usable Role", customValue: (rowObject) => rowObject }
		];

		this.assignableRolesTableColumns = [
			{ fieldName: 0, displayName: "Assignable Role", customValue: (rowObject) => rowObject }
		];

		this.availableRolesTableColumns = [
			{ fieldName: 0, displayName: "Available Role", customValue: (rowObject) => rowObject }
		];

		this.state = {
			editingUserGroupEntityProfile: this.props.editingUserGroupEntityProfile,
			editingUserGroupEntityProfileDomainUserGroupName: null,
			selectedUsableRoleRowKeyList: [],
			selectedAssignableRoleRowKeyList: [],
			isEditingUsableRoles: false,
			isEditingAssignableRoles: false,
			domainOptions: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.setUsableRoleNames = this.setUsableRoleNames.bind(this);
		this.setAssignableRoleNames = this.setAssignableRoleNames.bind(this);
		this.getDomainOptions = this.getDomainOptions.bind(this);
		this.loadUserGroupOptions = this.loadUserGroupOptions.bind(this);

		this.save = this.save.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingUserGroupEntityProfile
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const isExisting = !!this.props.editingUserGroupEntityProfile.DomainUserGroupName;
			this.setState({
				editingUserGroupEntityProfile: this.props.editingUserGroupEntityProfile,
				editingUserGroupEntityProfileDomainUserGroupName: this.props.editingUserGroupEntityProfile.DomainUserGroupName,
				isExisting,
				isEditingUsableRoles: !isExisting,
				isEditingAssignableRoles: !isExisting
			});
		}
	}

	render() {
		const editingUserGroupEntityProfile = this.state.editingUserGroupEntityProfile || {};
		const previousEditingUserEntityProfile = this.props.editingUserGroupEntityProfile || {};
		const availableRoleNames = this.props.availableRoleNames || [];
		const domainUserGroupName = previousEditingUserEntityProfile.DomainUserGroupName || "[New]";
		const isExisting = this.state.isExisting;
		const canChangeEntityUsers = this.props.entityGuid === null || this.context.hasPermission(this.props.entityGuid, "Change Entity Users");
		//const webUserSession = this.context.getWebUserSession();
		const isOwnAccount = false; //(editingUserGroupEntityProfile.DomainUserGroupName || "").toLowerCase() === webUserSession.UserDetails.DomainUserGroupName.toLowerCase();

		const formatOptionLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}
		return (
			<BetterDialog title={`User Group: ${domainUserGroupName}`} open={this.props.isOpen}>
				<div className="entry-table-container user-entity-profile-edit-modal">
					<fieldset>
						<legend>Main</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="* Domain" title="Domain">
										{isExisting ? <span>{editingUserGroupEntityProfile.DomainName || ""}</span> :
											<LoadingWrapper isLoading={!this.state.domainOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
												<Select
													name="DomainName"
													value={editingUserGroupEntityProfile.DomainName}
													previousValue={previousEditingUserEntityProfile.DomainName}
													showDirty={isExisting}
													disabled={this.props.disabled || isExisting || isOwnAccount}
													onChange={this.handleDataChange}
													options={this.getDomainOptions()}
													autoFocus={this.props.isOpen && !isExisting}
												/>
											</LoadingWrapper>
										}
									</EntryRow>
									<EntryRow label="* User Group Name" title="User Group Name" innerDivStyle={{ overflow: "visible" }}>
										{isExisting ? <span>{editingUserGroupEntityProfile.UserGroupName || ""}</span> :
											<ComboBoxAsync
												name="UserGroupName"
												value={editingUserGroupEntityProfile.UserGroupName}
												previousValue={previousEditingUserEntityProfile.UserGroupName}
												showDirty={isExisting}
												disabled={this.props.disabled || isExisting || !editingUserGroupEntityProfile.DomainName}
												loadOptions={_.debounce(this.loadUserGroupOptions, 250)}
												isSearchable={true}
												onChange={this.handleDataChange}
												noOptionsMessage={(context) => context.inputValue ? "No matching user groups!" : "Start typing to show options..."}
												formatOptionLabel={formatOptionLabel}
											/>
										}
									</EntryRow>
									<EntryRow label="Domain/UserGroupName">
										<span>{editingUserGroupEntityProfile.DomainUserGroupName || ""}</span>
									</EntryRow>
									<EntryRow label="Description">
										<span>{editingUserGroupEntityProfile.Description || ""}</span>
									</EntryRow>
									<EntryRow label="Group Owner">
										<span>{editingUserGroupEntityProfile.OwnerDomainUsername || ""}</span>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that give this user access to features.">
						<legend>Usable Roles</legend>
						<div className="role-select-container">
							<ComboBox
								name="UsableRoleNames"
								value={(editingUserGroupEntityProfile.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								previousValue={(previousEditingUserEntityProfile.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								isClearable={false}
								isMulti={true}
								isSearchable={true}
								closeMenuOnSelect={false}
								backspaceRemovesValue={true}
								onChange={this.handleDataChange}
								showDirty={isExisting}
								disabled={this.props.disabled || !(canChangeEntityUsers || !isExisting) || isOwnAccount}
							/>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that this user can assign to other users, if they have permission to assign roles.">
						<legend>Assignable Roles</legend>
						<div className="role-select-container">
							<ComboBox
								name="AssignableRoleNames"
								value={(editingUserGroupEntityProfile.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								previousValue={(previousEditingUserEntityProfile.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								isClearable={false}
								isMulti={true}
								isSearchable={true}
								closeMenuOnSelect={false}
								backspaceRemovesValue={true}
								onChange={this.handleDataChange}
								showDirty={isExisting}
								disabled={this.props.disabled || !(canChangeEntityUsers || !isExisting) || isOwnAccount}
							/>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	toggleState(stateName) {
		this.setState({ [stateName]: !this.state[stateName] });
	}

	getDomainOptions() {
		if (this.state.domainOptions)
			return this.state.domainOptions;

		if (!this.state.isGettingDomainOptions && this.state.isExisting === false) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingDomainOptions: true }));

			this.apiService.getDomains("",
				(response) => {
					const domainNames = ["", ...response.data.DomainNames, ...response.data.VirtualDomainNames];
					const domainOptions = domainNames.map(domainName => { return { value: domainName, label: domainName } });

					this.setState({
						isGettingDomainOptions: false,
						domainOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingDomainOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	loadUserGroupOptions(inputValue, callback) {
		this.apiService.getUserGroups(this.state.editingUserGroupEntityProfile.DomainName, inputValue,
			(response) => {
				const domainUserGroupOptions = response.data.UserGroups.map(domainUser => {
					return {
						value: domainUser.UserGroupName,
						label: `${domainUser.UserGroupName} (Owner: ${domainUser.OwnerDomainUsername})`,
						data: domainUser
					};
				});
				callback(domainUserGroupOptions);
			},
			(error) => {
				handleApiError(error);
			}
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);
		const { data } = e.target;

		let values = {
			[name]: value
		};

		if (name === "DomainName") {
			values = {
				DomainUserGroupName: null,
				Description: null,
				OwnerDomainUsername: null,
				...values
			};
		}
		else if (data && name === "UserGroupName") {
			values = data;
		}

		const editingUserGroupEntityProfile = {
			...this.state.editingUserGroupEntityProfile,
			...values
		};

		this.setState({ editingUserGroupEntityProfile });
	}

	setUsableRoleNames(roleNames) {
		this.handleDataChange({ target: { name: "UsableRoleNames", value: roleNames } });
	}

	setAssignableRoleNames(roleNames) {
		this.handleDataChange({ target: { name: "AssignableRoleNames", value: roleNames } });
	}

	save() {
		if (this.props.setUserGroupEntityProfile(this.state.editingUserGroupEntityProfile, this.state.editingUserGroupEntityProfileDomainUserGroupName))
			this.props.closeModal();
	}
}