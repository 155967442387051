import React, { Fragment } from "react";
import Select from "../../Shared/Select";
import DynamicEntryRows from "../../Shared/DynamicEntryRows";
import Input from "../../Shared/Input";
import EntryRow from "../../Shared/EntryRow";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class PluginEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editingEntityPlugin: this.props.editingEntityPlugin,
			editingEntityPluginName: null,
			pluginDefinitionOptions: null,
			openShowJsonModal: false,
			editingEntityPluginForShowJson: null
		};

		this.getPluginDefinitionOptions = this.getPluginDefinitionOptions.bind(this);
		this.handlePluginChange = this.handlePluginChange.bind(this);
		this.handleEntityPluginSettingChange = this.handleEntityPluginSettingChange.bind(this);
		this.save = this.save.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.openShowJsonModal = this.openShowJsonModal.bind(this);
		this.setEditingEntityPluginFromShowJson = this.setEditingEntityPluginFromShowJson.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingEntityPlugin
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const isExisting = !!this.props.editingEntityPlugin.PluginName;
			this.setState({
				editingEntityPlugin: this.props.editingEntityPlugin,
				editingEntityPluginName: this.props.editingEntityPlugin.EntityPluginName,
				isExisting
			});
		}
	}

	render() {
		const editingEntityPlugin = this.state.editingEntityPlugin || { PluginDefinition: {} };
		const previousEditingEntityPlugin = this.props.editingEntityPlugin || { PluginDefinition: {} };
		const entityPluginName = previousEditingEntityPlugin.EntityPluginName || "[New]";
		const isExisting = !!previousEditingEntityPlugin.EntityPluginName;
		const heading = `Entity Plugin Alias: ${entityPluginName}`;

		return (
			<BetterDialog title={heading} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Plugin Configuration</legend>
						<div className="entry-table-inner-container">
							<table>
								<tbody>
									<EntryRow label={(isExisting ? "" : "* ") + "Plugin"} title="Plugin">
										{isExisting ? (<span>{editingEntityPlugin.PluginDefinition.PluginName || ""}</span>) :
											<LoadingWrapper isLoading={!this.state.pluginDefinitionOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
												<Select
													name="PluginName"
													value={editingEntityPlugin.PluginDefinition.PluginName || ""}
													previousValue={previousEditingEntityPlugin.PluginDefinition.PluginName || ""}
													showDirty={isExisting}
													onChange={this.handlePluginChange}
													options={this.getPluginDefinitionOptions()}
													autoFocus={this.props.isOpen && !isExisting}
													disabled={this.props.disabled || isExisting}
												/>
											</LoadingWrapper>
										}
									</EntryRow>
									<EntryRow label="* Entity Plugin Alias" title="Entity Plugin Alias">
										<Input type="text" maxLength={100}
											name="EntityPluginName"
											value={editingEntityPlugin.EntityPluginName || ""}
											previousValue={previousEditingEntityPlugin.EntityPluginName || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen && isExisting}
											disabled={this.props.disabled}
										/>
									</EntryRow>
									<DynamicEntryRows
										fieldDefinitions={editingEntityPlugin.PluginDefinition.EntitySettingDefinitions}
										fieldValues={editingEntityPlugin.EntityPluginSettings}
										previousFieldValues={previousEditingEntityPlugin.EntityPluginSettings}
										showDirty={isExisting}
										onChange={this.handleEntityPluginSettingChange}
										textBoxStyle={{ width: "100%", minWidth: "30em" }}
										disabled={this.props.disabled}
									/>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="entry-table-container">
					<fieldset>
						<legend>Plugin user setting fields</legend>
						<div className="entry-table-inner-container" >
							{editingEntityPlugin.PluginDefinition.UserSettingDefinitions && editingEntityPlugin.PluginDefinition.UserSettingDefinitions.length > 0 ? (
								<ul style={{ marginBottom: 0 }}>
									{editingEntityPlugin.PluginDefinition.UserSettingDefinitions.map((userSettingDefinition) => {
										return (<li key={userSettingDefinition.FieldName} title={userSettingDefinition.Tooltip || null}><b>{userSettingDefinition.DisplayName || userSettingDefinition.FieldName}</b> ({userSettingDefinition.FieldName} - {userSettingDefinition.UiWidget || "Textbox"})</li>);
									})}
								</ul>)
								: (<span>This plugin has no user settings</span>)
							}
						</div>
					</fieldset>
				</div>
				<ShowJsonModal
					heading={`${heading} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.editingEntityPluginForShowJson}
					setValue={this.setEditingEntityPluginFromShowJson}
					showDirty={this.state.isExisting}
					disabled={this.props.disabled}
				/>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={this.openShowJsonModal} title="Show JSON.">Show JSON</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	getPluginDefinitionOptions() {
		if (this.state.pluginDefinitionOptions)
			return this.state.pluginDefinitionOptions;

		if (!this.state.isGettingPluginDefinitions && this.state.isExisting === false) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingPluginDefinitions: true }));

			this.apiService.getPluginDefinitions(
				(response) => {
					const pluginDefinitionMap = response.data.Plugins.reduce(function (map, plugin) {
						map[plugin.PluginName] = plugin
						return map;
					}, {});

					const pluginDefinitions = [{ PluginName: "" }, ...response.data.Plugins];
					const pluginDefinitionOptions = pluginDefinitions.map(pluginDefinition => { return { value: pluginDefinition.PluginName, label: pluginDefinition.PluginName } });

					this.setState({
						isGettingPluginDefinitions: false,
						pluginDefinitionMap,
						pluginDefinitionOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingPluginDefinitions: false });
				}
			);
		}

		return []; // default to empty
	}

	handlePluginChange(e) {
		const { value } = e.target;

		const pluginDefinition = {
			...this.state.pluginDefinitionMap[value]
		};

		const editingEntityPlugin = {
			...this.state.editingEntityPlugin,
			EntityPluginName: pluginDefinition.PluginName,
			EntityPluginSettings: { ...this.state.editingEntityPlugin.EntityPluginSettings, ...this.getPluginSettingsDefaultValues(pluginDefinition.EntitySettingDefinitions) },
			PluginDefinition: pluginDefinition
		};

		this.setState({ editingEntityPlugin });
	}

	getPluginSettingsDefaultValues(settingDefinitions) {
		const defaultSettingValues = {};

		if (settingDefinitions !== null) {
			settingDefinitions.forEach((settingDefinition) => {
				if (settingDefinition.AssignDefaultValue === false || !settingDefinition.DefaultValue || !settingDefinition.FieldName)
					return;

				defaultSettingValues[settingDefinition.FieldName] = settingDefinition.DefaultValue;
			});
		}

		return defaultSettingValues;
	}

	handleEntityPluginSettingChange(e) {
		const { name, value } = extractDomElement(e.target);

		const entityPluginSettings = {
			...this.state.editingEntityPlugin.EntityPluginSettings,
			[name]: value
		};

		const editingEntityPlugin = {
			...this.state.editingEntityPlugin,
			EntityPluginSettings: entityPluginSettings
		};

		this.setState({ editingEntityPlugin });
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingEntityPlugin = {
			...this.state.editingEntityPlugin,
			[name]: value
		};

		this.setState({ editingEntityPlugin });
	}

	save() {
		const editingEntityPlugin = this.state.editingEntityPlugin
		const entityPluginSettings = editingEntityPlugin.EntityPluginSettings;
		if (!entityPluginSettings) {
			alert("You must select a plugin!");
			return;
		}
		const cleanEntityPlugin = {
			...editingEntityPlugin,
			// Ensure only field names specified by this plugin are kept, in case the user switched the plugin selection after entering some data
			EntityPluginSettings: editingEntityPlugin.PluginDefinition.EntitySettingDefinitions.reduce(function (map, entitySettingDefinition) {
				map[entitySettingDefinition.FieldName] = entityPluginSettings[entitySettingDefinition.FieldName];
				return map;
			}, {})
		}

		if (this.props.setPluginProfile(cleanEntityPlugin, this.state.editingEntityPluginName))
			this.props.closeModal();
	}

	openShowJsonModal() {
		this.setState({
			openShowJsonModal: true,
			editingEntityPluginForShowJson: { ...this.state.editingEntityPlugin }
		});
	}

	setEditingEntityPluginFromShowJson(editingEntityPlugin) {
		editingEntityPlugin = {
			...editingEntityPlugin
		};

		this.setState({ editingEntityPlugin, openShowJsonModal: false });
	}
}
