import React from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import PluginDefinitionEditModal from "./PluginDefinitionEditModal";
import PageOverlay from "../../Shared/PageOverlay";
import PassiveMessage from "../../Shared/PassiveMessage";
import _ from "lodash";

export default class PluginDefinitions extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.pluginRowContextMenuName = `PluginRowContextMenu_${uniqueName}`;

		this.state = {
			isInitialized: false,
			isLoading: true,
			isLoadingPassive: false,
			selectedRowKeyList: [],
			showPluginDefinitionEditModal: false,
			plugins: [],
			editingPlugin: null,
			showSaveSuccessNotification: false
		};

		this.tableColumns = [
			{ fieldName: "PluginName", displayName: "Plugin" },
			{ fieldName: "Name", displayName: "S3 Bucket Name", customValue: (rowObject, fieldName) => rowObject.PluginS3Bucket[fieldName] },
			{ fieldName: "RegionEndpointName", displayName: "S3 Bucket Region", customValue: (rowObject, fieldName) => rowObject.PluginS3Bucket[fieldName] },
			{ fieldName: "CopyFileToPluginS3Bucket", displayName: "Copy File?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Yes" : "No" },
			{ fieldName: "CopyExtractedFilesToPluginS3Bucket", displayName: "Copy Extracted Files?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Yes" : "No" },
			{ fieldName: "CreateDetailsFileForExtractedFiles", displayName: "Extracted Details?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Yes" : "No" },
			{ fieldName: "SecurityScorecardUrl", displayName: "Scorecard?", customValue: (rowObject, fieldName) => (!!rowObject[fieldName] ? "Yes" : "No") },
			{ fieldName: "CodeRepoUrl", displayName: "Code Repo?", customValue: (rowObject, fieldName) => (!!rowObject[fieldName] ? "Yes" : "No") },
			{ fieldName: "POC", displayName: "Point of Contact", customValue: (rowObject, fieldName) => rowObject[fieldName] }
		];

		this.closePluginDefinitionEditModal = this.closePluginDefinitionEditModal.bind(this);
		this.savePluginDefinition = this.savePluginDefinition.bind(this);
		this.handleCloseSaveNotification = this.handleCloseSaveNotification.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		//this.delete = this.delete.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	render() {
		const heading = "System Plugins";
		const plugins = this.state.plugins || [];

		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<PageOverlay showOverlay={this.state.isLoadingPassive} />
				<h1>{heading}</h1>
				<div className="list-table-container">
					<fieldset>
						<legend>File Upload Processing Plugins</legend>
						<LoadingWrapper isLoading={this.state.isLoading}>
							<div className="entry-table-inner-container" >
								<SelecTable className="profile-table"
									columns={this.tableColumns}
									rowObjects={plugins}
									getRowKey={(rowData) => rowData.PluginName}
									selectMode="single"
									contextMenuId={this.pluginRowContextMenuName}
									setSelectedRowKeys={this.setSelectedRowKeys}
									onDoubleClick={(...args) => this.edit(args[2])}
								/>
								<div className="button-bar">
									<button onClick={this.add}>Add New...</button>
								</div>
								<ContextMenu id={this.pluginRowContextMenuName}>
									<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}>View/Edit...</MenuItem>
									<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}>Duplicate...</MenuItem>
								</ContextMenu>
								<PluginDefinitionEditModal
									isOpen={this.state.showPluginDefinitionEditModal}
									closeModal={this.closePluginDefinitionEditModal}
									editingPlugin={this.state.editingPlugin}
									savePluginDefinition={this.savePluginDefinition}
									config={this.props.config}
								/>
							</div>
						</LoadingWrapper>
					</fieldset>
				</div>
				<PassiveMessage
					open={this.state.showSaveSuccessNotification}
					onClose={this.handleCloseSaveNotification}
					variant="success"
					message="Record saved successfully!" />
			</Layout >
		);
	}

	initialize() {
		this.apiService.getPluginDefinitions(
			(response) => {
				this.setState({
					isLoading: false,
					isLoadingPassive: false,
					plugins: response.data.Plugins
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false, isLoadingPassive: false, });
			}
		);

		this.setState({ isInitialized: true });
	}


	savePluginDefinition(pluginDefinition, successCallback) {
		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });

		this.apiService.setPluginDefinition(pluginDefinition,
			(response) => {
				if (successCallback)
					successCallback();
				// Force a refresh
				this.setState({
					showSaveSuccessNotification: true,
					isInitialized: false
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			}
		);
	}

	setSelectedRowKeys(selectedRowKeys) {
		this.setState({ selectedRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	add() {
		this.edit(null);
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const plugins = this.state.plugins || [];
		const matchingPlugins = plugins.filter((plugins) => plugins.PluginName === selectedRowKey);

		let plugin = matchingPlugins.length > 0 ? matchingPlugins[0] : { PluginS3Bucket: {} };

		if (createCopy) {
			plugin = { ...plugin, PluginName: null };
			delete plugin.PluginId
		}

		this.setState({ editingPlugin: plugin, showPluginDefinitionEditModal: true });
	}

	/*
	delete(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected plugin${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const plugins = this.props.plugins.filter((plugins) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === plugins.PluginName));

			this.state.setPlugins(plugins);
		}
	}
	*/

	closePluginDefinitionEditModal() {
		this.setState({ showPluginDefinitionEditModal: false });
	}

	handleCloseSaveNotification(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ showSaveSuccessNotification: false });
	}
}
