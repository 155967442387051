import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group"
import FileSection from "./FileSection"

export default class FileSections extends React.Component {
	constructor(props) {
		super(props);

		this.getFileSections = this.getFileSections.bind(this);
	}

	render() {
		return (
			<TransitionGroup>
				{this.getFileSections()}
			</TransitionGroup>
		);
	}

	getFileSections() {
		let fileNumber = 0;

		return this.props.filesData.map((fileDef, fileId) => {
			fileNumber++;
			return (
				<CSSTransition key={fileDef.key} classNames="file-section" timeout={{ enter: 500, exit: 300 }}>
					<FileSection
						fileTypeNames={this.props.fileTypeNames}
						fileId={fileId}
						fileNumber={fileNumber}
						fileDef={fileDef}
						fileTypeDetailsMap={this.props.fileTypeDetailsMap}
						fileData={this.props.filesData[fileId]}
						setFileData={this.props.setFileData}
						removeFileData={this.props.removeFileData}
					/>
				</CSSTransition>
			);
		});
	}
}
