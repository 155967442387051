import React, { Fragment } from "react";
import clsx from "clsx";

export default class Checkbox extends React.Component {
	render() {
		let { errorMessage, children, label, title, showDirty, value, checked, previousValue, className, ...rest } = this.props;

		if (checked === false || checked === true) {
			value = checked; // Allow use of checked or value
		}
		else {
			value = this.getCleanBool(value);
			previousValue = this.getCleanBool(previousValue);
		}

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<Fragment>
				<label className={className} title={title}><input type="checkbox" value={value} checked={!!value} {...rest} /> {label}</label>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}

	getCleanBool(value) {
		if (typeof value === 'string') {
			const lowerValue = value.toLowerCase();
			if (lowerValue === "false" || lowerValue === "0")
				value = false;
			else if (lowerValue === "true" || lowerValue === "1")
				value = true;
		}

		return value;
	}
}