import React, { Fragment } from "react";
import DynamicEntryRows from "../../Shared/DynamicEntryRows";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class UserPluginsSetting extends React.Component {
	constructor(props) {
		super(props);

		this.handleUserPluginSettingChange = this.handleUserPluginSettingChange.bind(this);
	}

	render() {
		if (this.props.userPlugin === null
			|| this.props.userPlugin.PluginDefinition === null
			|| this.props.userPlugin.PluginDefinition.UserSettingDefinitions === null
			|| this.props.userPlugin.PluginDefinition.UserSettingDefinitions.length === 0
		)
			return (<Fragment />);

		const previousUserPlugin = this.props.previousUserPlugin || {};

		return (
			<div className="entry-table-container" >
				<fieldset>
					<legend>{this.props.userPlugin.EntityPluginName} ({this.props.userPlugin.PluginDefinition.PluginName})</legend>
					<div className="entry-table-inner-container">
						<table>
							<tbody>
								<DynamicEntryRows
									fieldDefinitions={this.props.userPlugin.PluginDefinition.UserSettingDefinitions}
									fieldValues={this.props.userPlugin.UserPluginSettings}
									previousFieldValues={previousUserPlugin.UserPluginSettings}
									showDirty={true}
									onChange={this.handleUserPluginSettingChange}
									textBoxStyle={{ width: "100%", minWidth: "30em" }}
								/>
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
		)
	}

	handleUserPluginSettingChange(e) {
		const { name, value } = extractDomElement(e.target);

		const userPlugin = { ...this.props.userPlugin };
		userPlugin.UserPluginSettings = { ...userPlugin.UserPluginSettings, [name]: value };

		this.props.setUserPlugin(userPlugin);
	}
}
