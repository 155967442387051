import React from "react";

export default class TransitionalDiv extends React.Component {
	constructor(props) {
		super(props);
		this.updateDimensions = this.updateDimensions.bind(this);
	}

	componentDidMount() {
		this.updateDimensions(this.el);
		window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions(e) {
		this.setState({ height: e.scrollHeight });
	}

	render() {
		let { show, transitions, children, style, ...rest } = this.props;
		if (show !== false)
			show = true;

		style = style || {};
		if (transitions) {
			if (transitions.height) {
				style.height = show ? this.state.height : 0;
				style.transition = (style.transition ? style.transition + ", " : "") + (transitions.height.transition || "height 200ms linear");
			}
			if (transitions.maxHeight) {
				style.maxHeight = show ? this.state.height : 0;
				style.transition = (style.transition ? style.transition + ", " : "") + (transitions.maxHeight.transition || "max-height 200ms linear");
			}
			if (transitions.opacity) {
				style.opacity = show ? 1 : 0;
				style.transition = (style.transition ? style.transition + ", " : "") + (transitions.opacity.transition || "opacity 200ms linear");
			}
		}

		return (
			<div style={style} ref={el => this.el = el} {...rest}>
				{children}
			</div>
		);
	}
}