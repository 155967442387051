import React from "react";
import UserPluginSettings from "./UserPluginSettings";

export default class UserPluginsSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
		};

		this.setUserPlugin = this.setUserPlugin.bind(this);
	}


	render() {
		const userPlugins = this.props.userPlugins || [];
		const previousUserPlugins = this.props.previousUserPlugins || [];

		return (
			<div>
				{
					userPlugins.map((userPlugin, index) => {
						return (<UserPluginSettings
							key={userPlugin.EntityPluginName}
							userPlugin={userPlugin}
							previousUserPlugin={previousUserPlugins[index]}
							setUserPlugin={(userPlugin) => this.setUserPlugin(userPlugin, index)}
						/>);
					})
				}
			</div>
		);
	}

	setUserPlugin(userPlugin, index) {
		const userPlugins = [...this.props.userPlugins];
		userPlugins[index] = userPlugin;
		this.props.setUserPlugins(userPlugins);
	}
}
