import React from "react";
import Layout from"../../Layout/Layout";

export default class Home extends React.Component {
	render() {
		return (
			<Layout config={this.props.config} subtitle="Home" redirectIfSingleMenuItem={true}>
				<h1>Welcome to {this.props.config.appName}!</h1>
				<p>Choose an option from the top menu.</p>
			</Layout>
		);
	}
}
