import React from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import _ from "lodash";
import SearchCriteria from "./SearchCriteria";
import FilesDisplay from "./FilesDisplay";

export default class Download extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			isInitialized: false,
			isLoading: true,
			entityDetails: {},
			fileTypeDetailsMap: {},
			searchCriteria: null,
			allFileTypeMetadataFields: [],
			orderBy: "CreateDateTime DESC" // initial sort order on load
		};

		this.setSearchCriteria = this.setSearchCriteria.bind(this);
		this.getPath = this.getPath.bind(this);
		this.setRoute = this.setRoute.bind(this);
		this.getAllFileTypeMetadataFields = this.getAllFileTypeMetadataFields.bind(this);
		this.setOrderBy = this.setOrderBy.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	render() {
		const action = "View/Download";
		const heading = `${this.state.entityDetails.Name || "..."} ${action}`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		const path = this.getPath();

		return (
			<Layout config={this.props.config} subtitle={heading} showCuiBanner={((this.state.entityDetails || {}).Tags || []).includes("CUI")}>
				<LoadingWrapper isLoading={this.state.isLoading}>
					<h1>{heading}</h1>
					<SearchCriteria
						entityDetails={this.state.entityDetails}
						fileTypeDetailsMap={this.state.fileTypeDetailsMap}
						fileTypeNames={this.state.fileTypeNames}
						setSearchCriteria={this.setSearchCriteria}
						allFileTypeMetadataFields={this.state.allFileTypeMetadataFields}
						path={path}
						showEntitySearch={this.props.match.params.entityGuid === "11111111-1111-1111-1111-111111111111"}
					/>
					<FilesDisplay
						entityDetails={this.state.entityDetails}
						searchCriteria={this.state.searchCriteria}
						view={this.state.view}
						baseRoute={`/download/${this.state.entityDetails.Guid}`}
						path={path}
						setRoute={this.setRoute}
						fileTypeDetailsMap={this.state.fileTypeDetailsMap}
						allFileTypeMetadataFields={this.state.allFileTypeMetadataFields}
						apiBaseUrl={this.props.config.apiBaseUrl}
						streamZipDownloads={this.props.config.streamZipDownloads}
						orderBy={this.state.orderBy}
						setOrderBy={this.setOrderBy}
						showEntityName={this.props.match.params.entityGuid === "11111111-1111-1111-1111-111111111111"}
					/>
				</LoadingWrapper>
			</Layout >
		);
	}

	initialize() {
		this.apiService.getEntityDetails(this.props.match.params.entityGuid,
			(response) => {
				const fileTypeDetailsMap = {};
				let fileTypeNames = response.data.EntityDetails.FileTypeDetailsList.map((fileTypeDetails) => {
					const existingFileTypeDetails = fileTypeDetailsMap[fileTypeDetails.Name];
					if (!existingFileTypeDetails) {
						fileTypeDetailsMap[fileTypeDetails.Name] = { ...fileTypeDetails };
					}

					return fileTypeDetails.Name;
				});
				fileTypeNames = fileTypeNames.filter((value, index, self) => self.indexOf(value) === index); // distinct
				fileTypeNames.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
				if (fileTypeNames.length !== 1)
					fileTypeNames.unshift(""); // Add an empty selection for the drop-down lists

				const entityDetails = response.data.EntityDetails;
				if (this.props.match.params.entityGuid === "*")
					entityDetails.Guid = "*"

				this.setState({
					isLoading: false,
					entityDetails: entityDetails,
					fileTypeNames,
					fileTypeDetailsMap,
					allFileTypeMetadataFields: this.getAllFileTypeMetadataFields(fileTypeNames, fileTypeDetailsMap)
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);
		this.setState({ isInitialized: true });
	}

	setSearchCriteria(searchCriteria) {
		this.setState({ searchCriteria: searchCriteria });
	}

	getPath() {
		if (this.props.match.params === undefined || this.props.match.params === null)
			return "";

		return this.props.match.params[0] || "";
	}

	setRoute(newRoute) {
		this.props.history.push(newRoute);
	}

	getAllFileTypeMetadataFields(fileTypeNames, fileTypeDetailsMap) {
		const allFileTypeMetadataFields = [];
		for (let i = 0; i < fileTypeNames.length; i++) {
			const fileTypeName = fileTypeNames[i];
			if (fileTypeName === "")
				continue;

			const fileTypeDetails = fileTypeDetailsMap[fileTypeNames[i]];
			for (let j = 0; j < fileTypeDetails.MetadataFields.length; j++) {
				const metadataField = fileTypeDetails.MetadataFields[j];
				if (allFileTypeMetadataFields.some(existing => existing.FieldName === metadataField.FieldName))
					continue; // Prevent duplicate fieldNames

				allFileTypeMetadataFields.push(metadataField);
			}
		}

		allFileTypeMetadataFields.sort((a, b) => (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase() ? 1 : -1));

		return allFileTypeMetadataFields;
	}

	setOrderBy(orderBy) {
		this.setState({ orderBy });
	}
}
