
export default function getFieldNameFromDisplayName(displayName, fieldName) {
	if (!displayName || fieldName)
		return fieldName; // Do nothing if the displayName is empty or fieldName already has a value

	// DisplayName has a value but FieldName is empty so let's fill in the most likely value...
	const cleanFieldName = displayName
		.toLowerCase()
		.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()) // capitalize the first char of each word
		.replace(/[^A-Z\d_]/gi, ''); // Remove invalid characters

	return cleanFieldName;
}
