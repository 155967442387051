import React from "react";
import Files from "react-butterfiles";

export default class FileManifestUploadButton extends React.Component {
	constructor(props) {
		super(props);

		this.processFileManifest = this.processFileManifest.bind(this);
		this.processFileManifestAfterRead = this.processFileManifestAfterRead.bind(this);
	}

	render() {
		return (
			<Files accept={["application/json"]} onSuccess={this.processFileManifest} >
				{({ browseFiles }) => (
					<button onClick={browseFiles} title={`Upload file metadata to auto-populate fields.`}>Upload File Manifest</button>
				)}
			</Files>
		);
	}

	processFileManifest(butterFiles) {
		if (butterFiles.length !== 1)
			return;

		const butterFile = butterFiles[0];
		const fileReader = new FileReader();
		fileReader.onload = this.processFileManifestAfterRead;
		fileReader.readAsBinaryString(butterFile.src.file)
	}

	processFileManifestAfterRead(e) {
		const fileReader = e.target;
		const fileManifest = JSON.parse(fileReader.result);
		let filesData = [...this.props.filesData];

		let nextFileDataKey = this.props.nextFileDataKey;
		const filenames = Object.getOwnPropertyNames(fileManifest.Files);

		filenames.forEach((filename) => {
			let filesDataIndex = filesData.findIndex((d) => (!!d.file && (d.file.name === filename)) || d.filenamePlaceholder === filename);
			const manifestFileData = fileManifest.Files[filename];

			if (filesDataIndex < 0) {
				filesDataIndex = this.getNextEmptyFileDataIndex(filesData); // Use the first available empty slot
				this.applyFilenamePlaceholder(manifestFileData, filename)
			}

			if (manifestFileData.FileTypeName) { // Make sure the FileTypeName is valid and blank it out if it is not
				if (!this.props.fileTypeDetailsMap[manifestFileData.FileTypeName])
					manifestFileData.FileTypeName = ""
			}

			if (filesDataIndex < 0) {
				// File does not exist in our list so add it with a placeholder filename

				filesData.push({
					...manifestFileData,
					key: nextFileDataKey++
				});

				return;
			}

			// replace the existing data with any overrides
			filesData[filesDataIndex] = { ...(filesData[filesDataIndex]), ...manifestFileData };
		});

		filesData = [ // only get filesData that have something filled in
			...filesData.filter((fileData) => !!fileData.file || !!fileData.filenamePlaceholder || !!fileData.FileTypeName)
		];
		this.props.setFilesData(filesData, nextFileDataKey);
	}

	getNextEmptyFileDataIndex(filesData) {
		for (let i = 0; i < filesData.length; i++) {
			const fileData = filesData[i];
			if ((!fileData.file || !fileData.file.name) && !fileData.filenamePlaceholder && !fileData.FileTypeName) {
				return i;
			}
		}

		return -1;
	}

	applyFilenamePlaceholder(fileData, filename) {
		fileData.filenamePlaceholder = filename;
		fileData.errorMessages = { file: `Please upload '${filename}' as listed in the file manifest.` };
	}
}