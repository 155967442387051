import React, { Fragment } from "react";
import _ from "lodash";
import clsx from "clsx";

export default class Input extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastValidValue: null
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);

		this.ref = React.createRef();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.autoFocus && prevProps.autoFocus !== this.props.autoFocus) {
			if (this.ref.current)
				_.defer(() => { this.ref.current.focus(); }); // Set focus
		}
	}

	render() {
		let { errorMessage, children, value, previousValue, showDirty, className, autoComplete, maxLength, onChange, ...rest } = this.props;

		value = this.getStringValue(value);
		previousValue = this.getStringValue(previousValue);

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		if (this.props.pattern && this.state.lastValidValue !== value) {
			let isValid = true;
			if (value) {
				const patternRegex = new RegExp(this.props.pattern);
				isValid = !!patternRegex.exec(value);
			}

			if (isValid)
				_.defer(() => { this.setState({ lastValidValue: value }); });
		}

		return (
			<Fragment>
				<input
					ref={this.ref}
					value={value}
					className={className}
					autoComplete={autoComplete || "off"}
					onChange={this.onChange}
					onKeyDown={(e) => this.onKeyDown(e, previousValue)}
					onBlur={this.onBlur}
					{...rest}
				>
					{children}
				</input>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}

	getStringValue(value) {
		if (value === undefined || value === null)
			value = "";
		else
			value = `${value}`; // Cast to a string

		return value;
	}

	onKeyDown(e, newValue) {
		if (e && e.key === "Escape") {
			e.target.value = newValue;
			this.onChange(e);

			e.preventDefault();
			return false;
		}

		if (this.props.onKeyDown)
			this.props.onKeyDown(e);
	}

	onChange(e) {
		if (this.props.maxLength && e.target.value.length > this.props.maxLength) {
			e.target.value = e.target.value.substring(0, this.props.maxLength);
		}

		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}

	onBlur(e) {
		if (this.props.pattern && e.target.value) {
			const patternRegex = new RegExp(this.props.pattern);
			if (!patternRegex.exec(e.target.value)) {
				alert("Value is invalid.\nReverting to last valid value.\nValue must match pattern: " + this.props.pattern)

				e.target.value = this.state.lastValidValue;
				this.onChange(e);

				e.target.focus();

				return false;
			}
		}

		if (this.props.onBlur)
			this.props.onBlur(e);
	}
}
