import React, { Fragment } from "react";
import EntityFilter from "./EntityFilter";
import EntityLinkList from "./EntityLinkList";
import _ from "lodash";

export default class EntityFilterLinkList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredEntities: null
		};

		this.setFilteredEntities = this.setFilteredEntities.bind(this);

		_.defer(() => this.setFilteredEntities(this.props.entities));
	}

	setFilteredEntities(filteredEntities) {
		this.setState({ filteredEntities });
	}

	render() {
		return (
			<div className="entry-table-container entity-filter-link-list">
				{(this.props.entities ?? []).length >= (this.props.filterEntityCount ?? 6) ? // show filter only if more than filterEntityCount
					<EntityFilter
						entities={this.props.entities}
						setFilteredEntities={this.setFilteredEntities}
						showTags={this.props.showTags}
					/>
					: <Fragment />
				}
				<EntityLinkList
					urlBase={this.props.urlBase}
					entities={this.state.filteredEntities}
					showTags={this.props.showTags}
				/>
			</div>
		);
	}
}
