import React from "react";
import Input from "../../Shared/Input"
import Radio from "../../Shared/Radio"
import EntryRow from "../../Shared/EntryRow"
import ComboBox from "../../Shared/ComboBox";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import _ from "lodash";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import "./InformationFields.scss";

export default class InformationFields extends React.Component {
	constructor(props) {
		super(props);

		this.verifyState();

		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleDataChangeBool = this.handleDataChangeBool.bind(this);
		this.isValidNewTag = this.isValidNewTag.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		this.verifyState(prevProps, prevState);
	}

	verifyState(prevProps, prevState) {
		if (!prevProps || prevProps.entityProfile !== this.props.entityProfile) {
			// Handle entityProfile change
			const state = {
			};

			if (prevProps)
				this.setState(state);
			else {
				// Ignore warning about setting the state directly:
				// eslint-disable-next-line
				this.state = state; // Called from the constructor
			}
		}
	}

	render() {
		const previousEntityProfile = this.props.previousEntityProfile || {};
		const availableTags = this.props.availableTags || [];
		const formatPocLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}

		return (
			<div className="entry-table-container entity-profile-information-fields" >
				<fieldset>
					<legend>Information</legend>
					<div className="entry-table-inner-container" >
						<table>
							<tbody>
								<EntryRow label="Supplier Number" title="Use Oracle Supplier Number.">
									<Input type="text" maxLength={50}
										name="SupplierNumber"
										value={this.props.entityProfile.SupplierNumber || ""}
										previousValue={previousEntityProfile.SupplierNumber || ""}
										showDirty={!this.props.isNew}
										onChange={this.handleDataChange}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="POC" title="Point of contact - Program Manager or Viasat Contact for this Supplier." innerDivStyle={{ overflow: "visible" }}>
									<ComboBoxAsync
										className="poc-combo-box"
										name="POC"
										value={this.props.entityProfile.POC}
										previousValue={previousEntityProfile.POC}
										showDirty={!this.props.isNew}
										loadOptions={_.debounce(this.loadUsernameOptions, 250)}
										isSearchable={true}
										onChange={this.handleDataChange}
										noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
										formatOptionLabel={formatPocLabel}
										isClearable={true}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="Original Requester" title="Person requesting entity creation." innerDivStyle={{ overflow: "visible" }}>
									<ComboBoxAsync
										className="original-requester-combo-box"
										name="OriginalRequester"
										value={this.props.entityProfile.OriginalRequester}
										previousValue={previousEntityProfile.OriginalRequester}
										showDirty={!this.props.isNew}
										loadOptions={_.debounce(this.loadUsernameOptions, 250)}
										isSearchable={true}
										onChange={this.handleDataChange}
										noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
										formatOptionLabel={formatPocLabel}
										isClearable={true}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="User Approval Needed?" title="Indicates if user approval is needed.">
									<Radio
										label="Yes"
										name="UserApprovalNeeded"
										optionValue={true}
										checked={this.props.entityProfile.UserApprovalNeeded}
										title="User approval is needed."
										previousValue={previousEntityProfile.UserApprovalNeeded}
										onChange={this.handleDataChangeBool}
										showDirty={!this.props.isNew}
										disabled={this.props.disabled}
									/>
									<Radio
										label="No"
										name="UserApprovalNeeded"
										optionValue={false}
										checked={!this.props.entityProfile.UserApprovalNeeded}
										title="User approval is NOT needed."
										previousValue={!previousEntityProfile.UserApprovalNeeded}
										onChange={this.handleDataChangeBool}
										showDirty={!this.props.isNew}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="Tags" title="Tags can indicate security requirements or other attributes of an entity." innerDivStyle={{ overflow: "visible" }}>
									<ComboBox
										name="Tags"
										value={(this.props.entityProfile.Tags || []).map((tag) => { return { label: tag, value: tag } })}
										previousValue={(previousEntityProfile.Tags || []).map((tag) => { return { label: tag, value: tag } })}
										options={(availableTags || []).map((tag) => { return { label: tag, value: tag } })}
										isClearable={false}
										isMulti={true}
										isSearchable={true}
										closeMenuOnSelect={true}
										backspaceRemovesValue={true}
										onChange={this.handleDataChange}
										showDirty={!this.props.isNew}
										disabled={this.props.disabled}
										isValidNewOption={this.isValidNewTag}
									/>
								</EntryRow>
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
		);
	}

	isValidNewTag(inputValue, selectValue, selectOptions, accessors) {
		if (!inputValue)
			return false; // Zero-length not allowed

		if (selectOptions && selectOptions.some(o => o.value.toLowerCase() === inputValue.toLowerCase()))
			return false; // Cannot create if it already exists

		if (this.props.entityProfile.Tags && this.props.entityProfile.Tags.some(t => t.toLowerCase() === inputValue.toLowerCase()))
			return false; // Cannot create if it already exists

		return true;
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers("Viasat", inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					const value = `${domainUser.FirstName || ""} ${domainUser.LastName || ""} <${domainUser.EmailAddress}>`;
					return {
						value: value,
						label: value,
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
				this.setState({ isGettingDomainOptions: false });
			}
		);
	}

	handleDataChangeBool(e) {
		this.handleDataChange(e, true);
	}

	handleDataChange(e, isBool) {
		let { name, value } = extractDomElement(e.target, isBool);

		const entityProfile = {
			...this.props.entityProfile,
			[name]: value
		};

		this.props.setEntityProfile(entityProfile);
	}
}