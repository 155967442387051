import React, { Fragment } from "react";
import _ from "lodash";
import UploadStatus from "./UploadStatus"
import setStateAsync from "../../Helpers/setStateAsync";
import { evaluate, getValueDeclarations } from "../../Helpers/SharedFunctions";

export default class UploadFilesButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showUploadStatus: false,
			isUploadComplete: false,
			uploadStatus: "",
			filesDataUploadStates: []
		};

		this.uploadFiles = this.uploadFiles.bind(this);
		this.setFileDataUploadState = this.setFileDataUploadState.bind(this);
		this.handleFileProgressPercent = this.handleFileProgressPercent.bind(this);
		this.closeUploadStatusModal = this.closeUploadStatusModal.bind(this);
		this.setStateAsync = setStateAsync.bind(this);
	}

	render() {
		const { filesData, setFilesData, entityGuid, fileTypeDetailsMap, apiService, ...rest } = this.props;
		const isMultiple = this.props.filesData.length > 1;
		const tooltip = `Upload file${isMultiple ? "s" : ""} to server.`;

		return (
			<Fragment>
				<button {...rest} title={tooltip} onClick={this.uploadFiles}>Upload File{isMultiple ? "s" : ""}</button>
				<UploadStatus
					filesDataUploadStates={this.state.filesDataUploadStates}
					uploadStatus={this.state.uploadStatus}
					isUploadComplete={this.state.isUploadComplete}
					isOpen={this.state.showUploadStatus}
					closeModal={this.closeUploadStatusModal}
				/>
			</Fragment>
		);
	}

	closeUploadStatusModal() {
		this.setState({ showUploadStatus: false });
	}

	async uploadFiles() {
		let cleanFilesData = this.getCleanFilesData();
		if (cleanFilesData.hasErrors) {
			delete cleanFilesData.hasErrors;
			this.props.setFilesData(cleanFilesData);
			_.delay(() => alert("Invalid upload data. Please correct all errors then try again."), 100); // Give the error messages a chance to pop up before the alert
			return;
		}
		let successFilesDataResults = [];
		let failedFilesDataResults = [];
		const filesDataUploadStates = cleanFilesData.map((fileData) => { return { fileData: fileData, percentComplete: 0 } });
		await this.setStateAsync({ showUploadStatus: true, isUploadComplete: false, filesDataUploadStates: filesDataUploadStates });

		for (let i = 0; i < cleanFilesData.length; i++) {
			const fileData = cleanFilesData[i];
			await this.setStateAsync({ uploadStatus: `Uploading '${fileData.file.name}'...` });
			const result = await this.uploadFileData(fileData, i);

			if (result && result.success === true) {
				successFilesDataResults.push({ result: result, fileData: fileData });
				this.setFileDataUploadState(i, { percentComplete: 100 });
			}
			else {
				fileData.errorMessages = this.getErrorMessages(result);
				failedFilesDataResults.push({ result: result, fileData: fileData });
				this.setFileDataUploadState(i, { percentComplete: 100, hasErrors: true });
			}
		}

		// remove the files that were successfully uploaded
		const failedFilesData = failedFilesDataResults.map((fileDataResult) => fileDataResult.fileData);

		this.props.setFilesData(failedFilesData);

		const failedMessage = failedFilesDataResults.length === 0 ? "" : `, ${failedFilesDataResults.length} failed upload${failedFilesDataResults.length !== 1 ? "s" : ""}`

		await this.setStateAsync({
			isUploadComplete: true,
			uploadStatus: `Upload complete! ${successFilesDataResults.length} successful upload${successFilesDataResults.length !== 1 ? "s" : ""}${failedMessage}.`
		});
	}

	// Clean up filesData objects by removing any metadata properties that do not match the definition of the selected fileTypeName
	getCleanFilesData() {
		const requiredErrorMessage = "Field is required.";
		let hasErrors = false;
		const cleanFilesData = JSON.parse(JSON.stringify(this.props.filesData)); // deep copy with no obj refs

		for (let i = 0; i < cleanFilesData.length; i++) {
			const fileData = cleanFilesData[i];
			fileData.errorMessages = {}; // clear the error messages
			fileData.file = this.props.filesData[i].file; // restore the file object which cannot be deep copied

			if (!fileData.file || !fileData.file.name) {
				const filenamePlaceholder = fileData.filenamePlaceholder ? `: '${fileData.filenamePlaceholder}'` : ".";
				fileData.errorMessages["file"] = `File is required${filenamePlaceholder}`
				hasErrors = true;
			}

			// Clean the metadata properties
			const fileTypeDetails = this.props.fileTypeDetailsMap[fileData.FileTypeName];
			if (!fileTypeDetails) {
				fileData.errorMessages["FileTypeName"] = "File Type is required.";
				hasErrors = true;
				continue;
			}

			const hiddenMetadataFields = {};
			const valueDeclarations = getValueDeclarations(fileData.Metadata, fileTypeDetails.MetadataFields);
			fileTypeDetails.MetadataFields.forEach((metadataField) => {
				if (!evaluate(metadataField.AvailableCondition, valueDeclarations))
					hiddenMetadataFields[metadataField.FieldName] = true;
			});

			const validMetadataFieldNames = fileTypeDetails.MetadataFields
				.filter((metadataField) => !hiddenMetadataFields[metadataField.FieldName])
				.map((metadataField) => metadataField.FieldName);

			// make sure required metadata fields are filled in
			for (let j = 0; j < validMetadataFieldNames.length; j++) {
				const validMetadataFieldName = validMetadataFieldNames[j];
				const validMetadataField = fileTypeDetails.MetadataFields.find(metadataField => metadataField.FieldName === validMetadataFieldName);

				if (validMetadataField.IsStaticValue)
					continue; // Do not validate static fields

				if (validMetadataField.IsRequired) {
					const metadataFieldValue = fileData.Metadata[validMetadataField.FieldName];
					if (metadataFieldValue === undefined || metadataFieldValue === null || metadataFieldValue === "") {
						fileData.errorMessages[validMetadataField.FieldName] = requiredErrorMessage;
						hasErrors = true;
					}
				}
			}

			const providedMetadataFieldNames = Object.getOwnPropertyNames(fileData.Metadata);
			// delete metadata fields that are not needed for this file type
			for (let j = 0; j < providedMetadataFieldNames.length; j++) {
				const providedMetadataFieldName = providedMetadataFieldNames[j];
				if (!validMetadataFieldNames.find((target) => providedMetadataFieldName === target)) {
					delete fileData.Metadata[providedMetadataFieldName];
				}
			}
		}

		if (hasErrors)
			cleanFilesData.hasErrors = hasErrors;

		return cleanFilesData;
	}

	setFileDataUploadState(fileDataIndex, props) {
		const filesDataUploadStates = [...this.state.filesDataUploadStates];
		filesDataUploadStates[fileDataIndex] = { ...filesDataUploadStates[fileDataIndex], ...props }
		this.setState({ filesDataUploadStates: filesDataUploadStates });
	}

	async uploadFileData(fileData, fileDataIndex) {
		return new Promise(async (callback) => {
			let metadataResult;
			try {
				this.handleFileProgressPercent(fileDataIndex, 0.01);
				metadataResult = await this.uploadFileMetadata(fileData, fileDataIndex, true);
			}
			catch (ex) {
				console.error(ex);
				callback({ success: false, exception: ex, message: "Failed to upload metadata!" });
				return;
			}

			let fileResult;
			try {
				fileResult = await this.uploadFileMultiPart(fileData, fileDataIndex, metadataResult.data.FileGuid, metadataResult.data.MultipartUploadId)
				callback({ success: true, metadataResult: metadataResult, fileResult: fileResult });
			}
			catch (ex) {
				console.error(ex);
				callback({ success: false, exception: ex, message: "Failed to upload file!" });
				return;
			}
		});
	}

	uploadFileMetadata(fileData, fileDataIndex, isMultiPart) {
		return this.props.apiService.uploadFileMetadata(
			this.props.entityGuid,
			fileData.file,
			fileData.FileTypeName,
			isMultiPart,
			fileData.Metadata);
	}

	uploadFile(fileData, fileDataIndex, fileGuid, uploadUrl) {
		return this.props.apiService.uploadFile(
			this.props.entityGuid,
			fileGuid,
			uploadUrl,
			fileData.file,
			(fileProgressPercent) => this.handleFileProgressPercent(fileDataIndex, fileProgressPercent));
	}

	uploadFileMultiPart(fileData, fileDataIndex, fileGuid, multipartUploadId) {
		return this.props.apiService.uploadFileMultiPart(
			this.props.entityGuid,
			fileGuid,
			multipartUploadId,
			fileData.file,
			(fileProgressPercent) => this.handleFileProgressPercent(fileDataIndex, fileProgressPercent));
	}

	getErrorMessages(result) {
		let errorMessages = {};
		let generalErrorMessage = result.message || "Upload failed!";
		if (result.exception && result.exception.response && result.exception.response.data) {
			if (result.exception.response.data.ErrorMessage) {
				generalErrorMessage += "\n" + result.exception.response.data.ErrorMessage;
			}
			if (result.exception.response.data.ErrorDetails) {
				const errorFieldNames = Object.getOwnPropertyNames(result.exception.response.data.ErrorDetails);
				for (let i = 0; i < errorFieldNames.length; i++) {
					const errorFieldName = errorFieldNames[i];
					errorMessages[errorFieldName] = result.exception.response.data.ErrorDetails[errorFieldName].Errors.join(" - ");
				}
			}
		}

		errorMessages._ = generalErrorMessage;

		return errorMessages;
	}

	handleFileProgressPercent(fileDataIndex, fileProgressPercent) {
		this.setFileDataUploadState(fileDataIndex, { percentComplete: fileProgressPercent });
	}
}