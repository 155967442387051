import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import UserEntityProfileEditModal from "./UserEntityProfileEditModal";
import UserGroupEntityProfileEditModal from "./UserGroupEntityProfileEditModal";
import clsx from "clsx";
import _ from "lodash";

export default class UsersList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.usersListRowContextMenuName = `UsersListRowContextMenu_${uniqueName}`;

		this.state = {
			selectedUsersRowKeyList: [],
			showUserEntityProfileEditModal: false,
			editingUserEntityProfile: null,

			selectedUserGroupsRowKeyList: [],
			showUserGroupEntityProfileEditModal: false,
			editingUserGroupEntityProfile: null
		};

		this.userTableColumns = [
			{ fieldName: "DomainUsername", displayName: "Username" },
			{ fieldName: "Name", displayName: "Name", customValue: (rowObject) => `${rowObject["FirstName"] || ""} ${rowObject["LastName"] || ""}` },
			{ fieldName: "Enabled", displayName: "Enabled?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Enabled" : "Disabled" },
			{
				fieldName: "UsableRoleNames", displayName: "Usable Roles", customValue: (rowObject, fieldName) => {
					const roleNames = rowObject[fieldName] || [];
					return roleNames.join(", ");
				},
			}
		];

		this.userGroupTableColumns = [
			{ fieldName: "DomainUserGroupName", displayName: "Group Name" },
			{ fieldName: "UserCount", displayName: "User Count" },
			{
				fieldName: "UsableRoleNames", displayName: "Usable Roles", customValue: (rowObject, fieldName) => {
					const roleNames = rowObject[fieldName] || [];
					return roleNames.join(", ");
				},
			}
		];

		this.closeUserEntityProfileEditModal = this.closeUserEntityProfileEditModal.bind(this);
		this.setUserEntityProfile = this.setUserEntityProfile.bind(this);
		this.setSelectedUserRowKeys = this.setSelectedUserRowKeys.bind(this);
		this.addUser = this.addUser.bind(this);
		this.editUser = this.editUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.closeUserGroupEntityProfileEditModal = this.closeUserGroupEntityProfileEditModal.bind(this);
		this.setUserGroupEntityProfile = this.setUserGroupEntityProfile.bind(this);
		this.setSelectedUserGroupRowKeys = this.setSelectedUserGroupRowKeys.bind(this);
		this.addUserGroup = this.addUserGroup.bind(this);
		this.editUserGroup = this.editUserGroup.bind(this);
		this.deleteUserGroup = this.deleteUserGroup.bind(this);
	}

	render() {
		return (
			<div>
				<div className={clsx("list-table-container", "entity-profile-user-list", this.props.usersClassName)} >
					<fieldset>
						<legend>{this.props.usersTitle || "Users"}</legend>
						<div className="entry-table-inner-container" >
							<SelecTable className="profile-table"
								columns={this.userTableColumns}
								rowObjects={this.props.userEntityProfiles}
								getRowKey={(rowData) => rowData.DomainUsername}
								selectMode={this.props.disableUsers ? "single" : "multi"}
								setSelectedRowKeys={this.setSelectedUserRowKeys}
								contextMenuId={this.usersListRowContextMenuName}
								onDoubleClick={(...args) => this.editUser(args[2])}
							/>
							{this.props.disableUsers ? <Fragment /> : (
								<div className="button-bar">
									<button onClick={this.addUser}>Add New...</button>
								</div>)
							}
							<ContextMenu id={this.usersListRowContextMenuName}>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editUser(data.selectedRowKeys); })}
									disabled={this.state.selectedUsersRowKeyList.length !== 1}>View{this.props.disableUsers ? "" : "/Edit"}...</MenuItem>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editUser(data.selectedRowKeys, true); })}
									disabled={this.props.disableUsers || this.state.selectedUsersRowKeyList.length !== 1}>Duplicate...</MenuItem>
								<MenuItem divider />
								<MenuItem onClick={(e, data) => _.defer(() => { this.deleteUser(data.selectedRowKeys); })}
									disabled={this.props.disableUsers || this.state.selectedUsersRowKeyList.length === 0}>Delete</MenuItem>
							</ContextMenu>
							<UserEntityProfileEditModal
								isOpen={this.state.showUserEntityProfileEditModal}
								closeModal={this.closeUserEntityProfileEditModal}
								editingUserEntityProfile={this.state.editingUserEntityProfile}
								setUserEntityProfile={this.setUserEntityProfile}
								availableRoleNames={this.props.availableRoleNames}
								entityGuid={this.props.entityGuid}
								config={this.props.config}
								disabled={this.props.disableUsers}
							/>
						</div>
					</fieldset>
				</div>

				<div className={clsx("list-table-container", "entity-profile-user-list", this.props.userGroupsClassName)} >
					<fieldset>
						<legend>{this.props.userGroupsTitle || "User Groups"}</legend>
						<div className="entry-table-inner-container" >
							<SelecTable className="profile-table"
								columns={this.userGroupTableColumns}
								rowObjects={this.props.userGroupEntityProfiles}
								getRowTitle={(rowData) => `${rowData.Description}\nOwner: ${rowData.OwnerDomainUsername}`}
								getRowKey={(rowData) => rowData.DomainUserGroupName}
								selectMode={this.props.disableUserGroups ? "single" : "multi"}
								setSelectedRowKeys={this.setSelectedUserGroupRowKeys}
								contextMenuId="UserGroupsListRowContextMenu"
								onDoubleClick={(...args) => this.editUserGroup(args[2])}
							/>
							{this.props.disableUserGroups ? <Fragment /> : (
								<div className="button-bar">
									<button onClick={this.addUserGroup}>Add New...</button>
								</div>)
							}
							<ContextMenu id="UserGroupsListRowContextMenu">
								<MenuItem onClick={(e, data) => _.defer(() => { this.editUserGroup(data.selectedRowKeys); })}
									disabled={this.state.selectedUserGroupsRowKeyList.length !== 1}>View{this.props.disableUserGroups ? "" : "/Edit"}...</MenuItem>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editUserGroup(data.selectedRowKeys, true); })}
									disabled={this.props.disableUserGroups || this.state.selectedUserGroupsRowKeyList.length !== 1}>Duplicate...</MenuItem>
								<MenuItem divider />
								<MenuItem onClick={(e, data) => _.defer(() => { this.deleteUserGroup(data.selectedRowKeys); })}
									disabled={this.props.disableUserGroups || this.state.selectedUserGroupsRowKeyList.length === 0}>Delete</MenuItem>
							</ContextMenu>
							<UserGroupEntityProfileEditModal
								isOpen={this.state.showUserGroupEntityProfileEditModal}
								closeModal={this.closeUserGroupEntityProfileEditModal}
								editingUserGroupEntityProfile={this.state.editingUserGroupEntityProfile}
								setUserGroupEntityProfile={this.setUserGroupEntityProfile}
								availableRoleNames={this.props.availableRoleNames}
								entityGuid={this.props.entityGuid}
								config={this.props.config}
								disabled={this.props.disableUserGroups}
							/>
						</div>
					</fieldset>
				</div>

				{this.props.showAdminUsers === false ? <Fragment /> : (
					<div className="list-table-container entity-profile-user-list-admin" >
						<fieldset>
							<legend>System Admin Users (cannot be edited)</legend>
							<div className="entry-table-inner-container" >
								<SelecTable className="profile-table"
									columns={this.userTableColumns}
									rowObjects={this.props.adminUserEntityProfiles}
									getRowKey={(rowData) => rowData.DomainUsername}
									selectMode="none"
								/>
							</div>
						</fieldset>
					</div>
				)}
			</div>
		);
	}

	setUserEntityProfile(userEntityProfile, userEntityProfileOriginalDomainUsername) {
		const userEntityProfiles = [
			...(this.props.userEntityProfiles || [])
		];

		if (!userEntityProfile.DomainUsername) {
			alert("Domain/username cannot be empty.");
			return false;
		}

		if (userEntityProfile.DomainUsername !== userEntityProfileOriginalDomainUsername) {
			// Check for duplicates
			let savedUserEntityProfile = null;
			if (userEntityProfiles.some((thisUserEntityProfile) => {
				savedUserEntityProfile = thisUserEntityProfile;
				return thisUserEntityProfile.DomainUsername.toLowerCase() === userEntityProfile.DomainUsername.toLowerCase();
			})) {
				alert(`User '${savedUserEntityProfile.DomainUsername}' already exists and cannot be added again. Edit the existing entry instead.`);
				return false;
			}
		}

		let isUpdated = false;

		for (let i = userEntityProfiles.length - 1; i >= 0; i--) {
			const thisUserEntityProfile = userEntityProfiles[i];
			if (thisUserEntityProfile.DomainUsername === userEntityProfileOriginalDomainUsername) {
				userEntityProfiles[i] = userEntityProfile;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			userEntityProfiles.push(userEntityProfile);

		this.props.setUserEntityProfiles(userEntityProfiles);
		return true;
	}

	setSelectedUserRowKeys(selectedUserRowKeys) {
		this.setState({ selectedUsersRowKeyList: Object.getOwnPropertyNames(selectedUserRowKeys) });
	}

	addUser() {
		this.editUser(null);
	}

	editUser(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const matchingUserEntityProfile = (this.props.userEntityProfiles || []).filter((userEntityProfile) => userEntityProfile.DomainUsername === selectedRowKey);

		let editingUserEntityProfile = matchingUserEntityProfile.length > 0 ? matchingUserEntityProfile[0] : { Enabled: true };

		if (createCopy)
			editingUserEntityProfile = { ...editingUserEntityProfile, DomainUsername: null, Username: null, FirstName: null, LastName: null, EmailAddress: null };

		this.setState({ editingUserEntityProfile: editingUserEntityProfile, showUserEntityProfileEditModal: true });
	}

	deleteUser(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete access for selected user${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const userEntityProfiles = (this.props.userEntityProfiles || []).filter((userEntityProfile) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === userEntityProfile.DomainUsername));

			this.props.setUserEntityProfiles(userEntityProfiles);
		}
	}

	closeUserEntityProfileEditModal() {
		this.setState({ showUserEntityProfileEditModal: false });
	}


	setUserGroupEntityProfile(userGroupEntityProfile, userGroupEntityProfileOriginalDomainUserGroupName) {
		const userGroupEntityProfiles = [
			...(this.props.userGroupEntityProfiles || [])
		];

		if (!userGroupEntityProfile.DomainUserGroupName) {
			alert("User Group name cannot be empty.");
			return false;
		}

		if (userGroupEntityProfile.DomainUserGroupName !== userGroupEntityProfileOriginalDomainUserGroupName) {
			// Check for duplicates
			let savedUserGroupEntityProfile = null;
			if (userGroupEntityProfiles.some((thisUserGroupEntityProfile) => {
				savedUserGroupEntityProfile = thisUserGroupEntityProfile;
				return thisUserGroupEntityProfile.DomainUserGroupName.toLowerCase() === userGroupEntityProfile.DomainUserGroupName.toLowerCase();
			})) {
				alert(`User Group '${savedUserGroupEntityProfile.DomainUserGroupName}' already exists and cannot be added again. Edit the existing entry instead.`);
				return false;
			}
		}

		let isUpdated = false;

		for (let i = userGroupEntityProfiles.length - 1; i >= 0; i--) {
			const thisUserGroupEntityProfile = userGroupEntityProfiles[i];
			if (thisUserGroupEntityProfile.DomainUserGroupName === userGroupEntityProfileOriginalDomainUserGroupName) {
				userGroupEntityProfiles[i] = userGroupEntityProfile;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			userGroupEntityProfiles.push(userGroupEntityProfile);

		this.props.setUserGroupEntityProfiles(userGroupEntityProfiles);
		return true;
	}

	setSelectedUserGroupRowKeys(selectedUserGroupRowKeys) {
		this.setState({ selectedUserGroupsRowKeyList: Object.getOwnPropertyNames(selectedUserGroupRowKeys) });
	}

	addUserGroup() {
		this.editUserGroup(null);
	}

	editUserGroup(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const matchingUserGroupEntityProfile = (this.props.userGroupEntityProfiles || []).filter((userGroupEntityProfile) => userGroupEntityProfile.DomainUserGroupName === selectedRowKey);

		let editingUserGroupEntityProfile = matchingUserGroupEntityProfile.length > 0 ? matchingUserGroupEntityProfile[0] : { Enabled: true };

		if (createCopy)
			editingUserGroupEntityProfile = { ...editingUserGroupEntityProfile, DomainUserGroupName: null, UserGroupName: null, FirstName: null, LastName: null, EmailAddress: null };

		this.setState({ editingUserGroupEntityProfile: editingUserGroupEntityProfile, showUserGroupEntityProfileEditModal: true });
	}

	deleteUserGroup(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete access for selected user group${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const userGroupEntityProfiles = (this.props.userGroupEntityProfiles || []).filter((userGroupEntityProfile) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === userGroupEntityProfile.DomainUserGroupName));

			this.props.setUserGroupEntityProfiles(userGroupEntityProfiles);
		}
	}

	closeUserGroupEntityProfileEditModal() {
		this.setState({ showUserGroupEntityProfileEditModal: false });
	}
}