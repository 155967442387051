import React, { Fragment } from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import MainOptions from "./MainOptions";
import InformationFields from "./InformationFields";
import FileTypeList from "./FileTypeList";
import PluginList from "./PluginList";
import UsersList from "./UsersList";
import PageOverlay from "../../Shared/PageOverlay";
import PassiveMessage from "../../Shared/PassiveMessage";
import _ from "lodash";
import { Prompt } from "react-router-dom";

export default class EntityProfile extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.verifyState();

		this.leaveUnsavedMessage = "You have unsaved changes, are you sure you want to leave?";

		this.setEntityProfile = this.setEntityProfile.bind(this);
		this.setFileTypeDetailsList = this.setFileTypeDetailsList.bind(this);
		this.setPluginProfiles = this.setPluginProfiles.bind(this);
		this.setUserEntityProfiles = this.setUserEntityProfiles.bind(this);
		this.setUserGroupEntityProfiles = this.setUserGroupEntityProfiles.bind(this);

		this.saveProfile = this.saveProfile.bind(this);
		this.cloneProfile = this.cloneProfile.bind(this);
		this.deleteProfile = this.deleteProfile.bind(this);
		this.getDomainUsername = this.getMyDomainUsername.bind(this);
		this.handleCloseSaveNotification = this.handleCloseSaveNotification.bind(this);
		this.onBeforeUnload = this.onBeforeUnload.bind(this);
		this.openShowJsonModal = this.openShowJsonModal.bind(this);
		this.setEntityProfileFromShowJson = this.setEntityProfileFromShowJson.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
		window.addEventListener('beforeunload', this.onBeforeUnload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.verifyState(prevProps, prevState);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onBeforeUnload);
	}

	onBeforeUnload(e) {
		if (!this.state.isDirty)
			return;

		if (e)
			e.returnValue = this.leaveUnsavedMessage;

		return this.leaveUnsavedMessage;
	}

	verifyState(prevProps, prevState) {
		if (!prevProps || prevProps.match.params.entityGuid !== this.props.match.params.entityGuid) {
			if (this.state && this.state.entityProfile && this.state.entityProfile.isClone && this.props.match.params.entityGuid === "new")
				return; // Don't do anything if this is a new clone

			// Handle entityGuid change
			const isNew = this.props.match.params.entityGuid === "new";
			const placeholderEntityProfile = isNew ? { Enabled: true } : { Name: "..." };
			const state = {
				isNew: isNew,
				isInitialized: false,
				isLoading: true,
				entityProfile: placeholderEntityProfile,
				previousEntityProfile: placeholderEntityProfile,
				showSaveSuccessNotification: false,
				standardRoleNames: [],
				openShowJsonModal: false
			};

			if (prevProps)
				this.setState(state);
			else {
				// Ignore warning about setting the state directly:
				// eslint-disable-next-line
				this.state = state; // Called from the constructor
			}
		}
		else {
			const isDirty = this.state.entityProfile !== this.state.previousEntityProfile;
			if (this.state.isDirty !== isDirty) {
				this.setState({ isDirty });
			}
		}
	}

	render() {
		const action = "Profile";
		const heading = `${this.state.previousEntityProfile.Name || "[New Entity]"} ${action}`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		const entityProfile = this.state.entityProfile || {};
		const isAllEntities = entityProfile.Guid === this.context.allEntitiesGuid;
		const isSystemFileTypesEntity = entityProfile.Guid === this.context.systemFileTypesEntityGuid;
		const isSpecialEntity = isAllEntities || isSystemFileTypesEntity;
		let adminUserEntityProfiles = (entityProfile.AdminUserEntityProfiles || []);

		if (!isAllEntities) {
			// For regular entities, only keep users that have at least 1 standard role
			adminUserEntityProfiles = adminUserEntityProfiles.filter(p => {
				for (let i = 0; i < this.state.standardRoleNames.length; i++) {
					const standardRoleName = this.state.standardRoleNames[i];
					if (p.UsableRoleNames.includes(standardRoleName))
						return true;
				}
				return false;
			});
		}

		const hasChangeEntityDetails = this.context.hasPermission(entityProfile.Guid, "Change Entity Details");
		const hasChangeEntityPlugins = this.context.hasPermission(entityProfile.Guid, "Change Entity Plugins");
		const hasChangeEntityUsers = this.context.hasPermission(entityProfile.Guid, "Change Entity Users");
		const isAnyEditable = (entityProfile.Name !== "..." && (this.state.isNew || (hasChangeEntityDetails || hasChangeEntityPlugins || hasChangeEntityUsers)));

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<PageOverlay showOverlay={this.state.isLoadingPassive} />
				<LoadingWrapper isLoading={this.state.isLoading}>
					<h1>{heading}</h1>
					<MainOptions
						entityProfile={entityProfile}
						previousEntityProfile={this.state.previousEntityProfile}
						setEntityProfile={this.setEntityProfile}
						isSpecialEntity={isSpecialEntity}
						isNew={this.state.isNew}
						disabled={!(this.state.isNew || hasChangeEntityDetails)}
					/>
					{isAllEntities ? <Fragment /> :
						<Fragment>
							{isSystemFileTypesEntity ? <Fragment /> :
								<InformationFields
									config={this.props.config}
									entityProfile={entityProfile}
									previousEntityProfile={this.state.previousEntityProfile}
									setEntityProfile={this.setEntityProfile}
									isNew={this.state.isNew}
									availableTags={this.state.availableTags}
									disabled={!(this.state.isNew || hasChangeEntityDetails)}
								/>
							}
							<FileTypeList
								entityGuid={entityProfile.Guid}
								fileTypeDetailsList={entityProfile.FileTypeDetailsList}
								setFileTypeDetailsList={this.setFileTypeDetailsList}
								className={!this.state.isNew && this.state.previousEntityProfile.FileTypeDetailsList !== entityProfile.FileTypeDetailsList ? "is-dirty" : null}
								disabled={!(this.state.isNew || hasChangeEntityDetails)}
								isNew={this.state.isNew}
								config={this.props.config}
							/>
							{isSystemFileTypesEntity ? <Fragment /> :
								<PluginList
									entityGuid={entityProfile.Guid}
									pluginProfiles={entityProfile.PluginProfiles}
									setPluginProfiles={this.setPluginProfiles}
									className={!this.state.isNew && this.state.previousEntityProfile.PluginProfiles !== entityProfile.PluginProfiles ? "is-dirty" : null}
									disabled={!(this.state.isNew || hasChangeEntityPlugins)}
									isNew={this.state.isNew}
									config={this.props.config}
								/>
							}
						</Fragment>
					}
					<UsersList
						entityGuid={entityProfile.Guid}
						userEntityProfiles={entityProfile.UserEntityProfiles}
						userGroupEntityProfiles={entityProfile.UserGroupEntityProfiles}
						adminUserEntityProfiles={adminUserEntityProfiles}
						setUserEntityProfiles={this.setUserEntityProfiles}
						setUserGroupEntityProfiles={this.setUserGroupEntityProfiles}
						availableRoleNames={this.state.availableRoleNames}
						usersClassName={!this.state.isNew && this.state.previousEntityProfile.UserEntityProfiles !== entityProfile.UserEntityProfiles ? "is-dirty" : null}
						userGroupsClassName={!this.state.isNew && this.state.previousEntityProfile.UserGroupEntityProfiles !== entityProfile.UserGroupEntityProfiles ? "is-dirty" : null}
						disableUsers={!this.state.isNew && !hasChangeEntityUsers}
						disableUserGroups={!this.state.isNew && !this.context.hasPermission(entityProfile.Guid, "Change Entity User Groups")}
						showAdminUsers={!isAllEntities}
						usersTitle={isAllEntities ? "System Admin Users (users and roles apply to ALL Entities)" : null}
						userGroupsTitle={isAllEntities ? "System Admin User Groups (user groups and roles apply to ALL Entities)" : null}
						config={this.props.config}
					/>
					<div className="button-bar">
						{isAnyEditable ? <button onClick={this.saveProfile} title="Save all changes">Save</button> : <Fragment />}
						{entityProfile.Name !== "..." ? <button onClick={this.openShowJsonModal} title="Show JSON.">Show JSON</button> : <Fragment />}
						{!isSpecialEntity && !this.state.isNew && this.context.hasPermission("*", "Create Entities") ?
							<button onClick={this.cloneProfile} title="Clone the current entity profile and edit as a new record">Clone</button>
							: <Fragment />
						}
						{!isSpecialEntity && !this.state.isNew && this.context.hasPermission("*", "Delete Entities") ?
							<button onClick={this.deleteProfile} title="Delete the current entity. All files for this entity must be deleted first.">Delete</button>
							: <Fragment />
						}
					</div>
				</LoadingWrapper>
				<ShowJsonModal
					heading={`${heading} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.entityProfileForShowJson}
					setValue={this.setEntityProfileFromShowJson}
					showDirty={!this.state.isNew}
					disabled={this.props.disabled || !isAnyEditable}
				/>
				<Prompt
					when={this.state.isDirty}
					message={(location, action) => this.leaveUnsavedMessage}
				/>
				<PassiveMessage
					open={this.state.showSaveSuccessNotification}
					onClose={this.handleCloseSaveNotification}
					variant="success"
					message="Record saved successfully!" />
			</Layout >
		);
	}

	initialize() {
		const entityGuid = this.props.match.params.entityGuid;
		this.apiService.getEntityProfile(entityGuid,
			(response) => {
				const isAllEntities = entityGuid === this.context.allEntitiesGuid;
				let availableRoleNames = response.data.UserAssignableRoleNames;
				const standardRoleNames = response.data.StandardRoleNames;
				const availableTags = response.data.Tags

				availableRoleNames = availableRoleNames
					.filter((roleName) => isAllEntities || standardRoleNames.includes(roleName)) // take out the global-only role names if this is not the "all entities" GUID
					.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);

				if (this.state.isNew)
					response.data.EntityProfile.Guid = null;

				this.setState({
					isLoading: false,
					isLoadingPassive: false,
					entityProfile: response.data.EntityProfile,
					previousEntityProfile: response.data.EntityProfile,
					availableRoleNames,
					standardRoleNames,
					availableTags
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);

		this.setState({ isInitialized: true });
	}

	openShowJsonModal() {
		this.setState({
			openShowJsonModal: true,
			entityProfileForShowJson: this.getEntityProfileForShowJson(this.state.entityProfile)
		});
	}

	setEntityProfileFromShowJson(entityProfile) {
		entityProfile = {
			...this.state.entityProfile,
			...this.getEntityProfileForShowJson(entityProfile)
		};

		this.setEntityProfile(entityProfile);
		this.setState({ openShowJsonModal: false });
	}

	getEntityProfileForShowJson(entityProfile) {
		entityProfile = { ...entityProfile };

		// Exclude the following properties from the JSON view since they may not transfer between environments well
		delete entityProfile.Guid;
		delete entityProfile.UserEntityProfiles;
		delete entityProfile.UserGroupEntityProfiles;
		delete entityProfile.AdminUserEntityProfiles;
		delete entityProfile.PluginProfiles
		delete entityProfile.UpdateDateTime

		return entityProfile;
	}

	saveProfile() {
		const entityProfile = this.state.entityProfile;
		if (!entityProfile.Name) {
			alert("Entity name is required!");
			return;
		}
		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });
		const entityGuid = entityProfile.Guid;
		const me = this;

		this.apiService.setEntityProfile(entityProfile,
			(response) => {
				// Refresh the web user session context when an entity is saved (in case the user edits their own roles)
				this.apiService.getWebUserSession(
					(userSessionResponse) => {
						this.context.setWebUserSession(userSessionResponse.data.WebUserSession);

						if (response.data.EntityGuid !== entityGuid) {
							// New entity
							_.defer(() => {
								const newRoute = `/entityProfile/${response.data.EntityGuid}`;
								this.props.history.push(newRoute);
							});
							this.setState({
								previousEntityProfile: this.state.entityProfile, // Set the previous to the current to prevent an "unsaved changes" warning.
								showSaveSuccessNotification: true
							});
						}
						else {
							// Updated entity - force a refresh
							this.setState({
								showSaveSuccessNotification: true,
								isInitialized: false
							});
						}
					},
					(error) => {
						handleApiError(error);
						me.setState({ isLoadingPassive: false });
					}
				);
			},
			(error) => {
				handleApiError(error);
				me.setState({ isLoadingPassive: false });
			}
		);
	}

	deleteProfile() {
		const entityName = this.state.entityProfile.Name;

		if (!window.confirm(`Please ensure all files uploaded to this entity have been deleted before deleting this entity.\r\n\r\nAre you sure you want to delete entity "${entityName}"?`))
			return;

		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });

		const entityGuid = this.state.entityProfile.Guid;
		const me = this;
		
		this.apiService.deleteEntityProfile(entityGuid,
			(response) => {
				alert(`Entity "${entityName}" has been deleted.`);

				// Navigate to entities list page
				_.defer(() => {
					const newRoute = `/entityProfile`;
					this.props.history.push(newRoute);
				});
			},
			(error) => {
				handleApiError(error);
				me.setState({ isLoadingPassive: false });
			}
		);
	}

	cloneProfile() {
		if (this.state.isDirty) {
			if (!window.confirm("Are you sure you want to clone this entity?\r\nAny unsaved changes to the current entity will be lost."))
				return;

			this.setState({ isDirty: false });
		}

		_.defer(() => {
			const entityProfile = { ...this.state.entityProfile, isClone: true, Guid: null, Name: null };
			this.setState({
				isNew: true,
				entityProfile: entityProfile,
				previousEntityProfile: entityProfile,
				isDirty: false
			});
			this.setRoute("/entityProfile/new");
		});
	}

	setRoute(newRoute) {
		this.props.history.push(newRoute);
	}

	setEntityProfile(entityProfile) {
		this.setState({ entityProfile });
	}

	setFileTypeDetailsList(fileTypeDetailsList) {
		const entityProfile = { ...this.state.entityProfile, FileTypeDetailsList: fileTypeDetailsList };
		this.setState({ entityProfile });
	}

	setPluginProfiles(pluginProfiles) {
		const entityProfile = { ...this.state.entityProfile, PluginProfiles: pluginProfiles };
		this.setState({ entityProfile });
	}

	setUserEntityProfiles(userEntityProfiles) {
		const entityProfile = { ...this.state.entityProfile, UserEntityProfiles: userEntityProfiles };
		this.setState({ entityProfile });
	}

	setUserGroupEntityProfiles(userGroupEntityProfiles) {
		const entityProfile = { ...this.state.entityProfile, UserGroupEntityProfiles: userGroupEntityProfiles };
		this.setState({ entityProfile });
	}

	getMyDomainUsername() {
		const webUserSession = this.context.getWebUserSession();
		if (!webUserSession || !webUserSession.UserDetails)
			return null;

		let domainUsername = webUserSession.UserDetails.DomainUsername;

		return domainUsername;
	}

	handleCloseSaveNotification(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ showSaveSuccessNotification: false });
	}
}
