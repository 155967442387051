import React from "react";
import { showMenu, hideMenu } from "react-contextmenu";
import "../Content/react-contextmenu.scss";
import _ from "lodash";
import clsx from "clsx";
import { truncate } from "../Helpers/SharedFunctions";
import "./SelecTable.scss";

export default class SelecTable extends React.Component {
	constructor(props) {
		super(props);

		this.noRowsMessage = props.noRowsMessage === undefined ? "---" : props.noRowsMessage;
		this.getSelectedRowKeys = this.getSelectedRowKeys.bind(this);
		this.selectRow = this.selectRow.bind(this);
		this.getRowKeysBetween = this.getRowKeysBetween.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.selectAllRows = this.selectAllRows.bind(this);
		this.onDoubleClickRowClosure = this.onDoubleClickRowClosure.bind(this);
		this.onMouseDownRowClosure = this.onMouseDownRowClosure.bind(this);
		this.onMouseEnterRowClosure = this.onMouseEnterRowClosure.bind(this);
		this.onContextMenuClosure = this.onContextMenuClosure.bind(this);
		this.showRowMenu = this.showRowMenu.bind(this);
		this.onClickColumnHeader = this.onClickColumnHeader.bind(this);
		this.getOrderByArray = this.getOrderByArray.bind(this);
		
		this.state = {
			lastSelectedRowKey: null,
			selectedRowKeys: {},
			orderByArray: this.getOrderByArray()
		};
	}

	componentDidMount() {
		document.addEventListener("keydown", this.handleKeyDown);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.orderBy !== this.props.orderBy) {
			this.setState({ orderByArray: this.getOrderByArray() });
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown);
	}

	render() {
		const { className } = this.props;

		return (
			<div className="selec-table">
				<table className={className}>
					<tbody>
						{this.getTableHeaderRow()}
						{this.getTableRows()}
					</tbody>
				</table>
			</div>
		);
	}

	handleKeyDown(e) {
		if (this.props.enableSelectAll) {
			if (e.ctrlKey && e.key === "a" && (!e.path || !e.path[0] || e.path[0].tagName !== "INPUT")) {
				e.preventDefault();
				this.selectAllRows();
			}
		}
	}

	selectAllRows() {
		if (!this.props.rowObjects || this.props.rowObjects.length === 0)
			return;

		const selectedRowKeys = this.getRowKeysBetween(this.props.getRowKey(this.props.rowObjects[0]), this.props.getRowKey(this.props.rowObjects[this.props.rowObjects.length - 1]));

		this.setSelectedRowKeys(selectedRowKeys);

		return;
	}

	getTableHeaderRow() {
		const columns = this.props.columns || [];
		return (<tr>
			{columns.map((column, index) => {
				if (typeof column !== "object") {
					column = { fieldName: column, displayName: column }
				}

				let displayName = column.displayName;
				let onClick = null;
				if (column.isSortable && column.fieldName) {
					onClick = () => this.onClickColumnHeader(column.fieldName);
					const matchingFields = this.state.orderByArray.filter((f) => f.fieldName.toUpperCase() === column.fieldName.toUpperCase());

					if (matchingFields.length > 0) {
						const matchingField = matchingFields[0];
						if (matchingField.direction && matchingField.direction === -1) {
							displayName += " ▼"; // down arrow triangle
						}
						else {
							displayName += " ▲"; // up arrow triangle
						}
					}
				}

				return (<th key={`${column.fieldName || column.displayName}_${index}`} className={clsx(column.className, onClick ? "clickable" : null)} onClick={onClick}>{displayName || ""}</th>);
			})}
		</tr>);
	}

	getTableRows() {
		if (this.props.overrideTableRows)
			return this.props.overrideTableRows;

		const selectedRowKeys = this.getSelectedRowKeys();
		const rowObjects = this.props.rowObjects || [];

		if (!rowObjects.length && this.noRowsMessage) {
			return [(<tr key={this.noRowsMessage} className="no-hover"><td colSpan="1000">{this.noRowsMessage}</td></tr>)]
		}

		const rows = rowObjects.map((rowObject, index) => {
			const rowKey = this.props.getRowKey ? this.props.getRowKey(rowObject) : null;
			const isSelected = rowKey ? !!selectedRowKeys[rowKey] : false;
			const rowCells = this.props.getRowCells ? this.props.getRowCells(rowObject, rowKey)
				: this.props.columns.map((column) => {
					return (<td key={`${column.fieldName}_${index}`} className={column.className}
						title={column.customValue ? null : ((((this.props.defaultValueLengthLimit ?? 0) <= 0) || ((rowObject[column.fieldName]?.length) ?? 0) <= this.props.defaultValueLengthLimit) ? null : rowObject[column.fieldName])}
					>{column.customValue ? column.customValue(rowObject, column.fieldName) : truncate(rowObject[column.fieldName], this.props.defaultValueLengthLimit)}</td>);
				})

			let rowTitle = null;
			if (this.props.getRowTitle)
				rowTitle = this.props.getRowTitle(rowObject);

			return (<tr
				key={rowKey}
				title={rowTitle}
				className={(isSelected ? "selected" : "")}
				onMouseDown={this.onMouseDownRowClosure(rowObject, rowKey)}
				onMouseEnter={this.onMouseEnterRowClosure(rowObject, rowKey)}
				onDoubleClick={this.onDoubleClickRowClosure(rowObject, rowKey)}
				onContextMenu={this.onContextMenuClosure(rowObject, rowKey)}>
				{rowCells}
			</tr>);
		});

		return rows;
	}


	onDoubleClickRowClosure(rowObject, rowKey) {
		if (!this.props.onDoubleClick)
			return null;

		return (e) => {
			this.props.onDoubleClick(e, rowObject, rowKey)
		}
	}

	onMouseDownRowClosure(rowObject, rowKey) {
		return (e) => {
			hideMenu();
			if (e.buttons === 1) // Left button flag only
				this.selectRow(e, rowObject, rowKey);
		}
	}

	onMouseEnterRowClosure(rowObject, rowKey) {
		return (e) => {
			if (e.buttons === 1) // Left button flag only
				this.selectRow(e, rowObject, rowKey, true);
		}
	}

	onContextMenuClosure(rowObject, rowKey) {
		if (!this.props.contextMenuId)
			return;

		return (e) => {
			if (this.getSelectedRowKeys()[rowKey] !== true)
				this.selectRow(e, rowObject, rowKey); // The user right-clicked on an unselected row so select it before showing the menu

			this.showRowMenu(e, rowObject, rowKey);
		}
	}

	onClickColumnHeader(fieldName) {
		if (!this.props.setOrderBy)
			return;

		const existingField = this.state.orderByArray.filter((o) => o.fieldName.toUpperCase() === fieldName.toUpperCase());
		const existingFieldDirection = existingField.length > 0 && existingField[0].direction === 1 ? 1 : -1; // default to descending which will be inverted below
		const newFieldDirection = -existingFieldDirection;

		const newOrderBy = `${fieldName} ${newFieldDirection === -1 ? "DESC" : "ASC"}`

		this.props.setOrderBy(newOrderBy);
	}

	getOrderByArray() {
		if (!this.props.orderBy)
			return [];

		return this.props.orderBy.split(',')
			.map((o) => {
				const orderBySplit = o.trim().split(" ");
				const direction = orderBySplit.length > 1 && orderBySplit[1].trim().toUpperCase() === "DESC" ? -1 : 1;

				return { fieldName: orderBySplit[0].trim(), direction };
			});
	}

	selectRow(e, rowObject, rowKey, isDragging) {
		if (this.props.selectMode === "none")
			return;

		const isMultSelect = this.props.selectMode === "multi";
		if (e.ctrlKey && !e.shiftKey && isMultSelect) {
			// Invert the selection state of the target
			const selectedRowKeys = { ...this.getSelectedRowKeys() };
			if (selectedRowKeys[rowKey] === true) {
				delete selectedRowKeys[rowKey];
			}
			else {
				selectedRowKeys[rowKey] = true;
			}

			this.setSelectedRowKeys(selectedRowKeys);
			this.setState({ lastSelectedrowObject: rowObject });
			return;
		}
		else if (((e.shiftKey && !e.ctrlKey) || isDragging) && isMultSelect) {
			// Select a series of items ending at the target
			let lastSelectedRowKey = this.state.lastSelectedRowKey;
			if (lastSelectedRowKey === null && this.props.getRowKey) {
				lastSelectedRowKey = this.props.getRowKey(this.props.rowObjects[0]);
			}

			let selectedRowKeys = this.getRowKeysBetween(lastSelectedRowKey, rowKey);

			this.setSelectedRowKeys(selectedRowKeys);

			return;
		}
		else {
			// No selection modifiers
			this.setSelectedRowKeys({ [rowKey]: true });
			this.setState({ lastSelectedRowKey: rowKey });
		}
	}

	getRowKeysBetween(rowStartKey, rowEndKey) {
		const selectedRowKeys = {};

		if (rowStartKey === rowEndKey) {
			selectedRowKeys[rowStartKey] = true;
		}
		else if (this.props.getRowKey) {
			let isSelecting = false;
			for (let i = 0; i < this.props.rowObjects.length; i++) {
				const rowObject = this.props.rowObjects[i];
				const rowKey = this.props.getRowKey(rowObject);
				if (isSelecting)
					selectedRowKeys[rowKey] = true;

				if (rowKey === rowStartKey || rowKey === rowEndKey) {
					selectedRowKeys[rowKey] = true;
					if (isSelecting)
						break; // End of selection

					isSelecting = true;
				}
			}
		}

		return selectedRowKeys;
	}

	getSelectedRowKeys() {
		return this.props.selectedRowKeys || this.state.selectedRowKeys; // Props override the state
	}

	showRowMenu(e, rowObject, rowKey) {
		e.preventDefault();
		e.stopPropagation();

		let x = e.clientX || (e.touches && e.touches[0].pageX);
		let y = e.clientY || (e.touches && e.touches[0].pageY);

		hideMenu();
		const target = e.target;
		_.defer(() => {
			const showMenuConfig = {
				position: { x, y },
				target: target,
				id: this.props.contextMenuId,
				data: { target: target, rowObject, rowKey, selectedRowKeys: this.getSelectedRowKeys() }
			};

			showMenu(showMenuConfig);
		}); // Defer to ensure any queued setState processes are complete
	}

	setSelectedRowKeys(selectedRowKeys) {
		if (this.props.setSelectedRowKeys)
			this.props.setSelectedRowKeys(selectedRowKeys);

		this.setState({ selectedRowKeys });
	}
}