import React, { Fragment } from "react";
import TextArea from "./TextArea";
import RegExTester from "./RegExTester";
import { ContextMenu, showMenu, hideMenu } from "react-contextmenu";
import "../Content/react-contextmenu.scss";
import _ from "lodash";

export default class RegExTextArea extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			previousShowMenuConfig: null
		};

		const uniqueName = Math.random().toString(36).substring(2);
		this.regExTesterPopupName = `regExTesterPopup_${uniqueName}`;

		this.showRegExTester = this.showRegExTester.bind(this);
	}

	render() {
		let { name, value, testValuesName, testValues, previousTestValues, onChange, onFocus, onResize, onMouseDown, showDirty, ...rest } = this.props;

		return (
			<Fragment>
				<TextArea
					name={name}
					value={value}
					onChange={(e) => { this.showRegExTester(e); if (onChange) onChange(e); }}
					onFocus={(e) => { this.showRegExTester(e); if (onFocus) onFocus(e); }}
					onMouseDown={(e) => { this.showRegExTester(e); if (onMouseDown) onMouseDown(e); }}
					showDirty={showDirty}
					forceShowDirty={showDirty && testValues !== previousTestValues}
					{...rest}
				/>
				<ContextMenu id={this.regExTesterPopupName} preventKeyNavigation={true}>
					<RegExTester
						name={testValuesName}
						heading="Regular Expression Tests"
						regEx={value}
						testValues={testValues}
						previousTestValues={previousTestValues}
						showDirty={showDirty}
						onChange={onChange}
					/>
				</ContextMenu>
			</Fragment>
		);
	}

	showRegExTester(e) {

		const me = this;
		const target = e.target;

		_.defer(() => {
			const elRect = target.getBoundingClientRect();

			let x = elRect.x - 1 + 20; // - 1 to align + X px left margin
			let y = elRect.y + target.clientHeight + 3; // + X px space between element and pop-up

			const showMenuConfig = {
				position: { x, y },
				target: target,
				id: this.regExTesterPopupName
			};

			if (me.state.previousShowMenuConfig
				&& (showMenuConfig.position.x !== me.state.previousShowMenuConfig.position.x
					|| showMenuConfig.position.y !== me.state.previousShowMenuConfig.position.y)) {
				hideMenu(showMenuConfig); // Hide first if the position changed
			}

			me.setState({ previousShowMenuConfig: showMenuConfig });

			showMenu(showMenuConfig);
		}); // Defer to ensure any queued setState processes are complete
	}
}