import React from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import "../../Content/react-contextmenu.scss";
import clsx from "clsx";

export default class UserGroupEntitiesList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.entityRolesTableColumns = [
			{ fieldName: "Name", displayName: "Entity Name" },
			{
				fieldName: "Tags", displayName: "Tags", customValue: (rowObject, fieldName) => {
					const roleNames = rowObject[fieldName] ?? [];
					return roleNames.join(", ");
				},
			},
			{
				fieldName: "UsableRoleNames", displayName: "Usable Roles", customValue: (rowObject, fieldName) => {
					const roleNames = rowObject[fieldName] ?? [];
					return roleNames.join(", ");
				},
			}
		];
	}

	render() {
		return (
			<div>
				<div className={clsx("list-table-container", "entity-profile-user-list", this.props.userGroupsClassName)} >
					<fieldset>
						<legend>Entities</legend>
						<div className="entry-table-inner-container">
							<SelecTable className="profile-table"
								columns={this.entityRolesTableColumns}
								rowObjects={this.props.entityUserGroupRoles}
								getRowKey={(rowData) => rowData.DomainUserGroupName}
								selectMode="single"
							/>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}