import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import OptionsList from "./OptionsList";
import EntryRow from "../../Shared/EntryRow";
import Input from "../../Shared/Input";
import Select from "../../Shared/Select";
import TextArea from "../../Shared/TextArea";
import Checkbox from "../../Shared/Checkbox";
import getFieldNameFromDisplayName from "../../Helpers/getFieldNameFromDisplayName";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class SettingDefinitionEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editingSettingDefinition: this.props.editingSettingDefinition
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.setFieldNameFromDisplayName = this.setFieldNameFromDisplayName.bind(this);
		this.setOptions = this.setOptions.bind(this);
		this.save = this.save.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingSettingDefinition: this.props.editingSettingDefinition,
				editingSettingDefinitionFieldName: this.props.editingSettingDefinition.FieldName
			});
		}
	}

	render() {
		const editingSettingDefinition = this.state.editingSettingDefinition || {};
		const previousEditingSettingDefinition = this.props.editingSettingDefinition || {};
		const fieldName = previousEditingSettingDefinition.FieldName || "[New]";
		const isExisting = this.props.isExisting;

		const dataTypeOptions = [{ value: "String", label: "String" }, { value: "Integer", label: "Integer" }, { value: "Decimal", label: "Decimal" }];
		const uiWidgetOptions = [{ value: "Textbox", label: "Textbox" }, { value: "TextArea", label: "TextArea" }, { value: "DropDown", label: "DropDown" }, { value: "Radio", label: "Radio" }, { value: "Checkbox", label: "Checkbox" }];

		return (
			<BetterDialog title={`Setting Definition: ${fieldName}`} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Details</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="* Display Name" title="Display Name">
										<Input type="text" maxLength={50}
											name="DisplayName"
											value={editingSettingDefinition.DisplayName}
											previousValue={previousEditingSettingDefinition.DisplayName}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen}
										/>
									</EntryRow>
									<EntryRow label="* Field Name" title="Field Name - TitleCase with no spaces">
										<Input type="text" maxLength={50}
											name="FieldName"
											pattern="^[A-Za-z_][A-Za-z0-9_]*$"
											value={editingSettingDefinition.FieldName}
											previousValue={previousEditingSettingDefinition.FieldName}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											onFocus={this.setFieldNameFromDisplayName}
										/>
									</EntryRow>
									<EntryRow label="Tooltip" title="Tooltip">
										<TextArea
											name="Tooltip"
											value={editingSettingDefinition.Tooltip}
											previousValue={previousEditingSettingDefinition.Tooltip}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Watermark Text" title="Watermark text to display when textbox or textarea is empty.">
										<Input type="text"
											name="Watermark"
											value={editingSettingDefinition.Watermark}
											previousValue={previousEditingSettingDefinition.Watermark}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Default Value" title="Default value for the entity/user.">
										<TextArea
											name="DefaultValue"
											value={editingSettingDefinition.DefaultValue}
											previousValue={previousEditingSettingDefinition.DefaultValue}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Assign Default Value" title={"Assign the above default value to any new setting profile.\nDisable to use the default from the setting definition to allow easier global changes."}>
										<Checkbox
											name="AssignDefaultValue"
											value={editingSettingDefinition.AssignDefaultValue !== false}
											previousValue={previousEditingSettingDefinition.AssignDefaultValue !== false}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="* Data Type" title="Plugin setting data types used to perform basic validation during GUI input (for Textbox only).">
										<Select
											name="DataType"
											value={editingSettingDefinition.DataType || ""}
											previousValue={previousEditingSettingDefinition.DataType || ""}
											showDirty={isExisting}
											options={dataTypeOptions}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="* UI Widget" title="Indicates the UI widget to use in the web GUI.">
										<Select
											name="UiWidget"
											value={editingSettingDefinition.UiWidget || ""}
											previousValue={previousEditingSettingDefinition.UiWidget || ""}
											showDirty={isExisting}
											options={uiWidgetOptions}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				{editingSettingDefinition.UiWidget === "DropDown" || editingSettingDefinition.UiWidget === "Radio" ? (
					<OptionsList
						name="Options"
						options={editingSettingDefinition.Options}
						title={editingSettingDefinition.UiWidget + " Options"}
						setOptions={this.setOptions}
					/>
				) : <Fragment />
				}
				<BetterDialogButtons>
					<button onClick={this.save}>OK</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingSettingDefinition = {
			...this.state.editingSettingDefinition,
			[name]: value
		};

		this.setState({ editingSettingDefinition });
	}

	setOptions(options) {
		const editingSettingDefinition = {
			...this.state.editingSettingDefinition,
			Options: options
		};

		this.setState({ editingSettingDefinition });
	}

	save() {
		const editingSettingDefinition = { DataType: "String", UiWidget: "Textbox", ...this.state.editingSettingDefinition };

		if (this.props.setSettingDefinition(editingSettingDefinition, this.state.editingSettingDefinitionFieldName))
			this.props.closeModal();
	}

	setFieldNameFromDisplayName() {
		const cleanFieldName = getFieldNameFromDisplayName(this.state.editingSettingDefinition.DisplayName, this.state.editingSettingDefinition.FieldName);

		if (cleanFieldName === this.state.editingSettingDefinition.FieldName)
			return;

		const editingSettingDefinition = {
			...this.state.editingSettingDefinition,
			FieldName: cleanFieldName
		};

		this.setState({ editingSettingDefinition });
	}
}
