import React from "react";
import ViasatLogoSpinner from "./ViasatLogoSpinner";
import "./LoadingWrapper.scss";

export default function LoadingWrapper(props) {
	return (
		<React.Fragment>
			{props.isLoading
				?
				props.loadingElement ?? (
					<div className="loading-wrapper" style={props.style}>
						<ViasatLogoSpinner style={{ margin: "5px" }} />
						<h4>{props.loadingMessage ?? "Loading..."}</h4>
					</div>
				)
				:
				<React.Fragment>
					{props.children}
				</React.Fragment>
			}
		</React.Fragment>
	);
}
