import React from "react";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import "./DatePicker.scss";

export default class DatePicker extends React.Component {
	render() {
		let { errorMessage, name, value, previousValue, showDirty, className, onChange, ...rest } = this.props;

		if (showDirty && previousValue !== value && !this.props.disabled)
			className = clsx(className, "is-dirty");

		return (
			<ReactDatePicker
				selected={value}
				className={className}
				onChange={(date) => this.props.onChange({ target: { name: name, value: date } })}
				placeholderText="mm/dd/yyyy"
				todayButton={"Today"}
				isClearable={true}
				showMonthDropdown
				showYearDropdown
				dropdownMode="select"
				{...rest} />
		);
	}
}