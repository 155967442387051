import React from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import Input from "../../Shared/Input";
import EntryRow from "../../Shared/EntryRow";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class FileMetadataEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedMetadataRowKeyList: [],
			editingFile: this.props.editingFile,
		};

		this.save = this.save.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingFile
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingFile: this.props.editingFile,
				editingFileTypeName: this.props.editingFile.Name
			});
		}
	}

	render() {
		const editingFile = this.state.editingFile || { Metadata: {} };
		const previousEditingFile = this.props.editingFile || { Metadata: {} };

		const fileTypeDetails = this.props.fileTypeDetailsMap[editingFile.FileTypeName] || {};
		const metadataFields = fileTypeDetails.MetadataFields || [];
		Object.getOwnPropertyNames(editingFile.Metadata).forEach((metadataFieldName) => {
			if (metadataFields.filter((metadataField) => metadataField.FieldName === metadataFieldName).length > 0)
				return;

			// Add metadata filed that is not included in this file type
			metadataFields.push({ FieldName: metadataFieldName })
		});

		return (
			<BetterDialog title={`File: ${editingFile.Filename}`} open={this.props.isOpen}>
				<div className="entry-table-container" onKeyDown={this.onKeyDown}>
					<fieldset>
						<legend>Metadata</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									{metadataFields.map((metadataField, index) => {
										const metadataValue = editingFile.Metadata[metadataField.FieldName];
										const previousMetadataValue = previousEditingFile.Metadata[metadataField.FieldName];

										return (
											<EntryRow label={`${metadataField.DisplayName || ""} (${metadataField.FieldName})`} title={metadataField.Tooltip} key={metadataField.FieldName}>
												<Input type="text"
													name={metadataField.FieldName}
													value={metadataValue || ""}
													previousValue={previousMetadataValue || ""}
													showDirty={true}
													onChange={this.handleDataChange}
													autoFocus={this.props.isOpen && index === 0}
												/>
											</EntryRow>
										);
									})}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					<button onClick={this.save}>OK</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingFileMetadata = {
			...this.state.editingFile.Metadata,
			[name]: value
		};

		const editingFile = {
			...this.state.editingFile,
			Metadata: editingFileMetadata
		};

		this.setState({ editingFile });
	}

	onKeyDown(e) {
		if (e && e.key === "Enter") {
			this.save();
			e.preventDefault();
			return false;
		}

		if (e && e.key === "Escape") {
			this.props.closeModal();
			e.preventDefault();
			return false;
		}
	}

	save() {
		if (!window.confirm(`Are you sure you want to save the metadata changes for '${this.state.editingFile.Filename}'?`))
			return;

		if (this.props.setFileMetadata(this.state.editingFile))
			this.props.closeModal();
	}
}