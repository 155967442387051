import getChecksum from "../Helpers/checksum";

export function handleApiError(error) {
	let errorMessage = `An error occurred while attempting to communicate with the web API.\n\n`
	if (error) {
		console.error(error);
		if (error.response) {
			if (error.response.status === 403) {
				alert("You are not authorized to perform the requested action.");
				return;
			}
			else if (error.response.status === 401) {
				if (error.response.data && error.response.data.ErrorMessage) {
					let needToLogin = false;
					if (error.response.data.ErrorMessage.includes("password has changed")) {
						alert("Your Active Directory password has changed and you must login again.");
						needToLogin = true;
					}
					else if (error.response.data.ErrorMessage.includes("password has expired")) {
						alert("Your Active Directory password has expired.\r\nYou must change your password then login again.");
						needToLogin = true;
					}

					if (needToLogin) {
						const returnUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`
						window.location.href = `/login?returnUrl=${encodeURI(returnUrl)}`;
						return;
					}
				}
			}
			else if (error.response.status === 409) {
				if (error.response.data && error.response.data.ErrorMessage) {
					alert(`${error.response.data.ErrorMessage}`);
					return;
				}
			}
			else if (error.response.status === 503) {
				let outageMessage = error.response.data;
				if (!outageMessage)
					outageMessage = "Sorry, the system is unavailable at this time due to scheduled maintenance."

				const checksum = getChecksum(outageMessage);

				window.location.href = `/outage?c=${checksum}&m=${encodeURI(btoa(outageMessage))}`;
				return;
			}
			else if (error.response.status === 504) {
				errorMessage += `The system timed out while processing your request.\n\n`;
			}
		}

		if (error.config) { // look like an Axios error object
			if (error.response && error.response.data && error.response.data.ErrorMessage) {
				// This object looks like our custom error object for handled server-side exceptions
				errorMessage += `Message: ${error.message} - ${error.response.data.ErrorMessage}${error.response.data.ErrorDetails ? ("\n\n" + JSON.stringify(error.response.data.ErrorDetails)) : ""}`
			}
			else {
				let response = error.response;
				if (response && typeof response === 'object') {
					response = JSON.stringify(response);
				}
				errorMessage += `Message: ${error.message}\n\nResponse: ${response}\n\nDetails: ${error.stack || ""}`;
			}

			errorMessage += `\n\nURL: ${error.config.url}`;
		}
		else {
			errorMessage += error;
		}
	}
	else {
		errorMessage += "No error information available."
	}

	alert(errorMessage);
}
