import { createAxiosApi } from "../Helpers/axiosApiHelper";
import queryString from "query-string";
import axios from "axios";
import getInternetExplorerVersion from "../Helpers/getInternetExplorerVersion"
import _ from "lodash";

export function useApiService(apiBaseUrl) {
	return createApiService(apiBaseUrl);
}

function createApiService(apiBaseUrl) {
	const api = createAxiosApi(apiBaseUrl);
	const multipartFilePartSize = 10 * 1024 * 1024; // Minimum S3 multipart upload size is 5MB (incorrectly documented as 5MiB): https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html

	// --- Web User Session ---
	function createWebUserSession(userCredentials, handleSuccess, handleError) {
		return api.post(`/WebUserSession`, userCredentials)
			.then(handleSuccess).catch(handleError);
	}

	function getWebUserSession(handleSuccess, handleError) {
		return api.get(`/WebUserSession`)
			.then(handleSuccess).catch(handleError);
	}

	function deleteWebUserSession(handleSuccess, handleError) {
		return api.delete(`/WebUserSession`)
			.then(handleSuccess).catch(handleError);
	}

	// --- Users ---
	function getUserProfile(domainName, username, handleSuccess, handleError) {
		return api.get(`/Domains/${domainName}/Users/${username}/profile`)
			.then(handleSuccess).catch(handleError);
	}

	function getSelfAuditProfile(domainName, username, handleSuccess, handleError) {
		return api.get(`/Domains/${domainName}/Users/${username}/selfAuditProfile`)
			.then(handleSuccess).catch(handleError);
	}

	function setUserProfile(userProfile, handleSuccess, handleError) {
		const url = `/Domains/${userProfile.DomainName}/Users/${userProfile.Username}/profile`;

		// Create a copy of the object then remove objects that do not need to be sent to the server
		userProfile = { ...userProfile };

		const userProfileRequest = { UserProfile: userProfile };
		let apiAction = null;
		if (userProfile.IsExistingAppUser) {
			// Update
			apiAction = api.put(url, userProfileRequest);
		}
		else {
			// Add new
			apiAction = api.post(url, userProfileRequest);
		}

		return apiAction.then(handleSuccess).catch(handleError);
	}

	function setSelfAuditUserProfile(userProfile, handleSuccess, handleError) {
		const url = `/Domains/${userProfile.DomainName}/Users/${userProfile.Username}/selfAuditProfile`;

		// Create a copy of the object then remove objects that do not need to be sent to the server
		userProfile = { ...userProfile };

		const userProfileRequest = { UserProfile: userProfile };

		return api.put(url, userProfileRequest).then(handleSuccess).catch(handleError);
	}

	function createAuthCert(domainName, username, handleSuccess, handleError) {
		return api.post(`/Domains/${domainName}/Users/${username}/createAuthCert`, {})
			.then(handleSuccess).catch(handleError);
	}

	// --- Entities ---
	function getEntities(filter, handleSuccess, handleError) {
		return api.get(`/Entities?${queryString.stringify(filter)}`)
			.then(handleSuccess).catch(handleError);
	}

	function getEntityDetails(entityGuid, handleSuccess, handleError) {
		if (entityGuid === "*") {
			return api.get(`/FilesSearch/CompositeEntity`)
				.then(handleSuccess).catch(handleError);
		}
		else {
			return api.get(`/Entities/${entityGuid}`)
				.then(handleSuccess).catch(handleError);
		}
	}

	function getEntityProfile(entityGuid, handleSuccess, handleError) {
		return api.get(`/Entities/${entityGuid}/profile`)
			.then(handleSuccess).catch(handleError);
	}

	function setEntityProfile(entityProfile, handleSuccess, handleError) {
		const url = `/Entities/${entityProfile.Guid || "new"}/profile`;

		// Create a copy of the object then remove objects that do not need to be sent to the server
		entityProfile = { ...entityProfile };
		delete entityProfile.AdminUserEntityProfiles;
		if (entityProfile.PluginProfiles) {
			entityProfile.PluginProfiles = entityProfile.PluginProfiles.map((pluginProfile) => {
				pluginProfile = { ...pluginProfile };
				pluginProfile.PluginDefinition = { PluginName: pluginProfile.PluginDefinition.PluginName };
				return pluginProfile;
			});
		}

		const entityProfileRequest = { EntityProfile: entityProfile };
		let apiAction = null;
		if (entityProfile.Guid) {
			// Update
			apiAction = api.put(url, entityProfileRequest);
		}
		else {
			// Add new
			entityProfileRequest.EntityProfile = { ...entityProfileRequest.EntityProfile };
			delete entityProfileRequest.EntityProfile.Guid;
			apiAction = api.post(url, entityProfileRequest);
		}

		return apiAction.then(handleSuccess).catch(handleError);
	}

	function deleteEntityProfile(entityGuid, handleSuccess, handleError) {
		return api.delete(`/Entities/${entityGuid}`)
			.then(handleSuccess).catch(handleError);
	}

	function getOwnUserPlugins(entityGuid, handleSuccess, handleError) {
		return api.get(`/Entities/${entityGuid}/Users/Self/Plugins`)
			.then(handleSuccess).catch(handleError);
	}

	function setOwnUserPlugins(entityGuid, userPlugins, handleSuccess, handleError) {
		const url = `/Entities/${entityGuid}/Users/Self/Plugins`;

		const request = { UserPlugins: userPlugins };
		api.put(url, request).then(handleSuccess).catch(handleError);
	}

	// --- Files ---
	function getDirectoryListing(entityGuid, path, searchCriteria, handleSuccess, handleError) {
		const cleanSearchCriteria = {
			...searchCriteria,
			metadataFields: undefined,
			view: undefined,

			// Convert dates to ISO (UTC) for the web service
			uploadDateStart: getUtcDate(searchCriteria.uploadDateStart),
			uploadDateEnd: getUtcDate(searchCriteria.uploadDateEnd),
			downloadDateStart: getUtcDate(searchCriteria.downloadDateStart),
			downloadDateEnd: getUtcDate(searchCriteria.downloadDateEnd)
		};

		for (let [key, value] of Object.entries(searchCriteria.metadataFields)) {
			if (value === undefined || value === null || value === "")
				continue;
			cleanSearchCriteria[`meta.${key}`] = value;
		}

		let cleanPath = path.replace(/^\/|\/$/g, ''); // remove leading and trailing slashes
		if (cleanPath.length >= 1)
			cleanPath += "/";

		let baseApiPath = entityGuid === "*" ? "/FilesSearch" : `/Entities/${entityGuid}`;

		const cancelablePromise = getCancelablePromise(api.get(`${baseApiPath}/Files/${cleanPath}?${queryString.stringify(cleanSearchCriteria)}`));
		cancelablePromise.promise
			.then(handleSuccess).catch((e) => {
				if (!e.isCanceled)
					handleError(e);
			});

		return cancelablePromise;
	}

	function getCancelablePromise(promise) {
		let hasCanceled_ = false;

		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then((val) =>
				hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
			);
			promise.catch((error) =>
				hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
			);
		});

		return {
			promise: wrappedPromise,
			cancel() {
				hasCanceled_ = true;
			}
		};
	}

	function setFileMetadata(entityGuid, fileGuid, metadata, handleSuccess, handleError) {
		const request = {
			Metadata: metadata
		};

		return api.put(`/Entities/${entityGuid}/Files/${fileGuid}/Metadata`, request)
			.then(handleSuccess).catch(handleError);
	}

	function uploadFileMetadata(entityGuid, file, fileTypeName, isMultiPart, metadata) {
		const filesUploadInitRequest = {
			Filename: file.name,
			FileTypeName: fileTypeName,
			Metadata: metadata,
			IsMultiPart: isMultiPart
		};

		return api.post(`/Entities/${entityGuid}/Files`, filesUploadInitRequest);
	}

	async function uploadFile(entityGuid, fileGuid, uploadUrl, file, handleProgressPercent) {
		const config = {
			headers: { "Content-Type": "application/octet-stream" },
			onUploadProgress: (progressEvent) => getProgressPercent(progressEvent, handleProgressPercent)
		};

		const s3Api = axios.create();

		await s3Api.put(uploadUrl, file, config);

		getProgressPercent({ loaded: 100, total: 100 }, handleProgressPercent); // Make sure we are at 100% of the upload portion

		const commitResult = await uploadFileCommit(entityGuid, fileGuid);
		if (handleProgressPercent)
			handleProgressPercent(100);

		return commitResult;
	}

	async function uploadFileMultiPart(entityGuid, fileGuid, multipartUploadId, file, handleProgressPercent) {
		const s3Api = axios.create();
		const partETags = {};
		const totalParts = Math.ceil(file.size / multipartFilePartSize);
		const maxAttempts = 3;

		for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
			const startByteIndex = (partNumber - 1) * multipartFilePartSize;
			let partSize = file.size - startByteIndex;
			if (partSize > multipartFilePartSize)
				partSize = multipartFilePartSize;

			const filePartBlob = file.slice(startByteIndex, startByteIndex + partSize);
			
			const request = {
				UploadId: multipartUploadId,
				PartNumber: partNumber
			};

			const config = {
				headers: { "Content-Type": "application/octet-stream" },
				onUploadProgress: (progressEvent) => getProgressPercent(progressEvent, handleProgressPercent, (partNumber - 1) * multipartFilePartSize, file.size)
			};

			for (let attemptNumber = 1; ; attemptNumber++) {
				try {
					let uploadUrl = null;

					// Get file part upload URL from OSQRE
					await api.post(`/Entities/${entityGuid}/Files/${fileGuid}/GetPartUrl`, request)
						.then((response) => {
							if (response.status !== 200)
								throw new Error("Bad response from GetPartUrl.");

							uploadUrl = response.data.UploadUrl;
						})
						.catch((e) => {
							throw e;
						});

					// Upload the file part
					await s3Api.put(uploadUrl, filePartBlob, config)
						.then((response) => {
							if (response.status !== 200)
								throw new Error("Bad response from file part PUT.")

							// Get the ETag
							const etag = response.headers.etag;
							if (!etag) {
								alert('Could not read ETag from file part PUT response.\nS3 CORS is not setup correctly.\nThe following S3 CORS rule must be added to the file storage bucket:\n  "ExposeHeaders": ["ETag"]');
								// S3 CORS rule must be set to allow the browser to read the ETag: "ExposeHeaders": ["ETag"]
								//   - This will add response header: Access-Control-Expose-Headers: ETag
								throw new Error("Could not read ETag from file part PUT response.")
							}

							// Save the ETag
							partETags[partNumber] = etag;
						})
						.catch((e) => {
							throw e;
						});

					break;
				}
				catch (e) {
					console.error(`File part upload failed. Attempt ${attemptNumber}/${maxAttempts}. - Error: ${JSON.stringify(e)}`);
					if (attemptNumber >= maxAttempts)
						throw new Error(`Failed to upload file part ${partNumber}/${totalParts} after ${attemptNumber} attempts. - Error: ${JSON.stringify(e)}`, { cause: e });

					const waitSeconds = 5;
					console.error(`Will retry file part ${partNumber}/${totalParts} in ${waitSeconds} seconds...`);
					await new Promise(resolve => setTimeout(resolve, waitSeconds * 1000));
					continue;
				}
			}
		}

		getProgressPercent({ loaded: 100, total: 100 }, handleProgressPercent); // Make sure we are at 100% of the upload portion

		const commitResult = await uploadFileCommit(entityGuid, fileGuid, { UploadId: multipartUploadId, PartETags: partETags });
		if (handleProgressPercent)
			handleProgressPercent(100);

		return commitResult;
	}

	function getProgressPercent(progressEvent, handlePercentProgress, previouslyCompletedBytes, totalSize) {
		const progressPercent = Math.round(99 * ((previouslyCompletedBytes ?? 0) + progressEvent.loaded) / (totalSize ?? progressEvent.total)); // 99% to leave room for the commit
		if (handlePercentProgress)
			handlePercentProgress(progressPercent);

		return progressPercent;
	}

	function uploadFileCommit(entityGuid, fileGuid, filesUploadFileCommitRequest) {
		return api.post(`/Entities/${entityGuid}/Files/${fileGuid}/Commit${filesUploadFileCommitRequest ? "MultiPart" : ""}`, filesUploadFileCommitRequest);
	}

	function getUtcDate(dateTime) {
		if (dateTime === undefined || dateTime === null)
			return undefined;

		return dateTime.toISOString();
	}

	function downloadFile(entityGuid, fileGuid, isView) {
		const fileUrl = getApiDownloadUrl(entityGuid, fileGuid, true, isView);
		return downloadFileUrl(fileUrl);
	}

	function getApiDownloadUrl(entityGuid, fileGuid, isInteractive, isView) {
		const args = {};
		if (isInteractive)
			args.isInteractive = 1
		if (isView)
			args.view = 1
		let querystring = queryString.stringify(args)
		if (querystring)
			querystring = "?" + querystring;

		return `${apiBaseUrl}/Entities/${entityGuid}/Files/${fileGuid}${querystring}`;
	}

	function downloadFileUrl(fileUrl) {
		let downloadWindow = window;
		const ieVersion = getInternetExplorerVersion();
		let downloadDelayMs = 0;
		if (ieVersion) {
			// Super hack for IE single file download
			const downloadWindowParams = `height=${ieVersion <= 11 ? "200" : "325"},width=700,toolbar=0,menubar=0`; // Edge (>= v12) has a larger download prompt
			downloadWindow = window.open("", "OSQRE_Download", downloadWindowParams);
			downloadWindow.focus();
			downloadWindow.document.body.innerHTML = downloadWindowHtml;
			downloadDelayMs = ieVersion <= 11 ? 2000 : 100;
		}

		_.delay(() => { downloadWindow.location.href = fileUrl; }, downloadDelayMs);
	}

	function getDownloadFilesUrl(entityGuid, directoryPaths, fileGuids, handleSuccess, handleError) {
		const filesUrl = `${apiBaseUrl}/Entities/${entityGuid}/Files/**`;
		return api.post(filesUrl, { directoryPaths, fileGuids })
			.then(handleSuccess).catch(handleError);
	}

	function downloadFilesStream(entityGuid, directoryPaths, fileGuids) {
		const filesUrl = `${apiBaseUrl}/Entities/${entityGuid}/Files/**/Stream`;
		postDownload(filesUrl, { directoryPaths, fileGuids });
	}

	const downloadWindowHtml =
		"<title>Download Files</title>"
		+ "<style>* { font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif; }</style>"
		+ "<h2>Starting download...</h2>"
		+ "<h2>You may close this window after the download begins.</h2>"
		+ "<button onClick='window.close();'>Close</button>"

	function postDownload(path, params) {
		let downloadWindow = window;
		const ieVersion = getInternetExplorerVersion();

		if (ieVersion) {
			// Super hack for IE streaming multiple file downloads
			const downloadWindowParams = `height=${ieVersion <= 11 ? "200" : "325"},width=700,toolbar=0,menubar=0`; // Edge (>= v12) has a larger download prompt
			downloadWindow = window.open("", "OSQRE_Download", downloadWindowParams);
			downloadWindow.focus();
			downloadWindow.document.body.innerHTML = downloadWindowHtml;
		}

		const form = downloadWindow.document.createElement('form');

		form.id = "DownloadForm";
		form.method = "post";
		form.action = path;

		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				const hiddenField = downloadWindow.document.createElement('input');
				hiddenField.type = 'hidden';
				hiddenField.name = key;
				hiddenField.value = params[key];

				form.appendChild(hiddenField);
			}
		}

		downloadWindow.document.body.appendChild(form);

		if (ieVersion) {
			// Super hack for IE streaming multiple file downloads, continued...
			const submitScript = downloadWindow.document.createElement("script");
			const submitScriptInline = downloadWindow.document.createTextNode(`document.getElementById('${form.id}').submit();`);
			submitScript.appendChild(submitScriptInline);
			downloadWindow.document.body.appendChild(submitScript); // this adds the script to the pop-up window and executes it immediately.
		}
		else {
			form.submit();
			form.remove();
		}
	}

	function deleteFiles(entityGuid, directoryPaths, fileGuids, handleSuccess, handleError) {
		const filesDeleteRequest = { DirectoryPaths: directoryPaths, FileGuids: fileGuids };
		return api.post(`/Entities/${entityGuid}/Files/*Delete*`, filesDeleteRequest)
			.then(handleSuccess).catch(handleError);
	}

	function getFileDetails(entityGuid, fileGuid, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Entities/${entityGuid}/Files/${fileGuid}/Details`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	// --- Helpers ---
	function ping(warmup, handleSuccess, handleError) {
		return api.post(`/Ping?warmup=${warmup || 0}&key=${Math.random().toString(36).substring(2)}`, { Ping: "Ping" }) // Use post to force a CORS request
			.then(handleSuccess).catch(handleError);
	}

	function getFilenameMetadata(entityGuid, fileTypeName, filename, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/FilenameMetadata/${entityGuid}/${fileTypeName}/${filename}`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function getSystemFileTypes(handleSuccess, handleError) {
		const url = `${apiBaseUrl}/SystemFileTypes`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}
	
	function getDomains(permissionTemplate, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Domains?permissionTemplate=${encodeURI(permissionTemplate)}`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function getDomainUsers(domainName, name, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Domains/${domainName}/Users?name=${name}&`; // The trailing "&" prevents axios from trimming spaces from the end of our data string
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function getUserGroups(domainName, groupNamePrefix, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Domains/${domainName}/UserGroups?name=${groupNamePrefix}&`; // The trailing "&" prevents axios from trimming spaces from the end of our data string
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function getUserGroup(domainName, userGroupId, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Domains/${domainName}/UserGroups/${userGroupId}`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function setUserGroup(userGroup, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Domains/${userGroup.DomainName}/UserGroups`;
		userGroup = { ...userGroup };
		delete userGroup["DomainUserGroupName"];

		const request = { UserGroup: userGroup };

		if (userGroup.UserGroupId) {
			return api.put(url + `/${userGroup.UserGroupId}`, request)
				.then(handleSuccess).catch(handleError);
		}
		else {
			return api.post(url, request)
				.then(handleSuccess).catch(handleError);
		}
	}

	function getPluginDefinitions(handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Plugins`;
		return api.get(url)
			.then(handleSuccess).catch(handleError);
	}

	function setPluginDefinition(pluginDefinition, handleSuccess, handleError) {
		const url = `${apiBaseUrl}/Plugins`;
		const request = { PluginDefinition: pluginDefinition };

		if (pluginDefinition.PluginId) {
			return api.put(url + `/${pluginDefinition.PluginId}`, request)
				.then(handleSuccess).catch(handleError);
		}
		else {
			return api.post(url, request)
				.then(handleSuccess).catch(handleError);
		}
	}

	return {
		// --- Web User Session ---
		createWebUserSession,
		getWebUserSession,
		deleteWebUserSession,

		// --- Users ---
		getUserProfile,
		getSelfAuditProfile,
		setUserProfile,
		setSelfAuditUserProfile,
		createAuthCert,

		// --- Entities ---
		getEntities,
		getEntityDetails,
		getEntityProfile,
		setEntityProfile,
		deleteEntityProfile,

		getOwnUserPlugins,
		setOwnUserPlugins,

		// --- Files ---
		getDirectoryListing,
		setFileMetadata,
		uploadFileMetadata,
		uploadFile,
		uploadFileMultiPart,
		downloadFile,
		getApiDownloadUrl,
		downloadFileUrl,
		getDownloadFilesUrl,
		downloadFilesStream,
		deleteFiles,
		getFileDetails,

		// --- Helpers ---
		ping,
		getFilenameMetadata,
		getSystemFileTypes,
		getDomains,
		getDomainUsers,
		getUserGroups,
		getUserGroup,
		setUserGroup,
		getPluginDefinitions,
		setPluginDefinition,
		multipartFilePartSize
	};
}
