import React from "react";

export default function Footer(props) {
	const today = new Date();
	var decodeHtml = function (html) {
		var txt = document.createElement('textarea');
		txt.innerHTML = html;
		return txt.value;
	};
	// Email address obfuscated to avoid email harvesters. Generate and extract here: https://www.albionresearch.com/misc/obfuscator.php
	// eslint-disable-next-line
	const emailAddress = decodeHtml('o' + 's' + '&' + '#' + '1' + '1' + '3' + ';' + 'r' + '&' + '#' + '1' + '0' + '1' + ';' + '&' + '#' + '6' + '4' + ';' + 'v' + 'i' + 'a' + 's' + 'a' + 't' + '&' + '#' + '4' + '6' + ';' + '&' + '#' + '9' + '9' + ';' + 'o' + '&' + '#' + '1' + '0' + '9' + ';');

	return (
		<div className="fw single-ftr">
			{/* Footer */}
			<footer className="dark-footer learfix">
				{/* Footer Links */}
				<div className="ftr-gradient gradient1"></div>{/* footer top Gradient */}
				<div className="ftr-container ftr-copyright clearfix">
					<div className="ftr-lnk-logo">
						<div className="ftr-links">
							<ul>
								<li></li>
							</ul>
						</div>
						<div className="ftr-logo">
							<a href="https://www.viasat.com" target="_blank" rel="noopener noreferrer">
								<img src={require("../ViasatUx/images/logo_viasat_white.png")} className="footer-logo" alt="Viasat Logo : Click to go back to homepage" />
							</a>
						</div>
					</div>
					<div className="ftr-copy"><span style={{ "whiteSpace": "nowrap" }}>For technical questions please contact <a href={"mailto:" + emailAddress}>{emailAddress}</a>. &nbsp; &nbsp; &nbsp; </span> <span title={"Commit hash: " + props.appCommitHash} style={{ "whiteSpace": "nowrap" }}>{props.appName} v{props.appVersion}-{props.appCommitHash} - Copyright © {today.getFullYear()} Viasat, Inc.</span></div>
				</div>
			</footer>{/* End of Footer */}
		</div>);
}
