import React, { Fragment } from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import MainOptions from "./MainOptions";
import EntitiesList from "./EntitiesList";
import UserGroupsList from "./UserGroupsList";
import PageOverlay from "../../Shared/PageOverlay";
import PassiveMessage from "../../Shared/PassiveMessage";
import _ from "lodash";
import { Prompt } from "react-router-dom";
import EntryRow from "../../Shared/EntryRow"
import Link from "../../Shared/Link";

export default class UserProfile extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.verifyState();

		this.leaveUnsavedMessage = "You have unsaved changes, are you sure you want to leave?";

		this.setUserProfile = this.setUserProfile.bind(this);
		this.setEntityUserRoles = this.setEntityUserRoles.bind(this);
		this.setUserGroupUserProfiles = this.setUserGroupUserProfiles.bind(this);

		this.saveProfile = this.saveProfile.bind(this);
		this.getDomainUsername = this.getMyDomainUsername.bind(this);
		this.handleCloseSaveNotification = this.handleCloseSaveNotification.bind(this);
		this.onBeforeUnload = this.onBeforeUnload.bind(this);
		this.openShowJsonModal = this.openShowJsonModal.bind(this);
		this.setUserProfileFromShowJson = this.setUserProfileFromShowJson.bind(this);
		this.createCertificate = this.createCertificate.bind(this);
		
		this.downloadBinaryFileFromString = this.downloadBinaryFileFromString.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
		window.addEventListener('beforeunload', this.onBeforeUnload);
	}

	componentDidUpdate(prevProps, prevState) {
		this.verifyState(prevProps, prevState);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onBeforeUnload);
	}

	onBeforeUnload(e) {
		if (!this.state.isDirty)
			return;

		if (e)
			e.returnValue = this.leaveUnsavedMessage;

		return this.leaveUnsavedMessage;
	}

	verifyState(prevProps, prevState) {
		if (!prevProps) {
			const state = {
				isInitialized: false,
				isLoading: true,
				showSaveSuccessNotification: false,
				standardRoleNames: [],
				openShowJsonModal: false
			};

			if (prevProps)
				this.setState(state);
			else {
				// Ignore warning about setting the state directly:
				// eslint-disable-next-line
				this.state = state; // Called from the constructor
			}
		}
		else {
			const isDirty = this.state.userProfile !== this.state.previousUserProfile;
			if (this.state.isDirty !== isDirty) {
				this.setState({ isDirty });
			}
		}
	}

	render() {
		const userProfile = this.state.userProfile || {};
		const previousUserProfile = this.state.previousUserProfile || {};
		const isSelfAudit = this.props.isSelfAudit ?? false;

		const action = isSelfAudit ? "Self Audit" : "User Profile";
		const heading = `${userProfile.IsExistingAppUser ? `${userProfile.DomainName}\\${userProfile.Username}` : "[New User]"} ${action}`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		const hasChangeUserDetails = isSelfAudit ? false : this.context.hasPermission("*", `Change User Profiles (${userProfile.DomainName})`);
		const hasChangeUserEntities = isSelfAudit
			? this.context.hasPermission("*", "Self Audit")
			: hasChangeUserDetails && this.context.hasPermission("*", "Change Entity Users");
		const isAnyEditable = (userProfile.Name !== "..." && (this.state.isNew || (hasChangeUserEntities)));

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<PageOverlay showOverlay={this.state.isLoadingPassive} />
				<LoadingWrapper isLoading={this.state.isLoading}>
					{!this.state.userProfile ?
						<h2>Invalid domain\username!</h2>
						: <Fragment>
							<h1>{heading}</h1>
							<MainOptions
								userProfile={userProfile}
								previousUserProfile={previousUserProfile}
								setUserProfile={this.setUserProfile}
								isNew={!userProfile.IsExistingAppUser}
								disabled={(userProfile.IsExistingAppUser && !hasChangeUserDetails) || isSelfAudit}
								config={this.props.config}
							/>
							{userProfile.IsVirtualDomain && userProfile.IsExistingAppUser ?
								<div className="entry-table-container user-profile-cert-options" >
									<fieldset>
										<legend>User Authentication</legend>
										<div className="entry-table-inner-container" >
											<table>
												<tbody>
													<EntryRow label="Authentication Certificate" title="A certificate is required for virtual domain users to login/authenticate.">
														<span>
															<Link onClick={this.createCertificate}>Create and download</Link>
														</span>
													</EntryRow>
												</tbody>
											</table>
										</div>
									</fieldset>
								</div>
								: <Fragment />
							}
							<EntitiesList
								entityUserRoles={userProfile.EntityUserRoles}
								setEntityUserRoles={this.setEntityUserRoles}
								availableRoleNames={this.state.availableRoleNames}
								className={!this.state.isNew && this.state.previousUserProfile.EntityUserRoles !== userProfile.EntityUserRoles ? "is-dirty" : null}
								isSelfAudit={isSelfAudit}
								disableEntities={!this.state.isNew && !hasChangeUserEntities}
								config={this.props.config}
							/>
							<UserGroupsList
								userGroups={userProfile.UserGroups}
								isSelfAudit={isSelfAudit}
							/>
							<div className="button-bar">
								{isAnyEditable ? <button onClick={this.saveProfile} title="Save all changes">Save</button> : <Fragment />}
								{userProfile.Name !== "..." && !isSelfAudit ? <button onClick={this.openShowJsonModal} title="Show JSON.">Show JSON</button> : <Fragment />}
							</div>
						</Fragment>
					}
				</LoadingWrapper>
				<ShowJsonModal
					heading={`${heading} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.userProfileForShowJson}
					setValue={this.setUserProfileFromShowJson}
					showDirty={!this.state.isNew}
					disabled={this.props.disabled || !isAnyEditable}
				/>
				<Prompt
					when={this.state.isDirty}
					message={(location, action) => this.leaveUnsavedMessage}
				/>
				<PassiveMessage
					open={this.state.showSaveSuccessNotification}
					onClose={this.handleCloseSaveNotification}
					variant="success"
					message="Record saved successfully!" />
			</Layout>
		);
	}

	createCertificate() {
		if (!window.confirm("Are you sure you want to create and download a new authentication certificate?"))
			return;

		this.setState({ isLoadingPassive: true });

		this.apiService.createAuthCert(this.props.match.params.domainName, this.props.match.params.username,
			(response) => {
				const certificateText = response.data.CertificateData.CertificateText;
				this.downloadBinaryFileFromString(`UserAuthKey_${this.props.match.params.domainName}_${this.props.match.params.username}.pubkey`, certificateText);
				this.setState({ isLoadingPassive: false });
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			}
		);
	}

	downloadBinaryFileFromString(filename, stringContent) {
		const blob = new Blob([stringContent], { type: "application/octet-stream" });
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename);
		}
		else {
			const el = window.document.createElement('a');
			el.href = window.URL.createObjectURL(blob);
			el.download = filename;
			document.body.appendChild(el);
			el.click();
			document.body.removeChild(el);
		}
	}


	initialize() {
		const successFunc = (response) => {
			this.setState({
				isLoading: false,
				isLoadingPassive: false,
				userProfile: response.data.UserProfile,
				previousUserProfile: response.data.UserProfile
			});
		};
		const failFunc = (error) => {
			handleApiError(error);
			this.setState({ isLoading: false });
		}

		if (this.props.isSelfAudit) {
			const webUserSession = this.context.getWebUserSession();
			this.apiService.getSelfAuditProfile(webUserSession.UserDetails.DomainName, webUserSession.UserDetails.Username,
				successFunc,
				failFunc);
		}
		else {
			this.apiService.getUserProfile(this.props.match.params.domainName, this.props.match.params.username,
				successFunc,
				failFunc);
		}

		this.setState({ isInitialized: true });
	}

	openShowJsonModal() {
		this.setState({
			openShowJsonModal: true,
			userProfileForShowJson: this.getUserProfileForShowJson(this.state.userProfile)
		});
	}

	setUserProfileFromShowJson(userProfile) {
		userProfile = {
			...this.state.userProfile,
			...this.getUserProfileForShowJson(userProfile)
		};

		this.setUserProfile(userProfile);
		this.setState({ openShowJsonModal: false });
	}

	getUserProfileForShowJson(userProfile) {
		userProfile = { ...userProfile };

		// Exclude the following properties from the JSON view since they may not transfer between environments well
		//delete userProfile.UserEntityProfiles;
		//delete userProfile.UserGroupUserProfiles;
		delete userProfile.UpdateDateTime

		return userProfile;
	}

	saveProfile() {
		const userProfile = this.state.userProfile;
		if (!this.props.isSelfAudit) {
			if (!userProfile.DomainName) {
				alert("Domain name is required!");
				return;
			}
			if (!userProfile.Username) {
				alert("Username is required!");
				return;
			}
			if (!userProfile.FirstName) {
				alert("First name is required!");
				return;
			}
			if (!userProfile.LastName) {
				alert("Last name is required!");
				return;
			}
		}
		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });
		const me = this;

		const apiFunction = this.props.isSelfAudit ? this.apiService.setSelfAuditUserProfile : this.apiService.setUserProfile;

		apiFunction(userProfile,
			(response) => {
				// Refresh the web user session context when a user is saved (in case the user edits their own roles)
				this.apiService.getWebUserSession(
					(userSessionResponse) => {
						this.context.setWebUserSession(userSessionResponse.data.WebUserSession);

						// Force a refresh
						this.setState({
							showSaveSuccessNotification: true,
							isInitialized: false
						});
					},
					(error) => {
						handleApiError(error);
						me.setState({ isLoadingPassive: false });
					}
				);
			},
			(error) => {
				handleApiError(error);
				me.setState({ isLoadingPassive: false });
			}
		);
	}

	setRoute(newRoute) {
		this.props.history.push(newRoute);
	}

	setUserProfile(userProfile) {
		this.setState({ userProfile });
	}

	setEntityUserRoles(entityUserRoles) {
		const userProfile = { ...this.state.userProfile, EntityUserRoles: entityUserRoles };
		this.setState({ userProfile });
	}

	setUserGroupUserProfiles(userGroupUserProfiles) {
		const userProfile = { ...this.state.userProfile, UserGroupUserProfiles: userGroupUserProfiles };
		this.setState({ userProfile });
	}

	getMyDomainUsername() {
		const webUserSession = this.context.getWebUserSession();
		if (!webUserSession || !webUserSession.UserDetails)
			return null;

		let domainUsername = webUserSession.UserDetails.DomainUsername;

		return domainUsername;
	}

	handleCloseSaveNotification(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ showSaveSuccessNotification: false });
	}
}
