import React from "react";
import Layout from "../../Layout/Layout";
import queryString from "query-string";
import getChecksum from "../../Helpers/checksum";

export default class Outage extends React.Component {
	render() {
		const queryStrings = queryString.parse(window.location.search);
		let outageMessage64 = queryStrings["m"];
		let outageMessageChecksum = queryStrings["c"];
		let outageMessage = null;
		if (outageMessage64 && outageMessageChecksum) {
			try {
				outageMessage = atob(outageMessage64);
				const checksum = getChecksum(outageMessage);
				if ("" + checksum !== outageMessageChecksum)
					outageMessage = null;
			}
			catch { }
		}

		if (!outageMessage) {
			outageMessage = "Sorry, the system is unavailable at this time due to scheduled maintenance.";
		}

		return (
			<Layout config={this.props.config} subtitle="Outage" requireWebUserSession={false} useApi={false}>
				<h1>Temporarily Unavailable</h1>
				<p>{outageMessage}</p>
				<p><a href="/">Try again...</a></p>
			</Layout>
		);
	}
}
