import React, { Fragment } from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import UserGroupEditModal from "./UserGroupEditModal";
import PageOverlay from "../../Shared/PageOverlay";
import PassiveMessage from "../../Shared/PassiveMessage";
import _ from "lodash";

export default class UserGroups extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.userGroupRowContextMenuName = `UserGroupRowContextMenu_${uniqueName}`;

		this.state = {
			isInitialized: false,
			isLoading: true,
			isLoadingPassive: false,
			selectedRowKeyList: [],
			showUserGroupEditModal: false,
			userGroups: [],
			editingUserGroup: null,
			showSaveSuccessNotification: false
		};

		this.tableColumns = [
			{ fieldName: "DomainName", displayName: "Domain" },
			{ fieldName: "UserGroupName", displayName: "User Group Name" },
			{ fieldName: "Description", displayName: "Description" },
			{ fieldName: "LdapGroupName", displayName: "Sync LDAP Group" },
			{ fieldName: "UserCount", displayName: "User Count" },
			{ fieldName: "OwnerDomainUsername", displayName: "Owner" }
		];

		this.closeUserGroupEditModal = this.closeUserGroupEditModal.bind(this);
		this.saveUserGroup = this.saveUserGroup.bind(this);
		this.handleCloseSaveNotification = this.handleCloseSaveNotification.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	render() {
		const heading = "User Groups";
		const userGroups = this.state.userGroups || [];

		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		const canAddUserGroups = this.context.hasPermission("*", "Add User Groups");

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<PageOverlay showOverlay={this.state.isLoadingPassive} />
				<h1>{heading}</h1>
				<div className="list-table-container">
					<fieldset>
						<legend>User Groups</legend>
						<LoadingWrapper isLoading={this.state.isLoading}>
							<div className="entry-table-inner-container" >
								<SelecTable className="profile-table"
									columns={this.tableColumns}
									rowObjects={userGroups}
									getRowKey={(rowData) => rowData.DomainUserGroupName}
									selectMode="single"
									contextMenuId={this.userGroupRowContextMenuName}
									setSelectedRowKeys={this.setSelectedRowKeys}
									onDoubleClick={(...args) => this.edit(args[2])}
								/>
								{canAddUserGroups ?
									<div className="button-bar">
										<button onClick={this.add}>Add New...</button>
									</div>
									: <Fragment />
								}
								<ContextMenu id={this.userGroupRowContextMenuName}>
									<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}>View/Edit...</MenuItem>
									<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}>Duplicate...</MenuItem>
								</ContextMenu>
								<UserGroupEditModal
									isOpen={this.state.showUserGroupEditModal}
									closeModal={this.closeUserGroupEditModal}
									editingUserGroup={this.state.editingUserGroup}
									saveUserGroup={this.saveUserGroup}
									config={this.props.config}
								/>
							</div>
						</LoadingWrapper>
					</fieldset>
				</div>
				<PassiveMessage
					open={this.state.showSaveSuccessNotification}
					onClose={this.handleCloseSaveNotification}
					variant="success"
					message="Record saved successfully!" />
			</Layout >
		);
	}

	initialize() {
		this.apiService.getUserGroups("*", "*",
			(response) => {
				this.setState({
					isLoading: false,
					isLoadingPassive: false,
					userGroups: response.data.UserGroups
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false, isLoadingPassive: false, });
			}
		);

		this.setState({ isInitialized: true });
	}


	saveUserGroup(userGroup, successCallback) {
		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });

		this.apiService.setUserGroup(userGroup,
			(response) => {
				if (successCallback)
					successCallback();
				// Force a refresh
				this.setState({
					showSaveSuccessNotification: true,
					isInitialized: false
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			}
		);
	}

	setSelectedRowKeys(selectedRowKeys) {
		this.setState({ selectedRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	add() {
		this.setState({ editingUserGroup: { OwnerDomainUsername: this.context.getWebUserSession().UserDetails.DomainUsername }, showUserGroupEditModal: true });
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const userGroups = this.state.userGroups || [];
		const matchingUserGroups = userGroups.filter((userGroups) => userGroups.DomainUserGroupName === selectedRowKey);

		let userGroupSummary = matchingUserGroups.length > 0 ? matchingUserGroups[0] : null;

		if (userGroupSummary == null) {
			alert("Cannot edit selected row.");
			return;
		}

		this.setState({ isLoadingPassive: true, showSaveSuccessNotification: false });

		this.apiService.getUserGroup(userGroupSummary.DomainName, userGroupSummary.UserGroupId,
			(response) => {
				let userGroup = response.data.UserGroup;
				if (createCopy) {
					userGroup = { ...userGroup };
					delete userGroup.UserGroupId
					delete userGroup.DomainUserGroupName
					delete userGroup.UserGroupName
				}

				this.setState({
					editingUserGroup: userGroup,
					showUserGroupEditModal: true,
					isLoadingPassive: false
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			}
		);
	}

	closeUserGroupEditModal() {
		this.setState({ showUserGroupEditModal: false });
	}

	handleCloseSaveNotification(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ showSaveSuccessNotification: false });
	}
}
