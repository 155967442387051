import React, { Fragment } from "react";
import { extractDomElement } from "../Helpers/SharedFunctions";
import EntryRow from "../Shared/EntryRow"
import ComboBox from "../Shared/ComboBox";
import Checkbox from "../Shared/Checkbox";
import Input from "../Shared/Input"
import _ from "lodash";
import "./EntityFilter.scss";

export default class EntityFilter extends React.Component {
	constructor(props) {
		super(props);

		this.NO_TAGS = "[no tags]";

		this.state = {
			entityFilters: { includeTagsMatchAll: false },
			filteredEntities: null,
			tags: []
		};

		this.updateTags = this.updateTags.bind(this);
		this.setFilteredEntities = this.setFilteredEntities.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);

		_.defer(() => { this.updateTags(); });
		_.defer(() => { this.setFilteredEntities(); });
	}

	componentDidUpdate(prevProps, prevState) {
		let isChanged = false;
		if (isChanged || this.props.entities !== prevProps.entities) {
			this.updateTags();
			isChanged = true;
		}

		if (isChanged || this.state.entityFilters !== prevState.entityFilters) {
			_.defer(() => { this.setFilteredEntities(); });
			isChanged = true;
		}
	}

	updateTags() {
		const tagsSet = new Set();
		this.props.entities.forEach((entity, key) => (entity.Tags ?? []).forEach((tag, key) => tagsSet.add(tag)));
		this.setState({
			tags: [this.NO_TAGS, ...Array.from(tagsSet).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)]
		});
	}

	setFilteredEntities() {
		if (!this.props.setFilteredEntities)
			return;

		const nameSearches = (this.state.entityFilters.nameSearch ?? "").toLowerCase().split(" ");
		const includeTags = this.state.entityFilters.includeTags ?? [];
		const excludeTags = this.state.entityFilters.excludeTags ?? [];

		const filteredEntities = (this.props.entities ?? [])
			.filter((entity) =>
				nameSearches.every(nameSearch => entity.Name.toLowerCase().includes(nameSearch))
				&& (includeTags.length === 0
					|| (this.state.entityFilters.includeTagsMatchAll ?
						includeTags.every(includeTag => (includeTag !== this.NO_TAGS) ? (entity.Tags ?? []).includes(includeTag) : (entity.Tags ?? []).length === 0)
						: (((entity.Tags ?? []).length === 0 && includeTags.includes(this.NO_TAGS)) || (entity.Tags ?? []).some(tag => includeTags.includes(tag)))))
				&& (excludeTags.length === 0
					|| (excludeTags.every(excludeTag => !((excludeTag !== this.NO_TAGS) ? (entity.Tags ?? []).includes(excludeTag) : (entity.Tags ?? []).length === 0))))
			);
		this.props.setFilteredEntities(filteredEntities);
	}

	andOr(isAnd, array, func) {
		return isAnd ? array.every(func) : array.some(func);
	}

	render() {
		return (
			<div className="entry-table-container entity-filter-fields">
				<fieldset>
					<legend>Entity Filter Criteria</legend>
					<div className="entry-table-inner-container" >
						<table>
							<tbody>
								<EntryRow label="Name" title="Search for an entity by partial name match.">
									<Input type="text" maxLength={50}
										name="nameSearch"
										value={this.state.entityFilters.nameSearch || ""}
										onChange={this.handleDataChange}
										autoFocus={true}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								{this.state.tags.length > 1 && this.props.showTags !== false ?
									<Fragment>
										<EntryRow label="Include tags" title="Tags to include in the list." innerDivStyle={{ overflow: "visible" }}>
											<ComboBox
												name="includeTags"
												value={(this.state.entityFilters.includeTags || []).map((tag) => { return { label: tag, value: tag } })}
												options={(this.state.tags || []).map((tag) => { return { label: tag, value: tag } })}
												isClearable={true}
												isMulti={true}
												isSearchable={true}
												closeMenuOnSelect={true}
												backspaceRemovesValue={true}
												onChange={this.handleDataChange}
												disabled={this.props.disabled}
												isValidNewOption={false}
												className="combo-box-inline"
											/>
											&nbsp; <Checkbox
												name="includeTagsMatchAll"
												value={this.state.entityFilters.includeTagsMatchAll}
												title="When 'match all' is enabled, only entities that have all 'include tags' assigned will be shown."
												onChange={this.handleDataChange}
												label={`Match all`}
												disabled={this.props.disabled}
											/>
										</EntryRow>
										<EntryRow label="Exclude tags" title="Tags to exclude from the list." innerDivStyle={{ overflow: "visible" }}>
											<ComboBox
												name="excludeTags"
												value={(this.state.entityFilters.excludeTags || []).map((tag) => { return { label: tag, value: tag } })}
												options={(this.state.tags || []).map((tag) => { return { label: tag, value: tag } })}
												isClearable={true}
												isMulti={true}
												isSearchable={true}
												closeMenuOnSelect={true}
												backspaceRemovesValue={true}
												onChange={this.handleDataChange}
												disabled={this.props.disabled}
												isValidNewOption={false}
												className="combo-box-inline"
											/>
										</EntryRow>
									</Fragment>
									: <Fragment />
								}
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
		);
	}

	handleDataChange(e, isBool) {
		const { name, value } = extractDomElement(e.target, isBool);

		const entityFilters = {
			...this.state.entityFilters,
			[name]: value
		};

		this.setState({ entityFilters });
	}
}
