import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import PluginEditModal from "./PluginEditModal";
import clsx from "clsx";
import { moveArrayItemsByKey } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class PluginList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.pluginRowContextMenuName = `PluginRowContextMenu_${uniqueName}`;

		this.state = {
			selectedRowKeyList: [],
			showPluginEditModal: false,
			editingEntityPlugin: null,
			openShowJsonModal: false
		};

		this.tableColumns = [
			{ fieldName: "PluginName", displayName: "Plugin", customValue: (rowObject, fieldName) => rowObject.PluginDefinition[fieldName] },
			{ fieldName: "EntityPluginName", displayName: "Entity Plugin Alias" }
		];

		this.closePluginEditModal = this.closePluginEditModal.bind(this);
		this.setPluginProfiles = this.setPluginProfiles.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.delete = this.delete.bind(this);
		this.reorder = this.reorder.bind(this);
	}

	render() {
		const canChangeEntityPlugins = this.props.isNew || this.context.hasPermission(this.props.entityGuid, "Change Entity Plugins");

		return (
			<div className={clsx("list-table-container", "entity-profile-file-type-list", this.props.className)} >
				<fieldset>
					<legend>File Upload Processing Plugins</legend>
					<div className="entry-table-inner-container" >
						<SelecTable className="profile-table"
							columns={this.tableColumns}
							rowObjects={this.props.pluginProfiles}
							getRowKey={(rowData) => rowData.EntityPluginName}
							selectMode={this.props.disabled ? "single" : "multi"}
							contextMenuId={this.pluginRowContextMenuName}
							setSelectedRowKeys={this.setSelectedRowKeys}
							onDoubleClick={(...args) => this.edit(args[2])}
						/>
						<div className="button-bar">
							{this.props.disabled ? <Fragment /> :
								<Fragment>
									<button onClick={this.add}>Add New...</button>
									{this.props.pluginProfiles && this.props.pluginProfiles.length > 1 ? (
										<Fragment>
											<button title="Move selected row(s) up." onClick={() => this.reorder(-1)}>▲</button>
											<button title="Move selected row(s) down." onClick={() => this.reorder(1)}>▼</button>
										</Fragment>)
										: <Fragment />
									}
								</Fragment>
							}
							<button onClick={() => this.setState({ openShowJsonModal: true })} title="Show JSON.">Show JSON</button>
						</div>
						<ShowJsonModal
							heading="File Upload Processing Plugins (JSON)"
							isOpen={this.state.openShowJsonModal}
							closeModal={() => this.setState({ openShowJsonModal: false })}
							value={this.props.pluginProfiles}
							setValue={(value) => { this.props.setPluginProfiles(value); this.setState({ openShowJsonModal: false }); }}
							showDirty={!this.props.isNew}
							disabled={this.props.disabled}
						/>
						<ContextMenu id={this.pluginRowContextMenuName}>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}
								disabled={this.state.selectedRowKeyList.length !== 1}>View{this.props.disabled ? "" : "/Edit"}...</MenuItem>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}
								disabled={this.props.disabled || this.state.selectedRowKeyList.length !== 1 || !canChangeEntityPlugins}>Duplicate...</MenuItem>
							<MenuItem divider />
							<MenuItem onClick={(e, data) => _.defer(() => { this.delete(data.selectedRowKeys); })}
								disabled={this.props.disabled || this.state.selectedRowKeyList.length === 0 || !canChangeEntityPlugins}>Delete</MenuItem>
						</ContextMenu>
						<PluginEditModal
							isOpen={this.state.showPluginEditModal}
							closeModal={this.closePluginEditModal}
							editingEntityPlugin={this.state.editingEntityPlugin}
							setPluginProfile={this.setPluginProfiles}
							isNew={this.props.isNew}
							config={this.props.config}
							disabled={this.props.disabled}
						/>
					</div>
				</fieldset>
			</div>
		);
	}

	setPluginProfiles(pluginProfile, pluginProfileOriginalName) {
		if (!pluginProfile.EntityPluginName) {
			alert("Plugin alias cannot be empty.");
			return false;
		}

		const pluginProfiles = [
			...(this.props.pluginProfiles || [])
		];

		if (pluginProfile.EntityPluginName !== pluginProfileOriginalName) {
			// Check for duplicates
			let savedPluginProfile = null;
			if (pluginProfiles.some((thisPluginProfile) => {
				savedPluginProfile = thisPluginProfile;
				return thisPluginProfile.EntityPluginName.toLowerCase() === pluginProfile.EntityPluginName.toLowerCase();
			})) {
				alert(`Duplicate plugin aliases are not allowed. Plugin alias '${savedPluginProfile.EntityPluginName}' already exists.`);
				return false;
			}
		}

		pluginProfileOriginalName = pluginProfileOriginalName || pluginProfile.EntityPluginName;
		let isUpdated = false;

		for (let i = pluginProfiles.length - 1; i >= 0; i--) {
			const thisPluginProfile = pluginProfiles[i];
			if (thisPluginProfile.EntityPluginName === pluginProfileOriginalName) {
				pluginProfiles[i] = pluginProfile;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			pluginProfiles.push(pluginProfile);

		// eslint-disable-next-line
		pluginProfiles.sort((a, b) => a.SortOrder == b.SortOrder ? 0 : (a.SortOrder < b.SortOrder ? -1 : 1));

		this.props.setPluginProfiles(pluginProfiles);
		return true;
	}

	setSelectedRowKeys(selectedRowKeys) {
		this.setState({ selectedRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	add() {
		this.edit(null);
	}

	reorder(direction) {
		let pluginProfiles = moveArrayItemsByKey(direction, this.props.pluginProfiles, "EntityPluginName", this.state.selectedRowKeyList);

		if (pluginProfiles === this.props.pluginProfiles)
			return; // same object indicates no changes

		const sortOrderIncrement = 10;
		let sortOrder = sortOrderIncrement;
		pluginProfiles = pluginProfiles.map(p => {
			const pluginProfile = { ...p, SortOrder: sortOrder };
			sortOrder += sortOrderIncrement;
			return pluginProfile;
		});

		this.props.setPluginProfiles(pluginProfiles);
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const pluginProfiles = this.props.pluginProfiles || [];
		const matchingPluginProfiles = pluginProfiles.filter((pluginProfiles) => pluginProfiles.EntityPluginName === selectedRowKey);

		let pluginProfile = matchingPluginProfiles.length > 0 ? matchingPluginProfiles[0] : { PluginDefinition: {} };

		if (createCopy) {
			pluginProfile = { ...pluginProfile, EntityPluginName: null };
			delete pluginProfile.EntityPluginId;
		}

		this.setState({ editingEntityPlugin: pluginProfile, showPluginEditModal: true });
	}

	delete(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected entity plugin${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const pluginProfiles = this.props.pluginProfiles.filter((pluginProfiles) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === pluginProfiles.EntityPluginName));

			this.props.setPluginProfiles(pluginProfiles);
		}
	}

	closePluginEditModal() {
		this.setState({ showPluginEditModal: false });
	}
}