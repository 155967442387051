import React from "react";

export const UserContext = React.createContext({});

export class UserContextProvider extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			webUserSession: null
		};

		this.allEntitiesGuid = "00000000-0000-0000-0000-000000000000";
		this.systemFileTypesEntityGuid = "00000000-0000-0000-0000-000000000001";

		this.setWebUserSession = this.setWebUserSession.bind(this);
		this.getWebUserSession = this.getWebUserSession.bind(this);
		this.hasPermission = this.hasPermission.bind(this);
	}

	setWebUserSession(webUserSession) {
		if (window.localStorage) {
			const saveToLocalStorage = window.localStorage.getItem("loginRememberMe") === "true";

			if (saveToLocalStorage) {
				window.localStorage.setItem("webUserSession", webUserSession ? JSON.stringify(webUserSession) : null);
			}
			else {
				window.localStorage.setItem("webUserSession", null); // Clear the session since the user does not want to be remembered
			}
		}

		this.setState({
			webUserSession: webUserSession
		});
	}

	getWebUserSession() {
		if (this.state.webUserSession)
			return this.state.webUserSession;

		if (window.localStorage) {
			// Get session from local storage to make the page render sooner.
			try {
				const webUserSession = JSON.parse(window.localStorage.getItem("webUserSession"));
				if (webUserSession) {
					return { ...webUserSession, needsRefresh: true };
				}
			}
			catch{ }
		}

		return null;
	}

	hasPermission(entityGuid, requiredPermission) {
		const webUserSession = this.getWebUserSession();
		if (!entityGuid || !requiredPermission || !webUserSession || !webUserSession.UserDetails || !webUserSession.UserDetails.EntityPermissionsList)
			return false;

		requiredPermission = requiredPermission.toLowerCase();
		if (entityGuid === "*") // Match permission for any entity
			return webUserSession.UserDetails.EntityPermissionsList.some(entityPermissions => entityPermissions.UsablePermissions.some(usablePermission => usablePermission.toLowerCase() === requiredPermission));

		// Match for specific entity or "all entities"
		const matchingEntityPermissionsList = webUserSession.UserDetails.EntityPermissionsList.filter(entityPermissions => entityPermissions.EntityGuid === entityGuid || entityPermissions.EntityGuid === this.allEntitiesGuid);
		return matchingEntityPermissionsList.some(entityPermissions => entityPermissions.UsablePermissions.some(usablePermission => usablePermission.toLowerCase() === requiredPermission));
	}

	render() {
		const context = {
			setWebUserSession: this.setWebUserSession,
			getWebUserSession: this.getWebUserSession,
			hasPermission: this.hasPermission,
			allEntitiesGuid: this.allEntitiesGuid,
			systemFileTypesEntityGuid: this.systemFileTypesEntityGuid
		};

		return (
			<UserContext.Provider value={context}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}

