import React, { Fragment } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group"
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Input from "../../Shared/Input"
import FileInput from "../../Shared/FileInput"
import SelectFileType from "../../Shared/SelectFileType"
import EntryRow from "../../Shared/EntryRow"
import Select from "../../Shared/Select";
import { extractDomElement, evaluate, getValueDeclarations } from "../../Helpers/SharedFunctions";
import _ from "lodash";
import "./FileSection.scss";

export default class FileSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hiddenMetadataFields: {},
			filenameHelperText: null
		};

		this.getMetadataFieldRows = this.getMetadataFieldRows.bind(this);
		this.handleFileDataChange = this.handleFileDataChange.bind(this);
		this.handleFileMetadataChange = this.handleFileMetadataChange.bind(this);

		this.handleFilesDataPropsChange = this.handleFilesDataPropsChange.bind(this);
		this.handleFilesDataPropsChangeDebounced = _.debounce(this.handleFilesDataPropsChange.bind(this), 250);
	}

	componentDidMount() {
		this._isMounted = true;
		this.handleFilesDataPropsChange(); // Initialize
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps !== this.props) {
			this.handleFilesDataPropsChangeDebounced(true);
		}
	}

	handleFilesDataPropsChange() {
		if (!this._isMounted)
			return;

		const hiddenMetadataFields = {};
		const fileTypeDetails = this.props.fileTypeDetailsMap[this.props.fileDef.FileTypeName];

		if (fileTypeDetails) {
			const valueDeclarations = getValueDeclarations(this.props.fileDef.Metadata, fileTypeDetails.MetadataFields);

			fileTypeDetails.MetadataFields.forEach((metadataField) => {
				if (!evaluate(metadataField.AvailableCondition, valueDeclarations))
					hiddenMetadataFields[metadataField.FieldName] = true;
			});
		}

		this.setState({ hiddenMetadataFields, filenameHelperText: fileTypeDetails?.FilenameHelperText })
	}

	render() {
		return (
			<div className="entry-table-container file-data">
				<fieldset disabled={this.props.fileDef.isLoading}>
					<legend><span>File {this.props.fileNumber}</span> <DeleteIcon className={"action-icon"} file-id={this.props.fileId} title="Remove this file" onClick={(e) => this.props.removeFileData(this.props.fileId)} /></legend>
					<div className="entry-table-inner-container">
						<table>
							<TransitionGroup component="tbody">
								{!this.props.fileDef.errorMessages._ ? (<Fragment />) : (
									<CSSTransition timeout={{ enter: 300, exit: 300 }}>
										<tr>
											<td colSpan="2"><div>
												{(() => {
													let errorMessageNumber = 0;
													return this.props.fileDef.errorMessages._.split("\n").map(errorMessage => {
														errorMessageNumber++;
														return (<span className="error-message" key={errorMessageNumber}>{errorMessage}&nbsp;</span>);
													})
												})()}
											</div></td>
										</tr>
									</CSSTransition>
								)}
								<CSSTransition timeout={{ enter: 0, exit: 0 }}>
									<EntryRow label="* File" labelTdStyle={{ "paddingBottom": "0.25em" }} title={this.state.filenameHelperText}>
										<FileInput
											name="file"
											file-id={this.props.fileId}
											onChange={this.handleFileDataChange}
											file={this.props.fileDef.file}
											errorMessage={this.props.fileDef.errorMessages.file} />
									</EntryRow>
								</CSSTransition>
								<CSSTransition timeout={{ enter: 0, exit: 0 }}>
									<EntryRow label="* File Type" title={this.state.filenameHelperText}>
										<SelectFileType name="FileTypeName"
											file-id={this.props.fileId}
											fileTypeNames={this.props.fileTypeNames}
											value={this.props.fileDef.FileTypeName}
											onChange={this.handleFileDataChange}
											errorMessage={this.props.fileDef.errorMessages.FileTypeName} />
									</EntryRow>
								</CSSTransition>
								{this.getMetadataFieldRows(this.props.fileDef, this.props.fileId)}
							</TransitionGroup>
						</table>
					</div>
				</fieldset>
			</div>
		);
	}

	getMetadataFieldRows(fileDef, fileId) {
		const fileTypeDetails = this.props.fileTypeDetailsMap[fileDef.FileTypeName];
		if (fileTypeDetails === undefined)
			return null;

		return fileTypeDetails.MetadataFields.map((metadataField) => {
			let valueOptions = null;
			if (metadataField.ValueOptions && metadataField.ValueOptions.trim()) {
				valueOptions = [null, ...metadataField.ValueOptions.replace(/,/g, "\n").split("\n").map(v => v.trim())];
			}

			const isVisible = !metadataField.IsStaticValue && !this.state.hiddenMetadataFields[metadataField.FieldName];

			return (
				<CSSTransition key={metadataField.FieldName} classNames="file-section-row" timeout={{ enter: 300, exit: 300 }} visible={isVisible}>
					<EntryRow label={`${metadataField.IsRequired ? "* " : ""}${metadataField.DisplayName}`}>
						{
							valueOptions ?
								(<Select
									file-id={fileId}
									name={metadataField.FieldName}
									value={fileDef.Metadata[metadataField.FieldName] || ""}
									title={metadataField.Tooltip}
									onChange={this.handleFileMetadataChange}
									errorMessage={fileDef.errorMessages[metadataField.FieldName]}
									options={valueOptions.map((value) => {
										return {
											value: value,
											label: value
										};
									})}
								/>) :
								(
									<Input type="text"
										file-id={fileId}
										name={metadataField.FieldName}
										value={fileDef.Metadata[metadataField.FieldName] || ""}
										title={metadataField.Tooltip}
										onChange={this.handleFileMetadataChange}
										errorMessage={fileDef.errorMessages[metadataField.FieldName]}
									/>
								)
						}
					</EntryRow>
				</CSSTransition>
			);
		});
	}

	handleFileDataChange(e) {
		const { name, value, type } = extractDomElement(e.target);

		const fileData = {
			...this.props.fileData,
			[name]: value
		};

		fileData.errorMessages = {
			...fileData.errorMessages,
			[name]: null,
			_: null
		};

		if (type === "file" && fileData.filenamePlaceholder) {
			delete fileData.filenamePlaceholder; // clear this placeholder once a file has been selected
		}

		this.props.setFileData(fileData, this.props.fileId);
	}

	handleFileMetadataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const metadata = {
			...this.props.fileData.Metadata,
			[name]: value
		};

		const fileData = {
			...this.props.fileData,
			Metadata: metadata
		};

		fileData.errorMessages = {
			...fileData.errorMessages,
			[name]: null,
			_: null
		};

		this.props.setFileData(fileData, this.props.fileId);
	}
}
