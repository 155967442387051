import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import EntityUserRolesEditModal from "./EntityUserRolesEditModal";
import clsx from "clsx";
import _ from "lodash";

export default class EntitiesList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.entitiesListRowContextMenuName = `EntitiesListRowContextMenu_${uniqueName}`;

		this.state = {
			selectedEntitiesRowKeyList: [],
			showEntityUserRolesEditModal: false,
			editingEntityUserRoles: null,
		};

		this.entityTableColumns = [
			{ fieldName: "Name", displayName: "Entity" },
			{
				fieldName: "UsableRoleNames", displayName: "Usable Roles", customValue: (rowObject, fieldName) => {
					const roleNames = rowObject[fieldName] || [];
					return roleNames.join(", ");
				},
			}
		];

		this.closeEntityUserRolesEditModal = this.closeEntityUserRolesEditModal.bind(this);
		this.setRoles = this.setRoles.bind(this);
		this.setSelectedEntityRowKeys = this.setSelectedEntityRowKeys.bind(this);
		this.addEntity = this.addEntity.bind(this);
		this.editEntity = this.editEntity.bind(this);
		this.removeEntity = this.removeEntity.bind(this);
	}

	render() {
		return (
			<div>
				<div className={clsx("list-table-container", "user-profile-entity-list", this.props.className)} >
					<fieldset>
						<legend>{this.props.title || "Entities"} (right-click for menu)</legend>
						<div className="entry-table-inner-container">
							<SelecTable className="profile-table"
								columns={this.entityTableColumns}
								rowObjects={this.props.entityUserRoles}
								getRowKey={(rowData) => rowData.Guid}
								selectMode={this.props.disableEntities ? "single" : "multi"}
								setSelectedRowKeys={this.setSelectedEntityRowKeys}
								contextMenuId={this.entitiesListRowContextMenuName}
								onDoubleClick={(...args) => this.editEntity(args[2])}
							/>
							{(this.props.disableEntities || this.props.isSelfAudit) ? <Fragment /> : (
								<div className="button-bar">
									<button onClick={this.addEntity}>Add...</button>
								</div>)
							}
							<ContextMenu id={this.entitiesListRowContextMenuName}>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editEntity(data.selectedRowKeys); })}
									disabled={this.state.selectedEntitiesRowKeyList.length !== 1}>View{(this.props.disableEntities || this.props.isSelfAudit) ? "" : "/Edit"}...</MenuItem>
								{this.props.isSelfAudit ? <Fragment /> : <MenuItem onClick={(e, data) => _.defer(() => { this.editEntity(data.selectedRowKeys, true); })}
									disabled={this.props.disableEntities || this.state.selectedEntitiesRowKeyList.length !== 1}>Duplicate...</MenuItem>}
								{this.props.isSelfAudit ? <Fragment /> : <MenuItem divider />}
								<MenuItem onClick={(e, data) => _.defer(() => { this.removeEntity(data.selectedRowKeys); })}
									disabled={this.props.disableEntities || this.state.selectedEntitiesRowKeyList.length === 0}>Remove</MenuItem>
							</ContextMenu>
							<EntityUserRolesEditModal
								isOpen={this.state.showEntityUserRolesEditModal}
								closeModal={this.closeEntityUserRolesEditModal}
								editingEntityUserRoles={this.state.editingEntityUserRoles}
								setRoles={this.setRoles}
								entityGuid={this.props.entityGuid}
								config={this.props.config}
								disabled={this.props.disableEntities || this.props.isSelfAudit}
							/>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}

	setRoles(roles, originalGuid) {
		const entityUserRoles = [
			...(this.props.entityUserRoles || [])
		];

		if (!roles.Guid) {
			alert("Entity cannot be empty.");
			return false;
		}

		if (roles.Guid !== originalGuid) {
			// Check for duplicates
			let saved = null;
			if (entityUserRoles.some((thisRoles) => {
				saved = thisRoles;
				return thisRoles.Guid.toLowerCase() === roles.Guid.toLowerCase();
			})) {
				alert(`Entity '${saved.Name}' already exists and cannot be added again. Edit the existing entry instead.`);
				return false;
			}
		}

		let isUpdated = false;

		for (let i = entityUserRoles.length - 1; i >= 0; i--) {
			const thisUserEntityProfile = entityUserRoles[i];
			if (thisUserEntityProfile.Guid === originalGuid) {
				entityUserRoles[i] = roles;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			entityUserRoles.push(roles);

		this.props.setEntityUserRoles(entityUserRoles);
		return true;
	}

	setSelectedEntityRowKeys(selectedUserRowKeys) {
		this.setState({ selectedEntitiesRowKeyList: Object.getOwnPropertyNames(selectedUserRowKeys) });
	}

	addEntity() {
		this.editEntity(null);
	}

	editEntity(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const matchingEntityUserRoles = (this.props.entityUserRoles || []).filter((userEntityProfile) => userEntityProfile.Guid === selectedRowKey);

		let editingEntityUserRoles = matchingEntityUserRoles.length > 0 ? matchingEntityUserRoles[0] : { };

		if (createCopy)
			editingEntityUserRoles = { ...editingEntityUserRoles, Guid: null, Name: null  };

		this.setState({ editingEntityUserRoles: editingEntityUserRoles, showEntityUserRolesEditModal: true });
	}

	removeEntity(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Remove ${this.props.isSelfAudit ? "your" : "user"} access from selected entit${selectedRowKeys.length > 1 ? "ies" : "y"}?`)) {
			const entityUserRoles = (this.props.entityUserRoles || []).filter((entityUserRole) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === entityUserRole.Guid));

			this.props.setEntityUserRoles(entityUserRoles);
		}
	}

	closeEntityUserRolesEditModal() {
		this.setState({ showEntityUserRolesEditModal: false });
	}
}