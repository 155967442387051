import React, { Fragment } from "react";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import OptionEditModal from "./OptionEditModal";
import clsx from "clsx";
import _ from "lodash";

export default class OptionsList extends React.Component {
	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.settingDefinitionListRowContextMenuName = `SettingDefinitionListRowContextMenu_${uniqueName}`;

		this.state = {
			selectedRowKeyList: [],
			showSettingDefinitionEditModal: false,
			editingOption: null
		};

		this.tableColumns = [
			{ fieldName: "Value", displayName: "Value" },
			{ fieldName: "Label", displayName: "Label" }
		];

		this.closeSettingDefinitionEditModal = this.closeSettingDefinitionEditModal.bind(this);
		this.setOption = this.setOption.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.delete = this.delete.bind(this);
	}

	render() {
		return (
			<div className={clsx("list-table-container", "entity-profile-file-type-list", this.props.className)} >
				<fieldset>
					<legend>{this.props.title}</legend>
					<div className="entry-table-inner-container" >
						<SelecTable className="profile-table"
							columns={this.tableColumns}
							rowObjects={this.props.options}
							getRowKey={(rowData) => rowData.Value}
							selectMode={this.props.disabled ? "none" : "multi"}
							contextMenuId={this.settingDefinitionListRowContextMenuName + this.props.name}
							setSelectedRowKeys={this.setSelectedRowKeys}
							onDoubleClick={(...args) => this.edit(args[2])}
						/>
						{this.props.disabled ? <Fragment /> : (
							<div className="button-bar">
								<button onClick={this.add}>Add New...</button>
							</div>)
						}
						<ContextMenu id={this.settingDefinitionListRowContextMenuName + this.props.name}>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}>View/Edit...</MenuItem>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}>Duplicate...</MenuItem>
							<MenuItem divider />
							<MenuItem onClick={(e, data) => _.defer(() => { this.delete(data.selectedRowKeys); })}>Delete</MenuItem>
						</ContextMenu>
						<OptionEditModal
							isOpen={this.state.showSettingDefinitionEditModal}
							closeModal={this.closeSettingDefinitionEditModal}
							editingOption={this.state.editingOption}
							setOption={this.setOption}
							isNew={this.props.isNew}
						/>
					</div>
				</fieldset>
			</div>
		);
	}

	setOption(option, optionOriginalValue) {
		if (!option.Value) {
			alert("Value cannot be empty.");
			return false;
		}

		const options = [
			...(this.props.options || [])
		];

		if (option.Value !== optionOriginalValue) {
			// Check for duplicates
			let savedOption = null;
			if (options.some((thisOption) => {
				savedOption = thisOption;
				return thisOption.Value.toLowerCase() === option.Value.toLowerCase();
			})) {
				alert(`Duplicate alues are not allowed. Value '${savedOption.Value}' already exists.`);
				return false;
			}
		}

		optionOriginalValue = optionOriginalValue || option.Value;
		let isUpdated = false;

		for (let i = options.length - 1; i >= 0; i--) {
			const thisOption = options[i];
			if (thisOption.Value === optionOriginalValue) {
				options[i] = option;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			options.push(option);

		this.props.setOptions(options);
		return true;
	}

	setSelectedRowKeys(selectedRowKeys) {
		this.setState({ selectedRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	add() {
		this.edit(null);
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const options = this.props.options || [];
		const matchingOption = options.filter((option) => option.Value === selectedRowKey);

		let option = matchingOption.length > 0 ? matchingOption[0] : { Enabled: true };

		if (createCopy)
			option = { ...option, Name: null };

		this.setState({ editingOption: option, showSettingDefinitionEditModal: true });
	}

	delete(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected field${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const options = this.props.options.filter((option) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === option.Value));

			this.props.setOptions(options);
		}
	}

	closeSettingDefinitionEditModal() {
		this.setState({ showSettingDefinitionEditModal: false });
	}
}