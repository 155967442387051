import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import "./UploadStatus.scss";

export default class UploadStatus extends React.Component {
	constructor(props) {
		super(props);
		this.getUploadStates = this.getUploadStates.bind(this);
	}

	render() {
		if (!this.props.isOpen)
			return (<Fragment />);

		return (
			<BetterDialog title={`Upload ${this.props.isUploadComplete ? "complete" : "progress"}`} open={this.props.isOpen}>
				<div className="upload-status">
					<div className="upload-status-heading">
						Status: {this.props.uploadStatus}
					</div>
					<div className="upload-status-list">
						{this.getUploadStates()}
					</div>
				</div>
				{this.props.isUploadComplete ? (
					<BetterDialogButtons>
						<button className="primary" onClick={this.props.closeModal}>Close</button>
					</BetterDialogButtons>)
					: (<Fragment />)
				}
			</BetterDialog>
		);
	}

	getUploadStates() {
		return this.props.filesDataUploadStates.map((fileUploadState) => {
			const percentComplete = Math.round(fileUploadState.percentComplete);
			const styleClass = fileUploadState.percentComplete > 0 && fileUploadState.percentComplete < 100 ? "active" : "inactive";
			return (
				<div key={fileUploadState.fileData.key} className={styleClass}>
					<div className="progress-bar-2">
						<div className={fileUploadState.hasErrors ? "error" : ""} style={{ width: `${percentComplete}%` }}>
							{fileUploadState.hasErrors ? <Fragment /> :
								<div class="percent-text">{percentComplete}%</div>
							}
						</div>
					</div>&nbsp;&nbsp;<span>{fileUploadState.fileData.file.name} ({fileUploadState.fileData.file.size.toLocaleString()} bytes)</span>
				</div>
			);
		});
	}
}