import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import UserGroupUsersList from "./UserGroupUsersList";
import UserGroupEntitiesList from "./UserGroupEntitiesList";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import Select from "../../Shared/Select";
import Input from "../../Shared/Input";
import EntryRow from "../../Shared/EntryRow";
import TextArea from "../../Shared/TextArea";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { UserContext } from "../../Contexts/UserContext";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import Link from "../../Shared/Link";
import _ from "lodash";

export default class UserGroupEditModal extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		this.state = {
			editingUserGroup: this.props.editingUserGroup,
			isEditingOwner: false
		};

		this.getDomainOptions = this.getDomainOptions.bind(this);
		this.setUsers = this.setUsers.bind(this);
		this.save = this.save.bind(this);
		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingUserGroup
		const editingUserGroup = this.props.editingUserGroup ?? {};
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const isExisting = !!editingUserGroup.UserGroupId;
			this.setState({
				editingUserGroup,
				isExisting,
				isEditingOwner: false
			});
		}
	}

	render() {
		const editingUserGroup = this.state.editingUserGroup ?? {};
		const previousEditingUserGroup = this.props.editingUserGroup ?? {};
		const domainUserGroupName = previousEditingUserGroup.DomainUserGroupName ?? "[New]";
		const isExisting = !!previousEditingUserGroup.UserGroupId;
		const isOwner = this.context.getWebUserSession().UserDetails.DomainUsername === previousEditingUserGroup.OwnerDomainUsername;
		const canChangeAllUserGroups = this.context.hasPermission("*", `Change User Groups (${editingUserGroup.DomainName})`);
		const canChangeOwnUserGroups = this.context.hasPermission("*", "Change Own User Groups");
		const isEditable = (isOwner && canChangeOwnUserGroups) || canChangeAllUserGroups;

		const formatOptionLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}

		return (
			<BetterDialog title={`User Group: ${domainUserGroupName}`} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>User Group Definition</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="User Group ID" style={{ display: "none" }}>
										<span>{previousEditingUserGroup.UserGroupId ?? "[New]"}</span>
									</EntryRow>
									<EntryRow label="* Domain" title="Domain">
										{editingUserGroup.DomainName ? <span>{editingUserGroup.DomainName ?? ""}</span> :
											<LoadingWrapper isLoading={!this.state.domainOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
												<Select
													name="DomainName"
													value={editingUserGroup.DomainName}
													previousValue={previousEditingUserGroup.DomainName}
													showDirty={isExisting}
													disabled={this.props.disabled || !isEditable}
													onChange={this.handleDataChange}
													options={this.getDomainOptions()}
													autoFocus={this.props.isOpen && !editingUserGroup.DomainName}
												/>
											</LoadingWrapper>
										}
									</EntryRow>
									<EntryRow label="* User Group Name" title="User Group Name">
										<Input type="text" maxLength={100}
											name="UserGroupName"
											value={editingUserGroup.UserGroupName ?? ""}
											previousValue={previousEditingUserGroup.UserGroupName ?? ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen && isExisting}
											disabled={this.props.disabled || !isEditable}
										/>
									</EntryRow>
									<EntryRow label="Sync LDAP Group" title={"LDAP group name to synchronize users.\nThe group will be syncronized periodically.\nLeave empty to disable synchronizing with an LDAP group.\nWarning: Misspelled LDAP group names will cause all users to be removed from this user group."}>
										<Input type="text" maxLength={100}
											name="LdapGroupName"
											value={editingUserGroup.LdapGroupName ?? ""}
											previousValue={previousEditingUserGroup.LdapGroupName ?? ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={this.props.disabled || !isEditable}
										/>
									</EntryRow>
									<EntryRow label="Description" title="Description">
										<TextArea
											maxLength={1000}
											name="Description"
											value={editingUserGroup.Description}
											previousValue={previousEditingUserGroup.Description}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={this.props.disabled || !isEditable}
										/>
									</EntryRow>
									<EntryRow label="Owner" title="Owner">
										{this.state.isEditingOwner ?
											<ComboBoxAsync
												name="OwnerDomainUsername"
												value={editingUserGroup.OwnerDomainUsername}
												previousValue={previousEditingUserGroup.OwnerDomainUsername}
												showDirty={isExisting}
												loadOptions={_.debounce(this.loadUsernameOptions, 250)}
												isSearchable={true}
												onChange={this.handleDataChange}
												noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
												formatOptionLabel={formatOptionLabel}
												autoFocus={true}
												disabled={this.state.disabled}
											/>
											:
											<Fragment>
												<span>{editingUserGroup.OwnerDomainUsername}</span>
												{!this.props.disabled && isEditable ? <span> (<Link onClick={() => this.setState({ isEditingOwner: true })}>Change</Link>)</span> : <Fragment />}
											</Fragment>
										}
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
					<UserGroupUsersList
						users={editingUserGroup.Users}
						domainName={editingUserGroup.DomainName}
						setUsers={this.setUsers}
						availableRoleNames={this.state.availableRoleNames}
						disabled={this.props.disabled || !isEditable || !!editingUserGroup.LdapGroupName}
						ldapGroupName={editingUserGroup.LdapGroupName}
						config={this.props.config}
					/>
					<UserGroupEntitiesList
						entityUserGroupRoles={editingUserGroup.EntityUserGroupRoles}
						disabled={this.props.disabled || !isEditable}
					/>
				</div>
				<BetterDialogButtons>
					{isEditable ?
						<Fragment>
							<button onClick={this.save}>Save{!!editingUserGroup.LdapGroupName ? " and sync with LDAP" : ""}</button>
							<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
						</Fragment>
						: <button onClick={this.props.closeModal}>Close</button>
					}
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers("Viasat", inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					return {
						value: domainUser.DomainUsername,
						label: `${domainUser.FirstName ?? ""} ${domainUser.LastName ?? ""} (${domainUser.Username})`,
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
			}
		);
	}

	getDomainOptions() {
		if (this.state.domainOptions)
			return this.state.domainOptions;

		if (!this.state.isGettingDomainOptions && this.state.isExisting === false) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingDomainOptions: true }));

			this.apiService.getDomains("",
				(response) => {
					const domainNames = ["", ...response.data.DomainNames, ...response.data.VirtualDomainNames];
					const domainOptions = domainNames.map(domainName => { return { value: domainName, label: domainName } });

					this.setState({
						isGettingDomainOptions: false,
						domainOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingDomainOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);
		// Handle object properties with a dot (.) separator
		const names = name.split(".");

		const name1 = names[0];
		const name2 = names[1];

		const editingUserGroup = {
			...this.state.editingUserGroup
		};

		editingUserGroup[name1] = name2 ? { ...editingUserGroup[name1] } : value;
		if (name2) {
			editingUserGroup[name1][name2] = value;
		}

		this.setState({ editingUserGroup });
	}

	setUsers(users) {
		const editingUserGroup = {
			...this.state.editingUserGroup,
			Users: users
		};

		this.setState({ editingUserGroup });
	}

	save() {
		let editingUserGroup = this.state.editingUserGroup
		//const userGroupSettings = editingUserGroup.UserGroupSettings;
		if (!editingUserGroup.UserGroupName) {
			alert("User Group Name is required!");
			return;
		}
		if (!editingUserGroup.DomainName) {
			alert("Domain Name is required!");
			return;
		}

		editingUserGroup = { ...editingUserGroup, DomainUserGroupName: `${editingUserGroup.DomainName}\\${editingUserGroup.UserGroupName}` }

		const me = this;
		this.props.saveUserGroup(editingUserGroup, () => { me.props.closeModal(); });
	}
}
