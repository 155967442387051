import React, { Fragment } from "react";
import EntryRow from "./EntryRow";
import TextArea from "./TextArea";
import Select from "./Select";
import Radio from "./Radio";
import Checkbox from "./Checkbox";
import Input from "./Input";
import Link from "./Link";

export default class DynamicEntryRows extends React.Component {
	constructor(props) {
		super(props);

		this.getEntryRow = this.getEntryRow.bind(this);
		this.getWidget = this.getWidget.bind(this);
		this.setFieldValue = this.setFieldValue.bind(this);
	}

	render() {
		let { fieldDefinitions, fieldValues, previousFieldValues, onChange, textBoxStyle, ...rest } = this.props;

		if (!fieldDefinitions)
			return <Fragment />

		fieldValues = fieldValues || {};
		previousFieldValues = previousFieldValues || {};

		return (
			<Fragment>
				{fieldDefinitions.map((fieldDefinition) => this.getEntryRow(fieldDefinition, fieldValues, previousFieldValues, rest))}
			</Fragment>
		);
	}

	getEntryRow(fieldDefinition, fieldValues, previousFieldValues, properties) {
		const defaultValue = fieldDefinition.DefaultValue;
		const fieldName = fieldDefinition.FieldName;

		return (
			<EntryRow label={fieldDefinition.DisplayName || fieldDefinition.FieldName} key={fieldDefinition.FieldName} title={fieldDefinition.Tooltip}>
				{this.getWidget(fieldDefinition, fieldValues, previousFieldValues, properties)}
				{!this.props.disabled && defaultValue ?
					(<div><Link title={"Set the following default value:\n" + defaultValue} onClick={() => this.setFieldValue(fieldName, defaultValue)}>Set default value</Link></div>)
					: (<Fragment />)}
			</EntryRow>
		);
	}

	getWidget(fieldDefinition, fieldValues, previousFieldValues, properties) {
		const commonProps = {
			name: fieldDefinition.FieldName,
			placeholder: fieldDefinition.Watermark,
			value: fieldValues[fieldDefinition.FieldName],
			previousValue: previousFieldValues[fieldDefinition.FieldName],
			onChange: this.props.onChange,
			...properties
		};

		switch (fieldDefinition.UiWidget) {
			case "TextArea":
				return (<TextArea {...commonProps} />);

			case "DropDown":
				let options = [];
				if (fieldDefinition.Options) {
					options = [{ Value: null }, ...fieldDefinition.Options]
				}
				return (<Select
					options={options.map((option) => {
						return {
							value: option.Value,
							label: option.Label !== null ? option.Label : option.Value
						};
					})}
					{...commonProps}
				/>);

			case "Radio":
				return (<Fragment>
					{fieldDefinition.Options.map((option) => {
						return (<Radio
							key={option.Value}
							optionValue={option.Value}
							label={option.Label !== null ? option.Label : option.Value}
							{...commonProps}
						/>);
					})}</Fragment>);

			case "Checkbox":
				return (<Checkbox {...commonProps} />);

			case "Textbox":
			default:
				let type = "text";
				let pattern = undefined;

				if (fieldDefinition.DataType === "Integer") {
					pattern = "^-?[0-9]+$"
				}
				else if (fieldDefinition.DataType === "Decimal") {
					pattern = "^-?(([0-9]+)|([0-9]*\\.[0-9]+))$";
				}

				return (<Input type={type} pattern={pattern} style={this.props.textBoxStyle} {...commonProps} />);
		}
	}

	setFieldValue(fieldName, value) {
		this.props.onChange({ target: { name: fieldName, value: value } });
	}
}