import React from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import _ from "lodash";
import FileSections from "./FileSections";
import UploadFilesButton from "./UploadFilesButton"
import FileManifestUploadButton from "./FileManifestUploadButton";
import FileManifestTemplateDownloadLink from "./FileManifestTemplateDownloadLink";
import Dropzone from 'react-dropzone';
import PageOverlay from "../../Shared/PageOverlay";
import "./Upload.scss";

export default class Upload extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			isInitialized: false,
			isLoading: true,
			entityDetails: {},
			fileTypeDetailsMap: {},
			formData: { filesData: [] },
			nextFileDataKey: 1,
			dropZoneClass: "",
			passiveLoadingCount: 0
		};

		this.addFileData = this.addFileData.bind(this);
		this.setFileData = this.setFileData.bind(this);
		this.setFilesData = this.setFilesData.bind(this);
		this.removeFileData = this.removeFileData.bind(this);
		this.getNextEmptyFileData = this.getNextEmptyFileData.bind(this);
		this.updateFileMetadata = this.updateFileMetadata.bind(this);
		this.addFiles = this.addFiles.bind(this);
		this.parseFileDataFilename = this.parseFileDataFilename.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	render() {
		const action = "Upload";
		const heading = `${this.state.entityDetails.Name || "..."} ${action}`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		const dropZoneClasses = `file-drop-zone ${this.state.dropZoneClass}`;

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<LoadingWrapper isLoading={this.state.isLoading}>
					<h1>{heading}</h1>
					<PageOverlay showOverlay={this.state.passiveLoadingCount > 0} />
					<FileSections filesData={this.state.formData.filesData}
						removeFileData={this.removeFileData}
						setFileData={this.setFileData}
						fileTypeDetailsMap={this.state.fileTypeDetailsMap}
						fileTypeNames={this.state.fileTypeNames}
					/>
					<div className="button-bar-split">
						<button onClick={this.addFileData}>Add File</button>
						<FileManifestUploadButton
							filesData={this.state.formData.filesData}
							nextFileDataKey={this.state.nextFileDataKey}
							setFilesData={this.setFilesData}
							fileTypeDetailsMap={this.state.fileTypeDetailsMap}
						/>
						<FileManifestTemplateDownloadLink />
						<UploadFilesButton style={{ float: "right" }}
							className="primary"
							filesData={this.state.formData.filesData}
							setFilesData={this.setFilesData}
							entityGuid={this.props.match.params.entityGuid}
							fileTypeDetailsMap={this.state.fileTypeDetailsMap}
							apiService={this.apiService}
						/>
					</div>
					<Dropzone
						onDrop={this.addFiles}
						onDragEnter={() => { this.setState({ dropZoneClass: "file-hover" }) }}
						onDragLeave={() => { this.setState({ dropZoneClass: "" }) }}>
						{({ getRootProps, getInputProps }) => (
							<div className={dropZoneClasses} {...getRootProps()}>
								<input {...getInputProps()} />
								<p>Drag and drop multiple files or folders here or click to add.</p>
							</div>
						)}
					</Dropzone>
				</LoadingWrapper>
			</Layout >
		);
	}

	initialize() {
		this.setState({ isLoading: true, isInitialized: true });
		this.apiService.getEntityDetails(this.props.match.params.entityGuid,
			(response) => {
				const fileTypeDetailsMap = {};
				let fileTypeNames = response.data.EntityDetails.FileTypeDetailsList.map((fileTypeDetails) => {
					fileTypeDetailsMap[fileTypeDetails.Name] = fileTypeDetails;
					return fileTypeDetails.Name;
				});

				for (let i = 0; i < fileTypeNames.length; i++) {
					const fileTypeDetails = fileTypeDetailsMap[fileTypeNames[i]];
					fileTypeDetails.canExtractAnyMetadataFromFilename = fileTypeDetails.MetadataFields.filter((field) => field.CanExtractFromFilename).length > 0;
				}

				if (fileTypeNames.length !== 1)
					fileTypeNames.unshift(""); // Add an empty selection for the dropdown lists

				this.setState({
					isLoading: false,
					entityDetails: response.data.EntityDetails,
					fileTypeDetailsMap,
					fileTypeNames
				});
				this.addFileData();
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);
	}

	addFileData() {
		const filesData = [...this.state.formData.filesData];
		const fileData = this.getNextEmptyFileData();
		filesData.push(fileData)

		const formData = {
			...(this.state.formData),
			filesData: filesData
		};
		this.setState({ errorMessage: null, formData: formData });
	}

	setFileData(fileData, fileId, performAutoParse) {
		performAutoParse = performAutoParse !== false;
		const prevFiledata = this.state.formData.filesData[fileId];
		if (!prevFiledata)
			return; // The record no longer exists

		const filesData = [...this.state.formData.filesData];

		// Replace the modified element
		filesData[fileId] = fileData;

		this.setFilesData(filesData);
		if (performAutoParse) {
			this.parseFileDataFilename(fileId, fileData, prevFiledata);
		}
	}

	parseFileDataFilename(fileId, fileData, prevFiledata) {
		const filename = fileData.file ? fileData.file.name : fileData.filenamePlaceholder;
		const prevFilename = (prevFiledata && prevFiledata.file) ? prevFiledata.file.name : prevFiledata ? prevFiledata.filenamePlaceholder : undefined;
		if (filename
			&& (!prevFiledata || prevFiledata.FileTypeName !== fileData.FileTypeName || prevFilename !== filename)) {
			const fileTypeName = !fileData.FileTypeName ? "*" : fileData.FileTypeName; // Use * to let the API know we do not know the file type
			// The filename or file type has changed. 
			const fileTypeDetails = this.state.fileTypeDetailsMap[fileData.FileTypeName];

			if (!fileTypeDetails || fileTypeDetails.canExtractAnyMetadataFromFilename) {
				// Update the metadata extracted from the filename
				_.defer(() => { this.updateFileMetadata(fileTypeName, filename, fileId); }); // Defer to allow the above state change to go through
			}
		}
	}

	setFilesData(filesData, nextFileDataKey) {
		nextFileDataKey = nextFileDataKey || this.state.nextFileDataKey;
		if (filesData.length === 0) {
			_.delay(() => {
				if (this.state.formData.filesData.length !== 0)
					return; // No longer 0...

				const filesDataCopy = [...this.state.formData.filesData];
				filesDataCopy.push(this.getNextEmptyFileData(nextFileDataKey++, false))
				const formDataUpdate = {
					...(this.state.formData),
					filesData: filesDataCopy
				};
				this.setState({ formData: formDataUpdate, nextFileDataKey: nextFileDataKey });
			}, 300 + 150); // wait until the remove animation completes and a little extra to make a smooth effect
		}

		const formData = {
			...(this.state.formData),
			filesData: filesData
		};
		this.setState({ errorMessage: null, formData: formData, nextFileDataKey: nextFileDataKey });
	}

	removeFileData(fileId) {
		const filesData = this.state.formData.filesData.filter((fileData, fileDataId) => fileDataId !== fileId);

		this.setFilesData(filesData);
	}

	getNextEmptyFileData(nextFileDataKey, shouldUpdateState) {
		nextFileDataKey = nextFileDataKey || this.state.nextFileDataKey;
		if (shouldUpdateState !== false)
			this.setState({ nextFileDataKey: nextFileDataKey + 1 });

		const fileTypeName = this.state.fileTypeNames.length === 1 ? this.state.fileTypeNames[0] : "";

		return {
			key: nextFileDataKey, errorMessages: {}, FileTypeName: fileTypeName, Metadata: {}
		}
	}

	updateFileMetadata(fileTypeName, filename, fileId) {
		this.setState({ passiveLoadingCount: this.state.passiveLoadingCount + 1 });
		this.setFileData({ ...this.state.formData.filesData[fileId], isLoading: true }, fileId, false);
		this.apiService.getFilenameMetadata(this.props.match.params.entityGuid, fileTypeName, filename,
			(response) => {
				const fileData = { ...this.state.formData.filesData[fileId], isLoading: false };
				fileData.Metadata = { ...fileData.Metadata, ...response.data.Metadata };
				fileData.FileTypeName = response.data.FileTypeName;

				this.setFileData(fileData, fileId, false);
				this.setState({ passiveLoadingCount: this.state.passiveLoadingCount - 1 });
			},
			(error) => {
				const filesData = { ...this.state.formData.filesData[fileId], isLoading: false };
				if (error && error.response && error.response.data && error.response.data.ErrorMessage) {
					filesData.errorMessages = { ...(filesData.errorMessages || {}), "_": error.response.data.ErrorMessage };
				}

				this.setFileData(filesData, fileId, false);
				this.setState({ passiveLoadingCount: this.state.passiveLoadingCount - 1 });
			}
		);
	}

	addFiles(files) {
		this.setState({ dropZoneClass: "", passiveLoadingCount: this.state.passiveLoadingCount + 1 });

		const filesData = [ // only get filesData that have something filled in
			...this.state.formData.filesData.filter((fileData) => !!fileData.file || !!fileData.filenamePlaceholder)
		];
		const firstNewFilesDataIndex = filesData.length;
		let nextFileDataKey = this.state.nextFileDataKey;
		files.forEach((file) => {
			// Add a record
			const fileData = this.getNextEmptyFileData(nextFileDataKey++, false);
			fileData.file = file;
			filesData.push(fileData);
		});

		this.setFilesData(filesData, nextFileDataKey);

		_.defer(() => {
			for (let i = firstNewFilesDataIndex; i < this.state.formData.filesData.length; i++) {
				const fileData = this.state.formData.filesData[i];
				this.parseFileDataFilename(i, fileData, null);
			}
			_.defer(() => {
				this.setState({ passiveLoadingCount: this.state.passiveLoadingCount - 1 });
			});
		});
	}
}
