import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import EntryRow from "../../Shared/EntryRow";
import Input from "../../Shared/Input";
import Link from "../../Shared/Link";
import Select from "../../Shared/Select";
import ComboBox from "../../Shared/ComboBox";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class UserEntityProfileEditModal extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			editingUserEntityProfile: this.props.editingUserEntityProfile,
			editingUserEntityProfileDomainUsername: null,
			selectedUsableRoleRowKeyList: [],
			selectedAssignableRoleRowKeyList: [],
			isEditingUsableRoles: false,
			isEditingAssignableRoles: false,
			isEditingEmailAddress: false,
			isEditingWhiteListIps: false,
			domainOptions: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.setUsableRoleNames = this.setUsableRoleNames.bind(this);
		this.setAssignableRoleNames = this.setAssignableRoleNames.bind(this);
		this.getDomainOptions = this.getDomainOptions.bind(this);
		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);

		this.save = this.save.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingUserEntityProfile
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const isExisting = !!this.props.editingUserEntityProfile.DomainUsername;
			this.setState({
				editingUserEntityProfile: this.props.editingUserEntityProfile,
				editingUserEntityProfileDomainUsername: this.props.editingUserEntityProfile.DomainUsername,
				isExisting,
				isEditingUsableRoles: !isExisting,
				isEditingAssignableRoles: !isExisting,
				isEditingEmailAddress: false, // make sure the email textbox turns back to read-only
				isEditingWhiteListIps: false // make sure the white list textbox turns back to read-only
			});
		}
	}

	render() {
		const editingUserEntityProfile = this.state.editingUserEntityProfile || {};
		const previousEditingUserEntityProfile = this.props.editingUserEntityProfile || {};
		const availableRoleNames = this.props.availableRoleNames || [];
		const domainUsername = previousEditingUserEntityProfile.DomainUsername || "[New]";
		const isExisting = this.state.isExisting;
		const canChangeEntityUsers = this.props.entityGuid === null || this.context.hasPermission(this.props.entityGuid, "Change Entity Users");
		//const webUserSession = this.context.getWebUserSession();
		const isOwnAccount = false; //(editingUserEntityProfile.DomainUsername || "").toLowerCase() === webUserSession.UserDetails.DomainUsername.toLowerCase();

		const formatOptionLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}
		return (
			<BetterDialog title={`User: ${domainUsername}`} open={this.props.isOpen}>
				<div className="entry-table-container user-entity-profile-edit-modal">
					<fieldset>
						<legend>Main</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="* Domain" title="Domain">
										{isExisting ? <span>{editingUserEntityProfile.DomainName || ""}</span> :
											<LoadingWrapper isLoading={!this.state.domainOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
												<Select
													name="DomainName"
													value={editingUserEntityProfile.DomainName}
													previousValue={previousEditingUserEntityProfile.DomainName}
													showDirty={isExisting}
													disabled={this.props.disabled || isExisting || isOwnAccount}
													onChange={this.handleDataChange}
													options={this.getDomainOptions()}
													autoFocus={this.props.isOpen && !isExisting}
												/>
											</LoadingWrapper>
										}
									</EntryRow>
									<EntryRow label="* Username" title="Username" innerDivStyle={{ overflow: "visible" }}>
										{isExisting ? <span>{editingUserEntityProfile.Username || ""}</span> :
											<ComboBoxAsync
												name="Username"
												value={editingUserEntityProfile.Username}
												previousValue={previousEditingUserEntityProfile.Username}
												showDirty={isExisting}
												disabled={this.props.disabled || isExisting || !editingUserEntityProfile.DomainName}
												loadOptions={_.debounce(this.loadUsernameOptions, 250)}
												isSearchable={true}
												onChange={this.handleDataChange}
												noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
												formatOptionLabel={formatOptionLabel}
											/>
										}
									</EntryRow>
									<EntryRow label="Domain/Username">
										<span>{editingUserEntityProfile.DomainUsername || ""}</span>
									</EntryRow>
									<EntryRow label="Full Name">
										<span>{editingUserEntityProfile.FirstName || ""} {editingUserEntityProfile.LastName || ""}</span>
									</EntryRow>
									<EntryRow label="Email" title="Email address">
										{this.state.isEditingEmailAddress ?
											<Input type="text" maxLength={255}
												className="email-input"
												name="EmailAddress"
												value={editingUserEntityProfile.EmailAddress || ""}
												previousValue={previousEditingUserEntityProfile.EmailAddress || ""}
												showDirty={isExisting}
												onChange={this.handleDataChange}
												autoFocus={this.state.isEditingEmailAddress}
												disabled={this.props.disabled}
											/>
											:
											<Fragment><span>{editingUserEntityProfile.EmailAddress}</span>
												{this.props.disabled ? <Fragment /> : <Fragment> (<Link onClick={() => this.setState({ isEditingEmailAddress: true })}>change</Link>)</Fragment>}</Fragment>
										}
									</EntryRow>
									<EntryRow label="White List IPs"
										title={"Comma separated list of Individual IPs and/or CIDR ranges that this user may login from.\nMulti-factor authentication (MFA/DUO) will not be required.\nUser may not login from other IPs."} // use curly braces for \n
									>
										{this.state.isEditingWhiteListIps ?
											<Input type="text" maxLength={8000}
												className="email-input"
												name="WhiteListIps"
												value={editingUserEntityProfile.WhiteListIps || ""}
												previousValue={previousEditingUserEntityProfile.WhiteListIps || ""}
												showDirty={isExisting}
												onChange={this.handleDataChange}
												autoFocus={this.state.isEditingWhiteListIps}
												disabled={this.props.disabled}
											/>
											:
											<Fragment><span>{editingUserEntityProfile.WhiteListIps}</span>
												{this.props.disabled ? <Fragment /> : <Fragment> (<Link onClick={() => this.setState({ isEditingWhiteListIps: true })}>{editingUserEntityProfile.WhiteListIps ? "change" : "add"}</Link>)</Fragment>}</Fragment>
										}
									</EntryRow>
									<EntryRow label="Enabled?">
										<span>{editingUserEntityProfile.Enabled === false ? "Disabled" : "Enabled"}</span>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that give this user access to features.">
						<legend>Usable Roles</legend>
						<div className="role-select-container">
							<ComboBox
								name="UsableRoleNames"
								value={(editingUserEntityProfile.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								previousValue={(previousEditingUserEntityProfile.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								isClearable={false}
								isMulti={true}
								isSearchable={true}
								closeMenuOnSelect={false}
								backspaceRemovesValue={true}
								onChange={this.handleDataChange}
								showDirty={isExisting}
								disabled={this.props.disabled || !(canChangeEntityUsers || !isExisting) || isOwnAccount}
							/>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that this user can assign to other users, if they have permission to assign roles.">
						<legend>Assignable Roles</legend>
						<div className="role-select-container">
							<ComboBox
								name="AssignableRoleNames"
								value={(editingUserEntityProfile.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								previousValue={(previousEditingUserEntityProfile.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
								isClearable={false}
								isMulti={true}
								isSearchable={true}
								closeMenuOnSelect={false}
								backspaceRemovesValue={true}
								onChange={this.handleDataChange}
								showDirty={isExisting}
								disabled={this.props.disabled || !(canChangeEntityUsers || !isExisting) || isOwnAccount}
							/>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	toggleState(stateName) {
		this.setState({ [stateName]: !this.state[stateName] });
	}

	getDomainOptions() {
		if (this.state.domainOptions)
			return this.state.domainOptions;

		if (!this.state.isGettingDomainOptions && this.state.isExisting === false) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingDomainOptions: true }));

			this.apiService.getDomains("",
				(response) => {
					const domainNames = ["", ...response.data.DomainNames, ...response.data.VirtualDomainNames];
					const domainOptions = domainNames.map(domainName => { return { value: domainName, label: domainName } });

					this.setState({
						isGettingDomainOptions: false,
						domainOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingDomainOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers(this.state.editingUserEntityProfile.DomainName, inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					return {
						value: domainUser.Username,
						label: `${domainUser.FirstName || ""} ${domainUser.LastName || ""} (${domainUser.Username})`,
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
			}
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);
		const { data } = e.target;

		let values = {
			[name]: value
		};

		if (name === "DomainName") {
			values = {
				DomainUsername: null,
				EmailAddress: null,
				Username: null,
				FirstName: null,
				LastName: null,
				...values
			};
		}
		else if (data && name === "Username") {
			values = data;
		}

		if (["EmailAddress", "WhiteListIps"].includes(name)) {
			values = {
				IsUpdated: true, // Let the server know that the user record needs to be updated
				...values
			};
		}

		const editingUserEntityProfile = {
			...this.state.editingUserEntityProfile,
			...values
		};

		this.setState({ editingUserEntityProfile });
	}

	setUsableRoleNames(roleNames) {
		this.handleDataChange({ target: { name: "UsableRoleNames", value: roleNames } });
	}

	setAssignableRoleNames(roleNames) {
		this.handleDataChange({ target: { name: "AssignableRoleNames", value: roleNames } });
	}

	save() {
		if (this.props.setUserEntityProfile(this.state.editingUserEntityProfile, this.state.editingUserEntityProfileDomainUsername))
			this.props.closeModal();
	}
}