import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./EntityLinkList.scss";

export default class EntityList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			lastValidValue: null
		};
	}

	render() {
		let { entities, urlBase } = this.props;

		const entityListItems = entities ? entities.map((entity, key) => {
			const entityUrl = `${urlBase}${entity.Guid}`;
			return (<li key={key}>
				<Link to={entityUrl}>{entity.Name}</Link>
				{entity.Tags && entity.Tags.length > 0 && this.props.showTags !== false ?
					<div className="tag-list">
						{entity.Tags.map(t => <Fragment key={t}><span>{t}</span></Fragment>)}
					</div>
					: <Fragment />}
			</li>);
		}) : null;

		return (
			<Fragment>
				<ul>{entityListItems}</ul>
			</Fragment>
		);
	}
}
