import React from "react";
import "./FileList.scss";

export default class FileList extends React.Component {
	render() {
		return (
			<div className="FileList">
				{this.props.children}
			</div>
		);
	}
}
