import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import PageOverlay from "../../Shared/PageOverlay";
import Layout from "../../Layout/Layout";
import { UserContext } from "../../Contexts/UserContext";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import queryString from "query-string"
import Input from "../../Shared/Input"
import EntryRow from "../../Shared/EntryRow"
import Select from "../../Shared/Select";
import _ from "lodash";
import ViasatLogoSpinner from "../../Shared/ViasatLogoSpinner";
import "./Login.scss";

export default class Login extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		const queryStrings = queryString.parse(window.location.search);
		const loginDefaultDomain = (window.localStorage ? window.localStorage.getItem("loginDefaultDomain") || "" : "");

		this.state = {
			isLoadingPassive: false,
			errorMessage: null,
			isLoginSuccess: false,
			formData: {
				rememberMe: true,
				domainName: loginDefaultDomain,
				mfaAuthType: "Auto"
			},
			loginDefaultDomain,
			isWaitingForMfaPush: false,
			mfaPromise: null,
			returnUrl: queryStrings["returnUrl"]
		};

		// Bind all functions called from the DOM
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleWebUserSession = this.handleWebUserSession.bind(this);
		this.handleCancelMfa = this.handleCancelMfa.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	handleChange(e) {
		const { name, value, type, checked } = e.target

		const formData = {
			...(this.state.formData),
			...{ [name]: type === "checkbox" ? checked : value }
		};

		this.setState({ errorMessage: null, formData: formData });
	}

	handleSubmit() {
		this.setState({ isLoadingPassive: true });

		const formData = { ...this.state.formData };
		formData.domainUsername = `${formData.domainName || ""}\\${formData.username || ""}`;
		delete formData.domainName;
		delete formData.username;

		this.apiService.createWebUserSession(formData,
			(response) => {
				if (response.data.WebUserSession) {
					this.handleWebUserSession(response.data.WebUserSession);

					return;
				}

				if (response.data.RequiresMfa) {
					if (this.state.formData.mfaAuthType === "Sms") {
						this.setState({ isLoadingPassive: false, formData: { ...this.state.formData, mfaAuthType: "Passcode" } });
						return;
					}

					if (!response.data.MfaSessionToken) {
						this.setState({ isLoadingPassive: false });
						return;
					}

					this.setState({ isWaitingForMfaPush: true });
					formData.mfaSessionToken = response.data.MfaSessionToken;
					const mfaPromise = this.apiService.createWebUserSession(formData,
						(response2) => {
							if (this.state.mfaPromise !== mfaPromise)
								return; // Ignore, this is not the last MFA API request

							this.setState({ mfaPromise: null });
							this.handleWebUserSession(response2.data.WebUserSession);
						},
						(error2) => {
							if (this.state.mfaPromise !== mfaPromise)
								return; // Ignore, this is not the last MFA API request

							console.error(error2);
							this.setState({ mfaPromise: null, isLoadingPassive: false, isWaitingForMfaPush: false });
							if (error2.response && error2.response.data && error2.response.data.ErrorMessage && error2.response.status === 401) {
								// Handle failed login 401 responses here instead of the general API error handler (required if render contains a redirect)
								this.setState({ errorMessage: `Error logging in: ${error2.response.data.ErrorMessage}` });
								return;
							}
							handleApiError(error2);
						});

					this.setState({ mfaPromise });
				}
			},
			(error) => {
				this.setState({ isLoadingPassive: false, isWaitingForMfaPush: false });
				if (error.response && error.response.data && error.response.data.ErrorMessage && error.response.status === 401) {
					// Handle failed login 401 responses here instead of the general API error handler (required if render contains a redirect)
					this.setState({ errorMessage: `Error logging in: ${error.response.data.ErrorMessage}` });
					return;
				}
				handleApiError(error);
			}
		);

		//_.delay(this.apiService.ping, 100); // Get the server to warm up an extra API instance to prevent potential cold starts
	}

	handleCancelMfa() {
		this.setState({ mfaPromise: null, isLoadingPassive: false, isWaitingForMfaPush: false });
	}

	handleWebUserSession(webUserSession) {
		if (window.localStorage) {
			// Save the domain name for the future login events
			window.localStorage.setItem("loginDefaultDomain", this.state.formData.domainName);
			window.localStorage.setItem("loginRememberMe", this.state.formData.rememberMe ? "true" : null);
		}

		this.context.setWebUserSession(webUserSession);

		if (this.state.returnUrl && this.state.returnUrl.toLowerCase().startsWith(this.props.config.apiBaseUrl.toLowerCase())) {
			// The return URL is an API link (maybe a download?)
			_.delay(() => {
				this.setState({ returnUrl: "/", isLoadingPassive: false, isWaitingForMfaPush: false, isLoginSuccess: true });
			}, 500); // make sure the API call is initiated then redirect to the home screen

			window.location.href = this.state.returnUrl;
		}
		else {
			this.setState({ isLoadingPassive: false, isWaitingForMfaPush: false, isLoginSuccess: true });
		}
	}

	onKeyPress(e) {
		if (e && e.key === "Enter") {
			this.handleSubmit();
			e.preventDefault();
			return false;
		}
	}

	render() {
		if (this.state.isLoginSuccess) {
			return (<Redirect to={this.state.returnUrl || "/"} />); // default redirect path
		}

		const isViasatDomain = (this.state.formData.domainName ?? this.state.loginDefaultDomain ?? "").toLowerCase() === "viasat";

		return (
			<Layout config={this.props.config} subtitle="Login" requireWebUserSession={false}>
				<PageOverlay showOverlay={this.state.isLoadingPassive}>
					<div className="login-status-popup">
						<ViasatLogoSpinner size="75px" />
						<h4>Logging in...</h4>
						{this.state.isWaitingForMfaPush ? (<div>
							<h4>Sending a push to your device...</h4>
							<button style={{ marginBottom: "15px" }} onClick={this.handleCancelMfa}>Cancel</button>
						</div>) : <Fragment />}
					</div>
				</PageOverlay>
				<h1>Login</h1>
				{this.state.returnUrl ? (<p>Please log in to continue.</p>) : (<Fragment />)}
				<div className="entry-table-container login-form" onKeyPress={this.onKeyPress}>
					<table>
						<tbody>
							<EntryRow label="Domain name">
								<Input type="text" maxLength={200}
									name="domainName"
									value={this.state.formData.domainName || ""}
									placeholder="domain" autoFocus={!this.state.loginDefaultDomain}
									onChange={this.handleChange} />
							</EntryRow>
							<EntryRow label="Username">
								<Input type="text" maxLength={200}
									name="username"
									value={this.state.formData.username || ""}
									placeholder="username"
									autoFocus={!!this.state.loginDefaultDomain}
									title="Domain account username."
									onChange={this.handleChange} />
							</EntryRow>
							<EntryRow label="Password">
								<Input type="password"
									name="password"
									value={this.state.formData.password || ""}
									placeholder="password"
									title="Domain account password."
									onChange={this.handleChange} />
							</EntryRow>
							<EntryRow label="Authentication">
								<Select
									name="mfaAuthType"
									value={this.state.formData.mfaAuthType || "Auto"}
									title="Domain account password."
									options={[{ label: "Automatic", value: "Auto" }, { label: "Push", value: "Push" }, { label: "Phone", value: "Phone" }, { label: "SMS", value: "Sms" }, { label: "Passcode", value: "Passcode" }]}
									onChange={this.handleChange} />
							</EntryRow>
							{this.state.formData.mfaAuthType === "Passcode" ? (
								<EntryRow label="Authentication Passcode">
									<Input type="text" maxLength={50}
										name="mfaAuthToken"
										value={this.state.formData.mfaAuthToken || ""}
										placeholder="authentication passcode"
										autoFocus={this.state.formData.mfaAuthType === "Passcode"}
										title="DUO authentication passcode."
										onChange={this.handleChange} />
								</EntryRow>
							) : <Fragment />}
							<EntryRow label="&nbsp;">
								<label title="Stay logged in, even if you restart your browser.">
									<input type="checkbox"
										name="rememberMe"
										checked={this.state.formData.rememberMe}
										onChange={this.handleChange} /> Stay logged in</label>
							</EntryRow>
							<tr>
								<td colSpan="2">&nbsp;</td>
							</tr>
							<tr>
								<td>&nbsp;</td>
								<td className="info">
									{this.state.errorMessage ? (
										<Fragment>
											<p><span className="error-message">{this.state.errorMessage}</span></p>
											{isViasatDomain
												?
												<Fragment>
													<p>The OSQRE password is the same password used for your corporate Viasat account. If you forgot your password or need to update it go to <a href="https://signin.viasat.com/" target="_blank" rel="noopener noreferrer" title="Viasat Single Sign-on portal">signin.viasat.com</a>.</p>
													<p>If you are a new user or have not accessed your account in the last year email <a href="mailto:OSQRE@viasat.com" title="OSQRE support email">OSQRE@viasat.com</a> or slack <a href="https://viasat.enterprise.slack.com/archives/C039LB1TF7T" target="_blank" rel="noopener noreferrer" title="OSQRE support Slack channel">#osqre-support</a> with the entity (or entities) that you need access to.</p>
												</Fragment>
												:
												<Fragment>
													<p>If you forgot your password or need to update it (expires every 90 days) go to <a href="https://partnerlogin.viasat.com" target="_blank" rel="noopener noreferrer" title="Viasat Single Sign-on portal">partnerlogin.viasat.com</a>.</p>
													<p>If you have not used your account in over 90 days, your account may have been deactivated. To have it reactivated, reach out to Viasat IT at: <a href="tel:1-760-476-2345" title="Viasat IT support phone number">1-760-476-2345</a></p>
												</Fragment>
											}
										</Fragment>) : (<Fragment />)}
									<button className="primary" onClick={this.handleSubmit}>Log In</button>
								</td>
							</tr>
							<tr>
								<td>&nbsp;</td>
								<td>
									<br />
									<a href={isViasatDomain ? "https://signin.viasat.com" : "https://partnerlogin.viasat.com"} target="_blank" rel="noopener noreferrer" title="Reset or change your password using the Viasat Single Sign-on portal">Forgot your password?</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Layout >
		);
	}
}
