import React, { Fragment } from "react";
import moment from "moment";
import PathBreadcrumbs from "./PathBreadcrumbs";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import PageOverlay from "../../Shared/PageOverlay";
import { FileIcon } from '@drawbotics/file-icons';
import '@drawbotics/file-icons/dist/style.css';
import FolderIcon from "@material-ui/icons/FolderOutlined";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import FileMetadataEditModal from "./FileMetadataEditModal"
import FilePermalinksModal from "./FilePermalinksModal"
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";
import * as Papa from "papaparse";
import { truncate } from "../../Helpers/SharedFunctions";
import "../../Shared/EntityLinkList.scss"; // for tag-list style

export default class FilesDisplay extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.ENTITY_NAME_INDEX = 1;
		this.ENTITY_COLUMN_COUNT = 2;

		const uniqueName = Math.random().toString(36).substring(2);
		this.filesDisplayRowContextMenuName = `FilesDisplayRowContextMenu_${uniqueName}`;
		this.metadataDisplayLengthLimit = 100;

		this.state = {
			directories: [],
			files: [],
			rowObjects: [],
			isLoading: false,
			isLoadingPassive: false,
			isSingleFileContext: false,
			lastPath: null,
			dirColSpan: this.getDirColSpan(this.props.allFileTypeMetadataFields),
			showFileMetadataEditModal: false,
			showPermalinksModal: false,
			editingFile: null,
			permalinksFileData: null
		};

		this.timeZoneMessage = "Shown in browser's local time zone.";

		this.handleChange = this.handleChange.bind(this);
		this.setPath = this.setPath.bind(this);
		this.getHeaderRowLabels = this.getHeaderRowLabels.bind(this);
		this.getRowCells = this.getRowCells.bind(this);
		this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
		this.downloadSelectedRowObjects = this.downloadSelectedRowObjects.bind(this);
		this.deleteSelectedRowObjects = this.deleteSelectedRowObjects.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.downloadFiles = this.downloadFiles.bind(this);
		this.changeMetadataSelectedRowObjects = this.changeMetadataSelectedRowObjects.bind(this);
		this.setFileMetadata = this.setFileMetadata.bind(this);
		this.closeFileMetadataEditModal = this.closeFileMetadataEditModal.bind(this);
		this.getPermalinks = this.getSelectedFileData.bind(this);
		this.showPermalinks = this.showPermalinks.bind(this);
		this.closePermalinksModal = this.closePermalinksModal.bind(this);

		this.apiService = useApiService(props.apiBaseUrl);
	}

	componentDidMount() {
		this.handleSearchCriteriaChange();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.searchCriteria !== this.props.searchCriteria
			|| prevProps.path !== this.props.path
			|| prevProps.orderBy !== this.props.orderBy) {
			this.handleSearchCriteriaChange(true);
		}
	}

	render() {
		if (!this.props.searchCriteria)
			return (<Fragment />);

		const me = this;

		return (
			<Fragment>
				<div className="files-display">
					<fieldset>
						<legend>{this.props.searchCriteria.view === "directories" ? (<PathBreadcrumbs label="Path" baseRoute={this.props.baseRoute} baseRouteAlias={this.props.entityDetails.Name} path={this.props.path} setPath={this.setPath} isEnabled={!this.state.isLoading} />) : (<span>Files</span>)}</legend>
						<SelecTable className="files-display-table-container"
							enableSelectAll={true}
							selectMode="multi"
							rowObjects={this.state.isLoading ? [] : this.state.rowObjects}
							overrideTableRows={this.getOverrideTableRows(this.state.rowObjects)}
							columns={this.getHeaderRowLabels()}
							orderBy={this.props.orderBy}
							setOrderBy={this.props.setOrderBy}
							getRowCells={this.getRowCells}
							getRowKey={this.getRowKey}
							onDoubleClick={this.onRowDoubleClick}
							contextMenuId={this.filesDisplayRowContextMenuName}
						/>
						<PageOverlay showOverlay={this.state.isLoadingPassive} />
						<LoadingWrapper isLoading={this.state.isLoading}>
							<ContextMenu id={this.filesDisplayRowContextMenuName} onShow={(event) => _.defer(() => {
								const { selectedDirectoryNodeNames, selectedFileGuids } = me.getSelectedDirecoryAndFileKeys(event.detail.data.selectedRowKeys);
								me.setState({
									isSingleFileContext: selectedDirectoryNodeNames.length === 0 && selectedFileGuids.length === 1
								});
							})}>
								<MenuItem onClick={(e, data) => _.defer(() => { this.downloadSelectedRowObjects(data.selectedRowKeys); })}>Download</MenuItem>
								<MenuItem onClick={(e, data) => _.defer(() => { this.downloadMetadataCsvForSelectedRowObjects(data.selectedRowKeys); })}>Download Metadata CSV</MenuItem>
								{this.state.files && this.state.files.length ?
									<MenuItem onClick={(e, data) => _.defer(() => { this.showPermalinks(data.selectedRowKeys); })}>Show Permalink{this.state.isSingleFileContext ? "" : "s"}</MenuItem>
									: <Fragment />}
								{/*
								<MenuItem onClick={(e, data) => _.defer(() => { this.showRowObjectDetails(data.rowObject); })}>Show Details</MenuItem>
								*/}
								<MenuItem divider />
								{this.state.isSingleFileContext && this.context.hasPermission(this.props.entityDetails.Guid, "Change Entity File Metadata")
									? <MenuItem onClick={(e, data) => _.defer(() => { this.changeMetadataSelectedRowObjects(data.selectedRowKeys); })}>Change Metadata</MenuItem>
									: <Fragment />}
								<MenuItem onClick={(e, data) => _.defer(() => { this.deleteSelectedRowObjects(data.selectedRowKeys); })}
									disabled={!this.context.hasPermission(this.props.entityDetails.Guid, "Delete Entity Files")}>Delete</MenuItem>
							</ContextMenu>
							<FileMetadataEditModal
								isOpen={this.state.showFileMetadataEditModal}
								closeModal={this.closeFileMetadataEditModal}
								fileTypeDetailsMap={this.props.fileTypeDetailsMap}
								editingFile={this.state.editingFile}
								setFileMetadata={this.setFileMetadata}
							/>
							<FilePermalinksModal
								isOpen={this.state.showPermalinksModal}
								closeModal={this.closePermalinksModal}
								permalinksFileData={this.state.permalinksFileData}
								apiService={this.apiService}
							/>
						</LoadingWrapper>
					</fieldset>
				</div>
			</Fragment>
		);
	}

	downloadSelectedRowObjects(selectedRowKeys) {
		const { selectedDirectoryNodeNames, selectedFileGuids } = this.getSelectedDirecoryAndFileKeys(selectedRowKeys);

		if (selectedDirectoryNodeNames.length === 0 && selectedFileGuids.length === 0) {
			return;
		}
		if (selectedDirectoryNodeNames.length === 0 && selectedFileGuids.length === 1) {
			// Single file download
			this.downloadFile(selectedFileGuids[0]);
		}
		else {
			// Directory and multiple file download
			this.downloadFiles(selectedDirectoryNodeNames, selectedFileGuids);
		}
	}

	downloadMetadataCsvForSelectedRowObjects(selectedRowKeys) {
		const { selectedDirectoryNodeNames, selectedFileGuids } = this.getSelectedDirecoryAndFileKeys(selectedRowKeys);

		if (selectedDirectoryNodeNames.length !== 0) {
			alert("Metadata may only be downloaded for files, not directories.");
			return;
		}

		if (selectedFileGuids.length === 0) {
			alert("No files selected to download metadata.");
			return;
		}

		const fileData = this.getSelectedFileData(selectedRowKeys);

		this.downloadMetadataCsv(fileData);
	}

	showPermalinks(selectedRowKeys) {
		const selectedFileData = this.getSelectedFileData(selectedRowKeys)
		if (!selectedFileData || selectedFileData.length === 0) {
			alert("No files were selected.")
			return;
		}

		this.setState({ permalinksFileData: selectedFileData, showPermalinksModal: true });
	}

	getSelectedFileData(selectedRowKeys) {
		const { selectedFileGuids } = this.getSelectedDirecoryAndFileKeys(selectedRowKeys);

		if (selectedFileGuids.length === 0) {
			return null;
		}

		const selectedFileGuidsSet = new Set(selectedFileGuids);
		const selectedFiles = this.state.files.filter((file) => selectedFileGuidsSet.has(file.FileGuid));

		return selectedFiles;
	}

	downloadFile(fileGuid) {
		this.setState({ isLoadingPassive: true });

		this.apiService.downloadFile(this.props.entityDetails.Guid, fileGuid);

		_.delay(() => { this.setState({ isLoadingPassive: false }); }, 1000); // Hide the loading indicator after a short time since we have no way of knowing when the download actually starts.
	}

	downloadFiles(directoryNodeNames, fileGuids) {
		const directoryPaths = directoryNodeNames.map((directoryNodeName) => `${this.getBasePath()}/${directoryNodeName}/`);

		this.setState({ isLoadingPassive: true });
		if (this.props.streamZipDownloads) {
			// Stream the zip file through the API. Faster method that begins immediately no matter how large the resulting zip file is. File is streamed as it is created.
			this.apiService.downloadFilesStream(this.props.entityDetails.Guid, directoryPaths, fileGuids);

			_.delay(() => { this.setState({ isLoadingPassive: false }); }, 1000); // Hide the loading indicator after a short time since we have no way of knowing when the download actually starts.
		}
		else {
			const me = this;
			// Zip then download from S3. Slower but required when API hosted by lambda. May timeout if the requested content is too large.
			this.apiService.getDownloadFilesUrl(this.props.entityDetails.Guid, directoryPaths, fileGuids,
				(response) => {
					this.apiService.downloadFileUrl(response.data.DownloadUrl);
					_.delay(() => { me.setState({ isLoadingPassive: false }); }, 1000); // Hide the loading indicator after a short time since we have no way of knowing when the download actually starts.
				},
				(error) => {
					handleApiError(error);
					this.setState({ isLoadingPassive: false });
				});
		}
	}

	downloadMetadataCsv(fileData) {
		const cleanEntityName = this.props.entityDetails.Name.replace(/([^-a-z0-9 _]+)/gi, "");
		const filename = `${cleanEntityName} - OSQRE Metadata - ${moment(new Date()).format().replaceAll(":", "")}.csv`;
		const headerRowLabels = this.getHeaderRowLabels("fieldName");
		headerRowLabels.shift(); // remove the first column which is always empty
		const METADATA_FIELD_SUFFIX = "_Metadata";
		const columnNames = headerRowLabels.map((headerRow, index) => `${((typeof headerRow === "object") ? headerRow.fieldName : headerRow)}${(index >= this.baseHeaderRowLabelCount ? METADATA_FIELD_SUFFIX : "")}`);
		const cleanFileData = fileData.map((data) => {
			const metadataFields = Object.fromEntries(Object.entries((data.Metadata ?? {})).map(([key, value]) => [`${key}${METADATA_FIELD_SUFFIX}`, value]));
			const cleanData = { ...metadataFields, ...data };
			delete cleanData.Metadata;
			return cleanData;
		});

		const fileContent = Papa.unparse(cleanFileData, { header: true, quotes: true, quoteChar: '"', escapeChar: '"', delimiter: ",", newline: "\r\n", columns: columnNames });

		var blob = new Blob([fileContent], { type: 'text/csv' });
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename);
		}
		else {
			const el = window.document.createElement('a');
			el.href = window.URL.createObjectURL(blob);
			el.download = filename;
			document.body.appendChild(el);
			el.click();
			document.body.removeChild(el);
		}
	}

	changeMetadataSelectedRowObjects(selectedRowKeys) {
		const { selectedDirectoryNodeNames, selectedFileGuids } = this.getSelectedDirecoryAndFileKeys(selectedRowKeys);

		if (selectedDirectoryNodeNames.length !== 0 || selectedFileGuids.length !== 1) {
			return; // We only support changing metadata for a single file
		}

		const selectedFileGuid = selectedFileGuids[0];
		const filteredFiles = this.state.files.filter((file) => file.FileGuid === selectedFileGuid);
		if (filteredFiles.length !== 1)
			return;

		this.setState({ editingFile: filteredFiles[0], showFileMetadataEditModal: true });
	}

	setFileMetadata(file) {
		this.setState({ isLoadingPassive: true });

		this.apiService.setFileMetadata(file.EntityGuid, file.FileGuid, file.Metadata,
			(response) => {
				this.closeFileMetadataEditModal();
				this.handleSearchCriteriaChange(true); // isLoadingPassive will be set to false when this API call is complete
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			});
	}

	deleteSelectedRowObjects(selectedRowKeys) {
		const { selectedDirectoryNodeNames, selectedFileGuids } = this.getSelectedDirecoryAndFileKeys(selectedRowKeys);

		if (selectedDirectoryNodeNames.length === 0 && selectedFileGuids.length === 0) {
			return;
		}

		const folderLabel = selectedDirectoryNodeNames.length > 0 ? ` folder${selectedDirectoryNodeNames.length > 1 ? "s" : ""}` : "";
		const fileLabel = selectedFileGuids.length > 0 ? ` file${selectedFileGuids.length > 1 ? "s" : ""}` : "";
		const and = (folderLabel && fileLabel) ? " and" : "";

		if (window.confirm(`Permanently delete selected${folderLabel}${and}${fileLabel}?`)) {
			this.deleteFiles(selectedDirectoryNodeNames, selectedFileGuids);
		}
	}

	deleteFiles(directoryNodeNames, fileGuids) {
		const directoryPaths = directoryNodeNames.map((directoryNodeName) => `${this.getBasePath()}/${directoryNodeName}/`);
		this.setState({ isLoadingPassive: true });
		this.apiService.deleteFiles(this.props.entityDetails.Guid, directoryPaths, fileGuids,
			(response) => {
				this.handleSearchCriteriaChange(true); // isLoadingPassive will be set to false when this API call is complete
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			});
	}

	getBasePath() {
		return this.props.path === "/" ? "" : this.props.path;
	}

	getSelectedDirecoryAndFileKeys(selectedRowKeys) {
		const allRowKeys = Object.getOwnPropertyNames(selectedRowKeys);
		const selectedDirectoryNodeNames = allRowKeys.filter((k) => k.startsWith("d") && k !== "d_..").map((k) => k.substring(2));
		const selectedFileGuids = allRowKeys.filter((k) => k.startsWith("f")).map((k) => k.substring(2));

		return { selectedDirectoryNodeNames, selectedFileGuids };
	}

	handleChange(e) {
		const { name, value } = extractDomElement(e.target);

		this.setState({ [name]: value });
	}

	handleSearchCriteriaChange(useLoadingPassive) {
		if (this.props.searchCriteria === null || this.props.searchCriteria === undefined) {
			this.setState({ directories: [], files: [], rowObjects: [] });
			return;
		}

		this.setState({ [`isLoading${useLoadingPassive ? "Passive" : ""}`]: true });

		if (this.activeGetDirectoryListing) {
			this.activeGetDirectoryListing.cancel();;
		}

		const flatDirectory = "**";
		if (this.props.searchCriteria.view === "flat" && this.props.path !== flatDirectory) {
			this.setState({ lastPath: this.props.path });
			this.setPath(flatDirectory);
			return;
		}

		if (this.props.searchCriteria.view === "directories" && this.props.path === flatDirectory) {
			this.setPath(this.state.lastPath !== null ? this.state.lastPath : "");
			return;
		}

		this.activeGetDirectoryListing = this.apiService.getDirectoryListing(
			this.props.entityDetails.Guid, this.props.path, { ...this.props.searchCriteria, orderBy: this.props.orderBy, page: 1, pageSize: 1000 }, // No paging support yet
			(response) => {
				// fix the date/time values
				for (let i = 0; i < response.data.Files.length; i++) {
					const file = response.data.Files[i];
					file.UploadDateTime = this.convertToDateTime(file.UploadDateTime);
					file.DownloadDateTime = this.convertToDateTime(file.DownloadDateTime);
				}

				const rowObjects = this.getRowObjects(response.data.Directories, response.data.Files, this.props.searchCriteria.view);

				this.setState({
					[`isLoading${useLoadingPassive ? "Passive" : ""}`]: false,
					directories: response.data.Directories,
					files: response.data.Files,
					rowObjects
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ [`isLoading${useLoadingPassive ? "Passive" : ""}`]: false });
			}
		);
	}

	getRowObjects(directories, files, view) {
		const rowObjects = [];
		if (view === "directories") {
			if (this.props.path !== "")
				rowObjects.push({ d: { DirectoryName: ".." } });

			for (let i = 0; i < directories.length; i++) {
				rowObjects.push({ d: directories[i] })
			}
		}

		for (let i = 0; i < files.length; i++) {
			rowObjects.push({ f: files[i] })
		}

		return rowObjects;
	}

	getHeaderRowLabels(type) {
		const baseHeaderRows = [
			{ displayName: "Entity", fieldName: "EntityName", isSortable: true },
			{ displayName: "Entity Tags", fieldName: "EntityTags", isSortable: true },
			{ displayName: "Filename", fieldName: "Filename", isSortable: true },
			{ displayName: "File Type", fieldName: "FileTypeName", isSortable: false },
			{ displayName: "Size", className: "number", fieldName: "FileSize", isSortable: true },
			{ displayName: "Uploaded By", fieldName: "UploaderUsername", isSortable: true },
			{ displayName: "Upload Date/Time", fieldName: "UploadDateTime", isSortable: true },
			{ displayName: "Download Date/Time", fieldName: "DownloadDateTime", isSortable: true }
		];

		const headerRowLabels = [
			"",
			...baseHeaderRows,
			...(this.props.allFileTypeMetadataFields.map((metadataField) => (type === "fieldName" ? metadataField.FieldName : metadataField.DisplayName)))
		];

		this.baseHeaderRowLabelCount = baseHeaderRows.length;

		if (!this.props.showEntityName && type !== "fieldName") {
			headerRowLabels.splice(this.ENTITY_NAME_INDEX, this.ENTITY_COLUMN_COUNT);
		}

		return headerRowLabels;
	}

	getRowCells(rowObject, rowKey) {
		if (rowObject.d) {
			const dir = rowObject.d;
			return [
				(<td key="DirIcon"><FolderIcon /></td>),
				(<td key="DirectoryName">{dir.DirectoryName}</td>),
				(<td key="DirSpacer" colSpan={this.state.dirColSpan}>&nbsp;</td>)
			];
		}

		if (rowObject.f) {
			const file = rowObject.f;
			const rowCells = [
				// add _123 to keys to help ensure uniqueness
				(<td key="FileIcon_123"><FileIcon filename={file.Filename.toLowerCase()} /></td>),
				(<td key="EntityName_123">{file.EntityName}</td>),
				(<td key="EntityTags_123"><div className="tag-list">{file.EntityTags?.map(t => { return (<span key={t} >{t}</span>) })}</div></td>),
				(<td key="Filename_123">{file.Filename}</td>),
				(<td key="FileTypeName_123">{file.FileTypeName}</td>),
				(<td key="FileSize_123" className="number">{file.FileSize.toLocaleString()}</td>),
				(<td key="UploaderUsername_123">{file.UploaderUsername}</td>),
				(<td key="UploadDateTime_123" title={this.timeZoneMessage}>{this.formatDateTime(file.UploadDateTime)}</td>),
				(<td key="DownloadDateTime_123" title={this.timeZoneMessage}>{file.DownloadDateTime ? this.formatDateTime(file.DownloadDateTime) : "-"}</td>),
				...this.props.allFileTypeMetadataFields.map((metadataField) => {
					const metadataValue = file.Metadata[metadataField.FieldName] ?? "";
					return (<td
						key={metadataField.FieldName}
						title={metadataValue.length <= this.metadataDisplayLengthLimit ? null : metadataValue}
					>{truncate(metadataValue, this.metadataDisplayLengthLimit)}</td>);
				})
			];

			if (!this.props.showEntityName) {
				rowCells.splice(this.ENTITY_NAME_INDEX, this.ENTITY_COLUMN_COUNT);
			}

			return rowCells;
		}
	}

	getDirColSpan(allFileTypeMetadataFields) {
		return 6 + (this.props.showEntityName ? 1 : 0) + allFileTypeMetadataFields.length - 1; // 6 static columns
	}

	onRowDoubleClick(e, rowObject, rowKey) {
		if (rowObject.d) {
			this.setPath(`${this.getBasePath()}/${rowObject.d.DirectoryName}`);
		}
		else if (rowObject.f) {
			this.downloadFile(rowObject.f.FileGuid);
		}
	}

	getOverrideTableRows(rowObjects) {
		if ((rowObjects.length === 0 || (rowObjects.length === 1 && rowObjects[0].d && rowObjects[0].d.DirectoryName === "..")) && !this.state.isLoading) {
			const dirColSpan = this.state.dirColSpan;
			return [
				(<tr key="no-files-1" className="no-files"><td colSpan={dirColSpan}>&nbsp;</td></tr >),
				(<tr key="no-files-2" className="no-files"><td colSpan={2}>&nbsp;</td><td colSpan={dirColSpan - 2}>No files match the search criteria.</td></tr>),
				(<tr key="no-files-3" className="no-files"><td colSpan={dirColSpan}>&nbsp;</td></tr>)
			];
		}

		return null;
	}

	convertToDateTime(dateTimeString) {
		if (!dateTimeString)
			return null;

		if (!dateTimeString.endsWith("Z"))
			dateTimeString += "Z";

		return new Date(dateTimeString);
	}

	formatDateTime(dateTime) {
		return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
	}

	setPath(path) {
		let newRoute = this.props.baseRoute;
		if (path) {
			let cleanPath = path.replace(/^\/|\/$/g, ''); // remove leading and trailing slashes
			if (cleanPath.endsWith("/..")) {
				cleanPath = cleanPath.substring(0, cleanPath.lastIndexOf("/"));
				cleanPath = cleanPath.substring(0, cleanPath.lastIndexOf("/"));
			}
			newRoute += "/" + cleanPath;
		}

		this.props.setRoute(newRoute);
	}

	getRowKey(rowObject) {
		return rowObject.d ? `d_${rowObject.d.DirectoryName}` : `f_${rowObject.f.FileGuid}`;
	}

	closeFileMetadataEditModal() {
		this.setState({ showFileMetadataEditModal: false });
	}

	closePermalinksModal() {
		this.setState({ showPermalinksModal: false });
	}
}
