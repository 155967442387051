import React from "react";
import Link from "../../Shared/Link";

export default class FileManifestTemplateDownloadLink extends React.Component {
	constructor(props) {
		super(props);

		this.downloadFileManifestTemplate = this.downloadFileManifestTemplate.bind(this);
	}

	render() {
		return (
			<Link onClick={this.downloadFileManifestTemplate} title={`Download a template manifest that can be edited and uploaded on this screen.`}>File Manifest Template</Link>
		);
	}

	downloadFileManifestTemplate(e) {
		const filename = "OsqreFileManifest_Template.json";
		const data = JSON.stringify({
			Files: {
				"filename1.ext": {
					"FileTypeName": "SipPassFail", "Metadata": { "PartNumber": "123456789", "SerialNumber": "9876543210" }
				},
				"filename2.ext": {
					"FileTypeName": "OtherFileType", "Metadata": { "MetadataField1": "Value 1", "MetadataField2": "Value 1" }
				}
			}
		}, null, 4);
		var blob = new Blob([data], { type: 'text/csv' });
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename);
		}
		else {
			const el = window.document.createElement('a');
			el.href = window.URL.createObjectURL(blob);
			el.download = filename;
			document.body.appendChild(el);
			el.click();
			document.body.removeChild(el);
		}
	}
}