import React from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import Checkbox from "../../Shared/Checkbox";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class FilePermalinksModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showFilenames: true,
			showAsLinks: false,
			areInteractiveLinks: true,
			areViewLinks: false
		};

		this.handleDataChange = this.handleDataChange.bind(this);
	}

	render() {
		const permalinksFileData = this.props.permalinksFileData ?? [];
		const plural = permalinksFileData.length === 1 ? "" : "s";
		const getApiDownloadUrl = this.props.apiService?.getApiDownloadUrl;

		let permalinkChildren = null;
		if (this.state.showAsLinks) {
			permalinkChildren = permalinksFileData.map((permalink) => {
				return (
					<li key={permalink.FileGuid}>
						<a rel="noopener noreferrer" target="_blank" href={getApiDownloadUrl(permalink.EntityGuid, permalink.FileGuid, this.state.areInteractiveLinks, this.state.areViewLinks)}>{permalink.Filename}</a>
					</li>
				);
			});
		}
		else {
			permalinkChildren = permalinksFileData.map((permalink) => {
				return (
					<li key={permalink.FileGuid}>
						{this.state.showAsLinks ? (
							<a rel="noopener noreferrer" target="_blank" href={getApiDownloadUrl(permalink.EntityGuid, permalink.FileGuid, this.state.areInteractiveLinks, this.state.areViewLinks)}>{permalink.Filename}</a>
						)
							: (
								<span>{this.state.showFilenames ? `${permalink.Filename}: ` : ""}{getApiDownloadUrl(permalink.EntityGuid, permalink.FileGuid, this.state.areInteractiveLinks, this.state.areViewLinks)}</span>
							)}
					</li>
				);
			})
		}

		return (
			<BetterDialog title={`File Permalink${plural}`} open={this.props.isOpen}>
				<p>A Permalink may be used by another user to download a file as long as that user has permission. If a user is not already logged in, they will be prompted to log in before the file can be downloaded.</p>
				<div className="permalink-container">
					<Checkbox
						name="showFilenames"
						value={(this.state.showAsLinks ?? false) ? true : this.state.showFilenames ?? false}
						onChange={this.handleDataChange}
						label={`Show filename${plural}`}
						disabled={this.state.showAsLinks ?? false}
					/>
					<Checkbox
						name="showAsLinks"
						value={this.state.showAsLinks ?? false}
						onChange={this.handleDataChange}
						label={`View as hyperlink${plural}`}
					/>
					<Checkbox
						name="areInteractiveLinks"
						value={this.state.areInteractiveLinks ?? false}
						onChange={this.handleDataChange}
						label={`Interactive link${plural}`}
						title="Interactive links will prompt a user to log in if these have not already. (recommended)"
					/>
					<Checkbox
						name="areViewLinks"
						value={this.state.areViewLinks ?? false}
						onChange={this.handleDataChange}
						label={`View in browser`}
						title="View in browser will display the file in the browser rather than downloading, if possible."
					/>
					<br />
					<br />
					<ul>
						{permalinkChildren}
					</ul>
				</div>
				<BetterDialogButtons>
					<button onClick={this.props.closeModal}>Close</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e, isBool) {
		const { name, value } = extractDomElement(e.target, isBool);

		this.setState({ [name]: value });
	}
}