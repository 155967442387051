import React, { Fragment } from "react";
import Layout from "../../Layout/Layout";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import { handleApiError } from "../../Shared/ErrorHandlers";
import UserPluginsSettings from "./UserPluginsSettings";
import PageOverlay from "../../Shared/PageOverlay";
import PassiveMessage from "../../Shared/PassiveMessage";
import _ from "lodash";
import { Prompt } from "react-router-dom";

export default class UserPlugins extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			isInitialized: false,
			isLoading: true,
			entity: null,
			userPlugins: {},
			previousUserPlugins: {},
			isDirty: false
		};

		this.leaveUnsavedMessage = "You have unsaved changes, are you sure you want to leave?";

		this.handleCloseSaveNotification = this.handleCloseSaveNotification.bind(this);
		this.setUserPlugins = this.setUserPlugins.bind(this);

		this.saveUserPlugins = this.saveUserPlugins.bind(this);
		this.onBeforeUnload = this.onBeforeUnload.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
		window.addEventListener('beforeunload', this.onBeforeUnload);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onBeforeUnload);
	}

	onBeforeUnload(e) {
		if (!this.state.isDirty)
			return;

		if (e)
			e.returnValue = this.leaveUnsavedMessage;

		return this.leaveUnsavedMessage;
	}

	render() {
		const action = "User Plugins / Notifications";
		const heading = `${this.state.entity ? this.state.entity.Name : ""} ${action}`;
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		return (
			<Layout config={this.props.config} subtitle={heading}>
				<PageOverlay showOverlay={this.state.isLoadingPassive} />
				<h1>{heading}</h1>
				<LoadingWrapper isLoading={this.state.isLoading}>
					{
						this.state.userPlugins.length === 0 ? (
							<div>
								<h4>No user configurable plugins available.</h4>
							</div>
						) : (<Fragment>
							<UserPluginsSettings
								userPlugins={this.state.userPlugins}
								previousUserPlugins={this.state.previousUserPlugins}
								setUserPlugins={this.setUserPlugins}
							/>
							<div className="button-bar">
								<button onClick={this.saveUserPlugins} title="Save all changes">Save</button>
							</div>
						</Fragment>)
					}
				</LoadingWrapper>
				<Prompt
					when={this.state.isDirty}
					message={(location, action) => this.leaveUnsavedMessage}
				/>
				<PassiveMessage
					open={this.state.openSaveNotification}
					onClose={this.handleCloseSaveNotification}
					variant="success"
					message="Record saved successfully!" />
			</Layout >
		);
	}

	initialize() {
		this.apiService.getOwnUserPlugins(this.props.match.params.entityGuid,
			(response) => {
				const userPlugins = this.getCleanUserPlugins(response.data.UserPlugins);

				this.setState({
					isLoading: false,
					isLoadingPassive: false,
					entity: response.data.Entity,
					userPlugins: userPlugins,
					previousUserPlugins: userPlugins
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);
		this.setState({ isInitialized: true });
	}

	getCleanUserPlugins(userPlugins) {
		if (userPlugins === null || userPlugins.length === 0)
			return [];

		return userPlugins.filter((userPlugin) => {
			if (userPlugin === null || userPlugin.PluginDefinition === null)
				return false;

			if (userPlugin.PluginDefinition.PluginName === null || userPlugin.PluginDefinition.PluginName === "")
				return false;

			return true;
		});
	}

	setUserPlugins(userPlugins) {
		this.setState({ userPlugins, isDirty: true });
	}

	saveUserPlugins() {
		this.setState({ isLoadingPassive: true, openSaveNotification: false });
		const entityGuid = this.state.entity.Guid;
		const userPlugins = this.state.userPlugins.map((userPlugin) => {
			const cleanUserPlugin = { ...userPlugin };
			delete cleanUserPlugin.PluginDefinition;

			return cleanUserPlugin;
		});

		this.apiService.setOwnUserPlugins(entityGuid, userPlugins,
			(response) => {
				// Force a refresh
				this.setState({
					openSaveNotification: true,
					isInitialized: false,
					isDirty: false
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoadingPassive: false });
			}
		);
	}

	handleCloseSaveNotification(e, reason) {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({ openSaveNotification: false });
	}
}
