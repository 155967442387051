import React, { Fragment } from "react";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import SettingDefinitionEditModal from "./SettingDefinitionEditModal";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import clsx from "clsx";
import _ from "lodash";

export default class SettingDefinitionsList extends React.Component {
	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.settingDefinitionsRowContextMenuName = `SettingDefinitionsRowContextMenu_${uniqueName}`;

		this.state = {
			selectedRowKeyList: [],
			showSettingDefinitionEditModal: false,
			showSettingDefinitionsShowJsonModal: false,
			editingSettingDefinition: null
		};

		this.tableColumns = [
			{ fieldName: "DisplayName", displayName: "Display Name" },
			{ fieldName: "FieldName", displayName: "Field Name" },
			{ fieldName: "UiWidget", displayName: "UI Widget" }
		];

		this.closeSettingDefinitionEditModal = this.closeSettingDefinitionEditModal.bind(this);
		this.closeSettingDefinitionsShowJsonModal = this.closeSettingDefinitionsShowJsonModal.bind(this);
		this.setSettingDefinition = this.setSettingDefinition.bind(this);
		this.setSettingDefinitions = this.setSettingDefinitions.bind(this);
		this.setSelectedRowKeys = this.setSelectedRowKeys.bind(this);
		this.add = this.add.bind(this);
		this.move = this.move.bind(this);
		this.edit = this.edit.bind(this);
		this.delete = this.delete.bind(this);
		this.editJson = this.editJson.bind(this);
	}

	render() {
		return (
			<div className={clsx("list-table-container", this.props.className)} >
				<fieldset>
					<legend>{this.props.title}</legend>
					<div className="entry-table-inner-container">
						<SelecTable className="profile-table"
							columns={this.tableColumns}
							rowObjects={this.props.settingDefinitions}
							getRowKey={(rowData) => rowData.FieldName}
							selectMode={this.props.disabled ? "none" : "multi"}
							contextMenuId={this.settingDefinitionsRowContextMenuName + this.props.name}
							setSelectedRowKeys={this.setSelectedRowKeys}
							onDoubleClick={(...args) => this.edit(args[2])}
						/>
						{this.props.disabled ? <Fragment /> : (
							<div className="button-bar">
								<button onClick={this.add}>Add New...</button>
								<button onClick={this.editJson}>Show JSON</button>
							</div>)
						}
						<ContextMenu id={this.settingDefinitionsRowContextMenuName + this.props.name}>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys); })}
								disabled={this.state.selectedRowKeyList.length !== 1}>View/Edit...</MenuItem>
							<MenuItem onClick={(e, data) => _.defer(() => { this.edit(data.selectedRowKeys, true); })}
								disabled={this.state.selectedRowKeyList.length !== 1}>Duplicate...</MenuItem>
							<MenuItem divider />
							<MenuItem onClick={(e, data) => _.defer(() => { this.move(data.selectedRowKeys, -1); })}>Move Up</MenuItem>
							<MenuItem onClick={(e, data) => _.defer(() => { this.move(data.selectedRowKeys, 1); })}>Move Down</MenuItem>
							<MenuItem divider />
							<MenuItem onClick={(e, data) => _.defer(() => { this.delete(data.selectedRowKeys); })}>Delete</MenuItem>
						</ContextMenu>
						<SettingDefinitionEditModal
							isOpen={this.state.showSettingDefinitionEditModal}
							closeModal={this.closeSettingDefinitionEditModal}
							editingSettingDefinition={this.state.editingSettingDefinition}
							setSettingDefinition={this.setSettingDefinition}
							isExisting={this.props.isExisting}
						/>
						<ShowJsonModal
							isOpen={this.state.showSettingDefinitionsShowJsonModal}
							closeModal={this.closeSettingDefinitionsShowJsonModal}
							value={this.props.settingDefinitions}
							setValue={this.setSettingDefinitions}
							showDirty={this.props.isExisting}
							disabled={this.props.disabled}
						/>
					</div>
				</fieldset>
			</div>
		);
	}

	setSettingDefinition(settingDefinition, settingDefinitionOriginalFieldName) {
		if (!settingDefinition.DisplayName) {
			alert("Display name cannot be empty.");
			return false;
		}

		if (!settingDefinition.FieldName) {
			alert("Field name cannot be empty.");
			return false;
		}

		const settingDefinitions = [
			...(this.props.settingDefinitions || [])
		];

		if (settingDefinition.FieldName !== settingDefinitionOriginalFieldName) {
			// Check for duplicates
			let savedSettingDefinitionDetails = null;
			if (settingDefinitions.some((thisSettingDefinitionDetails) => {
				savedSettingDefinitionDetails = thisSettingDefinitionDetails;
				return thisSettingDefinitionDetails.FieldName.toLowerCase() === settingDefinition.FieldName.toLowerCase();
			})) {
				alert(`Duplicate field names are not allowed. Field '${savedSettingDefinitionDetails.FieldName}' already exists.`);
				return false;
			}
		}

		settingDefinitionOriginalFieldName = settingDefinitionOriginalFieldName || settingDefinition.FieldName;
		let isUpdated = false;

		for (let i = settingDefinitions.length - 1; i >= 0; i--) {
			const thisSettingDefinitionDetails = settingDefinitions[i];
			if (thisSettingDefinitionDetails.FieldName === settingDefinitionOriginalFieldName) {
				settingDefinitions[i] = settingDefinition;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			settingDefinitions.push(settingDefinition);

		return this.setSettingDefinitions(settingDefinitions);
	}

	setSettingDefinitions(settingDefinitions) {
		this.props.setSettingDefinitions(settingDefinitions, this.props.name);
		return true;
	}

	setSelectedRowKeys(selectedRowKeys) {
		this.setState({ selectedRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	add() {
		this.edit(null);
	}

	move(selectedRowKeys, direction) {
		if (direction !== 1 && direction !== -1)
			return; // Invalid direction

		const settingDefinitions = [...this.props.settingDefinitions];
		let wasLastMatached = false;
		const firstIndex = direction === 1 ? settingDefinitions.length - 1 : 0;
		const lastIndex = direction !== 1 ? settingDefinitions.length - 1 : 0;

		for (let i = firstIndex; i !== lastIndex - direction; i -= direction) {
			const settingDefinition = settingDefinitions[i];
			if (selectedRowKeys[settingDefinition.FieldName]) {
				if (i !== firstIndex && !wasLastMatached) {
					[settingDefinitions[i], settingDefinitions[i + direction]] = [settingDefinitions[i + direction], settingDefinitions[i]];
					i += direction;
				}
				wasLastMatached = true;
			}
			else {
				wasLastMatached = false;
			}
		}

		this.props.setSettingDefinitions(settingDefinitions, this.props.name);
	}

	edit(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		const settingDefinitions = this.props.settingDefinitions || [];
		const matchingSettingDefinitionDetails = settingDefinitions.filter((settingDefinition) => settingDefinition.FieldName === selectedRowKey);

		let settingDefinition = matchingSettingDefinitionDetails.length > 0 ? matchingSettingDefinitionDetails[0] : { Enabled: true, AssignDefaultValue: false };

		if (createCopy)
			settingDefinition = { ...settingDefinition, FieldName: null };

		this.setState({ editingSettingDefinition: settingDefinition, showSettingDefinitionEditModal: true });
	}

	delete(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected field${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const settingDefinitions = this.props.settingDefinitions.filter((settingDefinition) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === settingDefinition.FieldName));

			this.props.setSettingDefinitions(settingDefinitions, this.props.name);
		}
	}

	editJson() {
		this.setState({ showSettingDefinitionsShowJsonModal: true });
	}

	closeSettingDefinitionEditModal() {
		this.setState({ showSettingDefinitionEditModal: false });
	}

	closeSettingDefinitionsShowJsonModal() {
		this.setState({ showSettingDefinitionsShowJsonModal: false });
	}
}