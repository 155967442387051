import React from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import clsx from "clsx";

export default class UserGroupsList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.userGroupTableColumns = [
			{ fieldName: "DomainUserGroupName", displayName: "User Group" },
			{ fieldName: "Description", displayName: "Description" },
			{ fieldName: "LdapGroupName", displayName: "Sync LDAP Group" },
			{ fieldName: "OwnerDomainUsername", displayName: "Owner" }
		];
	}

	render() {
		return (
			<div>
				<div className={clsx("list-table-container", "user-profile-entity-list", this.props.className)} >
					<fieldset>
						<legend>{this.props.title ?? "User Groups"}{this.props.isSelfAudit ? " (to be removed from a user group, you must send a request to the owner)" : ""}</legend>
						<div className="entry-table-inner-container" >
							<SelecTable className="profile-table"
								columns={this.userGroupTableColumns}
								rowObjects={this.props.userGroups}
								selectMode='none'
							/>
						</div>
					</fieldset>
				</div>
			</div>
		);
	}
}