import React from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import EntryRow from "../../Shared/EntryRow";
import Input from "../../Shared/Input";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class OptionEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editingOption: this.props.editingOption
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.save = this.save.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingOption: this.props.editingOption,
				editingOptionValue: this.props.editingOption.Value
			});
		}
	}

	render() {
		const editingOption = this.state.editingOption || {};
		const previousEditingOption = this.props.editingOption || {};
		const value = previousEditingOption.Value || "[New]";
		const isExisting = !!previousEditingOption.Value;

		return (
			<BetterDialog title={`Option: ${value}`} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Details</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="* Value">
										<Input type="text" maxLength={100}
											name="Value"
											value={editingOption.Value}
											previousValue={previousEditingOption.Value}
											showDirty={isExisting}
											title="Field Name"
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen}
										/>
									</EntryRow>
									<EntryRow label="Label">
										<Input type="text" maxLength={100}
											name="Label"
											value={editingOption.Label}
											previousValue={previousEditingOption.Label}
											showDirty={isExisting}
											title="Display Name"
											onChange={this.handleDataChange}
										/>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					<button onClick={this.save}>OK</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingOption = {
			...this.state.editingOption,
			[name]: value
		};

		this.setState({ editingOption });
	}

	save() {
		if (this.props.setOption(this.state.editingOption, this.state.editingOptionValue))
			this.props.closeModal();
	}
}
