import React, { Fragment } from "react";
import clsx from "clsx";
import "./FileInput.scss";

export default class FileInput extends React.Component {
	render() {
		const { errorMessage, ...rest } = this.props;

		return (
			<Fragment>
				{this.props.file ? this.props.file.name : "(No file selected)"}&nbsp;&nbsp;
				<label className={clsx("input-file", this.props.file ? null : "primary")}>
					<input type="file" {...rest} />
					<span>{this.props.file ? "Change" : "Choose"} File</span>
				</label>
				{!errorMessage ? (<Fragment />) : (
					<span className="error-message">{errorMessage}</span>
				)}
			</Fragment>
		);
	}
}