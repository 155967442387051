import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import FileTypeList from "./FileTypeList";
import Select from "../../Shared/Select";
import EntryRow from "../../Shared/EntryRow";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import _ from "lodash";

export default class CopyFileTypeFromEntityModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedEntityGuid: null,
			selectedFileTypeDetailsList: null,
			isGettingEntityOptions: false,
			entityOptions: null,
			isGettingEntityProfile: false,
			selectedEntityProfile: null
		};

		this.handleEntityChange = this.handleEntityChange.bind(this);
		this.getEntityOptions = this.getEntityOptions.bind(this);
		this.setFileTypeDetailsList = this.setFileTypeDetailsList.bind(this);
		this.save = this.save.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingFileTypeDetails
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				selectedEntityGuid: null,
				selectedFileTypeDetailsList: null,
				entityOptions: null,
				selectedEntityProfile: null
			});
		}
	}

	render() {
		if (!this.props.isOpen)
			return (<Fragment />);

		const selectedEntityProfile = this.state.selectedEntityProfile || {};

		return (
			<BetterDialog title="Choose an entity and file type(s) to copy" open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Entity File Types</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="Entity" title="Entity to copy file types from.">
										<LoadingWrapper isLoading={!this.state.entityOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
											<Select
												name="Entity"
												value={this.state.selectedEntityGuid || ""}
												onChange={this.handleEntityChange}
												options={this.getEntityOptions()}
												autoFocus={this.props.isOpen}
												disabled={this.state.isGettingEntityProfile}
											/>
										</LoadingWrapper>
									</EntryRow>
									<EntryRow label="File Types" title="File types.">
										<LoadingWrapper isLoading={this.state.isGettingEntityProfile} loadingElement={<LinearProgress style={{ width: "200px" }} />}>
											{selectedEntityProfile.FileTypeDetailsList && selectedEntityProfile.FileTypeDetailsList.length > 0 ?
												<Fragment>
													<FileTypeList
														fileTypeDetailsList={selectedEntityProfile.FileTypeDetailsList}
														setSelectedFileTypeDetailsList={this.setFileTypeDetailsList}
														onDoubleClick={this.save}
														allowMenu={false}
														forceMultiSelect={true}
														showFieldset={false}
														config={this.props.config}
													/>
													{selectedEntityProfile.FileTypeDetailsList && selectedEntityProfile.FileTypeDetailsList.length > 1 ? <span>Select 1 or more file types.</span> : <Fragment />}
												</Fragment>
												: <span>{this.state.selectedEntityGuid ? "No file types defined!" : "Select an entity to display file types."}</span>
											}
										</LoadingWrapper>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					<button onClick={this.save} disabled={!(this.state.selectedEntityProfile && this.state.selectedFileTypeDetailsList && this.state.selectedFileTypeDetailsList.length)}>OK</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	getEntityOptions() {
		if (this.state.entityOptions)
			return this.state.entityOptions;

		if (!this.state.isGettingEntityOptions) {
			_.defer(() => this.setState({ isGettingEntityOptions: true }));

			this.apiService.getEntities({ permissionName: "View Entity Profile", includeDisabledRecords: true },
				(response) => {
					const entities = [{}, ...response.data.Entities];
					const entityOptions = entities.map(entity => { return { value: entity.Guid || "", label: entity.Name || "" } });

					this.setState({
						isGettingEntityOptions: false,
						entityOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingDomainOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	handleEntityChange(e) {
		const { value } = e.target;
		const selectedEntityGuid = value;

		if (this.state.entityProfile && this.state.entityProfile.Guid === selectedEntityGuid && this.state.selectedEntityGuid === selectedEntityGuid)
			return;

		if (this.state.isGettingEntityProfile)
			return;

		if (!selectedEntityGuid) {
			_.defer(() => this.setState({ selectedEntityGuid, selectedEntityProfile: null }));
			return;
		}

		_.defer(() => this.setState({ isGettingEntityProfile: true, selectedEntityGuid }));

		this.apiService.getEntityProfile(selectedEntityGuid,
			(response) => {
				const selectedEntityProfile = { ...response.data.EntityProfile };

				this.setState({ selectedEntityProfile, selectedFileTypeDetailsList: null, isGettingEntityProfile: false });
			},
			(error) => {
				handleApiError(error);
				this.setState({ isGettingEntityProfile: false });
			}
		);
	}

	setSelectedMetadataFieldRowKeys(selectedRowKeys) {
		this.setState({ selectedMetadataRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	setFileTypeDetailsList(fileTypeDetailsList) {
		this.setState({ selectedFileTypeDetailsList: fileTypeDetailsList });
	}

	save() {
		if (this.props.setFileTypeDetailsList && this.state.selectedFileTypeDetailsList && this.state.selectedFileTypeDetailsList.length > 0)
			this.props.setFileTypeDetailsList(this.state.selectedFileTypeDetailsList);

		this.props.closeModal();
	}
}