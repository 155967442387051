import React from "react";
import {
	BrowserRouter,
	Switch,
	Route
} from "react-router-dom";
import Home from "./Routes/Home/Home";
import Upload from "./Routes/Upload/Upload";
import UploadChooseEntity from "./Routes/UploadChooseEntity/UploadChooseEntity";
import UploadResources from "./Routes/UploadResources/UploadResources";
import Download from "./Routes/Download/Download";
import DownloadChooseEntity from "./Routes/DownloadChooseEntity/DownloadChooseEntity";
import DownloadFile from "./Routes/DownloadFile/DownloadFile";
import EntityProfile from "./Routes/EntityProfile/EntityProfile";
import EntityProfileChooseEntity from "./Routes/EntityProfileChooseEntity/EntityProfileChooseEntity";
import UserPlugins from "./Routes/UserPlugins/UserPlugins";
import UserProfile from "./Routes/UserProfile/UserProfile";
import UserProfileChooseUser from "./Routes/UserProfileChooseUser/UserProfileChooseUser";
import UserGroups from "./Routes/UserGroups/UserGroups";
import UserPluginsChooseEntity from "./Routes/UserPluginsChooseEntity/UserPluginsChooseEntity";
import PluginDefinitions from "./Routes/PluginDefinitions/PluginDefinitions";
import Login from "./Routes/Login/Login";
import Logout from "./Routes/Logout/Logout";
import Outage from "./Routes/Outage/Outage";
import RouteNotFound from "./Shared/RouteNotFound";
import { UserContextProvider } from "./Contexts/UserContext";
import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap';
import "./ViasatUx/css/MaterialIcons.css";
import "./ViasatUx/css/bootstrap_viasat.css";
import "./ViasatUx/css/viasat-patterns.css";
import "./Content/site.scss";

export default class App extends React.Component {
	constructor(props) {
		super(props)

		const appVersion = "3.0.8917.26466"; // Do not manually change the version here! Set the base version in Web.csproj and run PrepareProjects to update.
		const appCommitHash = "5fdc66f"; // Do not manually change the commit hash here!

		this.state = {
			config: { ...this.props.config, appVersion, appCommitHash } // This line is needed to prevent random undefined exceptions for the config object
		}
	}

	render() {
		const moreProps = this.state;
		return (
			<BrowserRouter>
				<UserContextProvider>
					<Switch>
						<Route exact path="/" render={(props) => <Home {...{ ...props, ...moreProps }} />} />

						<Route exact path="/upload/:entityGuid" render={(props) => <Upload {...{ ...props, ...moreProps }} />} />
						<Route exact path="/upload" render={(props) => <UploadChooseEntity {...{ ...props, ...moreProps }} />} />
						<Route exact path="/uploadResources" render={(props) => <UploadResources {...{ ...props, ...moreProps }} />} />

						<Route exact path="/download/:entityGuid/*" render={(props) => <Download {...{ ...props, ...moreProps }} />} />
						<Route exact path="/download/:entityGuid" render={(props) => <Download {...{ ...props, ...moreProps }} />} />
						<Route exact path="/download" render={(props) => <DownloadChooseEntity {...{ ...props, ...moreProps }} />} />
						<Route exact path="/downloadFile/:entityGuid/:fileGuid" render={(props) => <DownloadFile {...{ ...props, ...moreProps }} />} />

						<Route exact path="/entityProfile/:entityGuid" render={(props) => <EntityProfile {...{ ...props, ...moreProps }} />} />
						<Route exact path="/entityProfile" render={(props) => <EntityProfileChooseEntity {...{ ...props, ...moreProps }} />} />

						<Route exact path="/userProfile/:domainName/:username" render={(props) => <UserProfile {...{ ...props, ...moreProps }} />} />
						<Route exact path="/userProfile" render={(props) => <UserProfileChooseUser {...{ ...props, ...moreProps }} />} />

						<Route exact path="/userGroups" render={(props) => <UserGroups {...{ ...props, ...moreProps }} />} />

						<Route exact path="/userPlugins/:entityGuid" render={(props) => <UserPlugins {...{ ...props, ...moreProps }} />} />
						<Route exact path="/userPlugins" render={(props) => <UserPluginsChooseEntity {...{ ...props, ...moreProps }} />} />

						<Route exact path="/pluginDefinitions" render={(props) => <PluginDefinitions {...{ ...props, ...moreProps }} />} />

						<Route exact path="/selfAudit" render={(props) => <UserProfile {...{ ...props, ...moreProps, isSelfAudit: true }} />} />

						<Route exact path="/login" render={(props) => <Login {...{ ...props, ...moreProps }} />} />
						<Route exact path="/logout" render={(props) => <Logout {...{ ...props, ...moreProps }} />} />

						<Route exact path="/outage" render={(props) => <Outage {...{ ...props, ...moreProps }} />} />

						<Route render={(props) => <RouteNotFound {...{ ...props, ...moreProps }} />} />
					</Switch>
				</UserContextProvider>
			</BrowserRouter>
		);
	}
}
