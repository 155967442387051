import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './SnackbarContentWrapper';

export default function PassiveMessage(props) {
	const { message, open, onClose, variant } = props;

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={open}
			autoHideDuration={6000}
			onClose={onClose}
		>
			<SnackbarContentWrapper
				onClose={onClose}
				variant={variant}
				message={message}
			/>
		</Snackbar>
	);
}