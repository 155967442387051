import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import EntryRow from "../../Shared/EntryRow";
import Select from "../../Shared/Select";
import ComboBox from "../../Shared/ComboBox";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import _ from "lodash";

export default class EntityUserRolesEditModal extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		this.state = {
			editingEntityUserRoles: this.props.editingEntityUserRoles,
			availableRoleNames: [],
			isLoadingAvailableRoles: false,
			selectedUsableRoleRowKeyList: [],
			selectedAssignableRoleRowKeyList: [],
			entityOptions: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.getEntityOptions = this.getEntityOptions.bind(this);
		this.getAvailableRoles = this.getAvailableRoles.bind(this);
		
		this.save = this.save.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened...
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			const isExisting = !!this.props.editingEntityUserRoles.Guid;
			this.setState({
				editingEntityUserRoles: this.props.editingEntityUserRoles,
				availableRoleNames: [],
				isExisting
			});

			_.defer(() => this.getAvailableRoles());
		}
	}

	render() {
		const editingEntityUserRoles = this.state.editingEntityUserRoles || {};
		const previousEditingUserEntityProfile = this.props.editingEntityUserRoles || {};
		const availableRoleNames = this.state.availableRoleNames || [];
		const entityName = previousEditingUserEntityProfile.Name || "[New]";
		const entityGuid = editingEntityUserRoles.Guid || "";
		const isExisting = this.state.isExisting;
		const canChangeUserEntities = this.props.entityGuid === null || this.context.hasPermission(entityGuid, "Change Entity Users");
		const isOwnAccount = false;

		return (
			<BetterDialog title={`Entity: ${entityName}`} open={this.props.isOpen}>
				<div className="entry-table-container user-entity-profile-edit-modal">
					<fieldset>
						<legend>Main</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="* Entity" title="Entity">
										{isExisting ? <span>{editingEntityUserRoles.Name || ""}</span> :
											<LoadingWrapper isLoading={!this.state.entityOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
												<Select
													name="Guid"
													value={editingEntityUserRoles.Guid}
													previousValue={previousEditingUserEntityProfile.Guid}
													showDirty={isExisting}
													disabled={this.props.disabled || isExisting || isOwnAccount}
													onChange={this.handleDataChange}
													options={this.getEntityOptions()}
													autoFocus={this.props.isOpen && !isExisting}
												/>
											</LoadingWrapper>
										}
									</EntryRow>
									<EntryRow label="Entity GUID" title="Entity GUID">
										<span>{entityGuid}</span>
									</EntryRow>
									{/*
									<EntryRow label="File Uploads Enabled?">
										<span>{editingEntityUserRoles.Enabled === false ? "Disabled" : "Enabled"}</span>
									</EntryRow>
									*/}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that give this user access to features.">
						<legend>Usable Roles</legend>
						<div className="role-select-container">
							<LoadingWrapper isLoading={this.state.isLoadingAvailableRoles} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
								<ComboBox
									name="UsableRoleNames"
									value={(editingEntityUserRoles.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									previousValue={(previousEditingUserEntityProfile.UsableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									isClearable={false}
									isMulti={true}
									isSearchable={true}
									closeMenuOnSelect={false}
									backspaceRemovesValue={true}
									onChange={this.handleDataChange}
									showDirty={isExisting}
									disabled={this.props.disabled || !(canChangeUserEntities || !isExisting) || isOwnAccount}
								/>
							</LoadingWrapper>
						</div>
					</fieldset>
				</div>
				<div className="fieldset-container">
					<fieldset title="Roles that this user can assign to other users, if they have permission to assign roles.">
						<legend>Assignable Roles</legend>
						<div className="role-select-container">
							<LoadingWrapper isLoading={this.state.isLoadingAvailableRoles} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
								<ComboBox
									name="AssignableRoleNames"
									value={(editingEntityUserRoles.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									previousValue={(previousEditingUserEntityProfile.AssignableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									options={(availableRoleNames || []).map((roleName) => { return { label: roleName, value: roleName } })}
									isClearable={false}
									isMulti={true}
									isSearchable={true}
									closeMenuOnSelect={false}
									backspaceRemovesValue={true}
									onChange={this.handleDataChange}
									showDirty={isExisting}
									disabled={this.props.disabled || !(canChangeUserEntities || !isExisting) || isOwnAccount}
								/>
							</LoadingWrapper>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	toggleState(stateName) {
		this.setState({ [stateName]: !this.state[stateName] });
	}

	getEntityOptions() {
		if (this.state.entityOptions)
			return this.state.entityOptions;

		if (!this.state.isLoadingEntityOptions && this.state.isExisting === false) {
			// We need to get a list...
			_.defer(() => this.setState({ isLoadingEntityOptions: true }));

			this.apiService.getEntities({ permissionName: "Change Entity Users", includeDisabledRecords: true, includeAllEntities: true },
				(response) => {
					const entities = response.data.Entities;
					const entityOptions = entities.map(entity => { return { value: entity.Guid, label: entity.Name, data: entity } });
					entityOptions.unshift({ value: null, label: "" });

					this.setState({
						isLoadingEntityOptions: false,
						entityOptions
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isLoadingEntityOptions: false });
				}
			);
		}

		return []; // default to empty
	}

	getAvailableRoles() {
		const entityGuid = this.state.editingEntityUserRoles ? this.state.editingEntityUserRoles.Guid : null;
		if (!entityGuid) {
			this.setState({
				isLoadingAvailableRoles: false,
				availableRoleNames: []
			});
			return;
		}

		this.setState({
			isLoadingAvailableRoles: true
		});

		this.apiService.getEntityProfile(entityGuid,
			(response) => {
				const isAllEntities = entityGuid === this.context.allEntitiesGuid;
				let availableRoleNames = response.data.UserAssignableRoleNames;
				const standardRoleNames = response.data.StandardRoleNames;

				availableRoleNames = availableRoleNames
					.filter((roleName) => isAllEntities || standardRoleNames.includes(roleName)) // take out the global-only role names if this is not the "all entities" GUID
					.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);

				if (this.state.isNew)
					response.data.EntityProfile.Guid = null;

				this.setState({
					isLoadingAvailableRoles: false,
					availableRoleNames
				});
			},
			(error) => {
				handleApiError(error);
				this.setState({ isLoading: false });
			}
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		let values = {
			[name]: value
		};

		if (name === "Guid") {
			// Lookup GUID and set the values
			const foundEntities = this.state.entityOptions.filter(option => option.value === value);
			if (foundEntities && foundEntities.length)
				values = foundEntities[0].data;

			_.defer(() => this.getAvailableRoles());
		}

		const editingEntityUserRoles = {
			...this.state.editingEntityUserRoles,
			...values
		};

		this.setState({ editingEntityUserRoles });
	}

	save() {
		const entityGuid = this.state.editingEntityUserRoles ? this.state.editingEntityUserRoles.Guid : null;

		if (this.props.setRoles(this.state.editingEntityUserRoles, entityGuid))
			this.props.closeModal();
	}
}