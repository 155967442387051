import React from "react";
import Input from "../../Shared/Input"
import EntryRow from "../../Shared/EntryRow"
import { extractDomElement } from "../../Helpers/SharedFunctions";
import Radio from "../../Shared/Radio";
import { useApiService } from "../../Hooks/useApiService";

export default class MainOptions extends React.Component {
	constructor(props) {
		super(props);

		this.verifyState();

		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleDataChangeBool = this.handleDataChangeBool.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		this.verifyState(prevProps, prevState);
	}

	verifyState(prevProps, prevState) {
		if (!prevProps || prevProps.userProfile !== this.props.userProfile) {
			// Handle userProfile change
			const state = {
			};

			if (prevProps) {
				this.setState(state);
			}
			else {
				// Ignore warning about setting the state directly:
				// eslint-disable-next-line
				this.state = state; // Called from the constructor
			}
		}
	}

	render() {
		return (
			<div className="entry-table-container user-profile-main-options" >
				<fieldset>
					<legend>Main</legend>
					<div className="entry-table-inner-container" >
						<table>
							<tbody>
								<EntryRow label="* Domain Name" title="Domain Name">
									<span>{this.props.userProfile.DomainName || ""}</span>
								</EntryRow>
								<EntryRow label="* Username" title="Username">
									<Input type="text" maxLength={200}
										name="Username"
										value={this.props.userProfile.Username || ""}
										previousValue={this.props.previousUserProfile.Username || ""}
										showDirty={true}
										onChange={this.handleDataChange}
										disabled={true}
									/>
								</EntryRow>
								<EntryRow label="* First Name" title="First Name">
									<Input type="text" maxLength={200}
										name="FirstName"
										value={this.props.userProfile.FirstName || ""}
										previousValue={this.props.previousUserProfile.FirstName || ""}
										showDirty={this.props.userProfile.IsExistingAppUser}
										onChange={this.handleDataChange}
										disabled={this.props.disabled}
										autoFocus={true}
									/>
								</EntryRow>
								<EntryRow label="* Last Name" title="Last Name">
									<Input type="text" maxLength={200}
										name="LastName"
										value={this.props.userProfile.LastName || ""}
										previousValue={this.props.previousUserProfile.LastName || ""}
										showDirty={this.props.userProfile.IsExistingAppUser}
										onChange={this.handleDataChange}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="Email Address" title="Email Address">
									<Input type="text" maxLength={255}
										name="EmailAddress"
										value={this.props.userProfile.EmailAddress || ""}
										previousValue={this.props.previousUserProfile.EmailAddress || ""}
										showDirty={this.props.userProfile.IsExistingAppUser}
										onChange={this.handleDataChange}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="White List IPs"
									title={"Comma separated list of Individual IPs and/or CIDR ranges that this user may login from.\nMulti-factor authentication (MFA/DUO) will not be required.\nUser may not login from other IPs."} // use curly braces for \n
								>
									<Input type="text" maxLength={8000}
										className="email-input"
										name="WhiteListIps"
										value={this.props.userProfile.WhiteListIps || ""}
										previousValue={this.props.previousUserProfile.WhiteListIps || ""}
										showDirty={this.props.userProfile.IsExistingAppUser}
										onChange={this.handleDataChange}
										autoFocus={this.state.isEditingWhiteListIps}
										disabled={this.props.disabled}
									/>
								</EntryRow>
								<EntryRow label="Enabled?" title="Indicates if the user account is allowed to log in.">
									<Radio
										label="Enabled"
										name="Enabled_" // Add underscore to make the name unique from the parent component
										optionValue={true}
										checked={this.props.userProfile.Enabled}
										title="This user may log in."
										previousValue={this.props.previousUserProfile.Enabled}
										onChange={this.handleDataChangeBool}
										disabled={this.props.disabled}
										showDirty={this.props.userProfile.IsExistingAppUser} />
									<Radio
										label="Disabled"
										name="Enabled_"
										optionValue={false}
										checked={!this.props.userProfile.Enabled}
										title="This user may NOT log in."
										previousValue={!this.props.previousUserProfile.Enabled}
										onChange={this.handleDataChangeBool}
										disabled={this.props.disabled}
										showDirty={this.props.userProfile.IsExistingAppUser} />
								</EntryRow>
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
		);
	}

	handleDataChangeBool(e) {
		this.handleDataChange(e, true);
	}

	handleDataChange(e, isBool) {
		let { name, value } = extractDomElement(e.target, isBool);

		const userProfile = {
			...this.props.userProfile,
			[name]: value
		};

		this.props.setUserProfile(userProfile);
	}
}