import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import SelecTable from "../../Shared/SelecTable";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import MetadataFieldEditModal from "./MetadataFieldEditModal";
import Input from "../../Shared/Input";
import TextArea from "../../Shared/TextArea";
import RegExTextArea from "../../Shared/RegExTextArea";
import Checkbox from "../../Shared/Checkbox";
import Radio from "../../Shared/Radio";
import EntryRow from "../../Shared/EntryRow";
import ComboBox from "../../Shared/ComboBox";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import { extractDomElement, evaluate, getValueDeclarations, moveArrayItemsByKey } from "../../Helpers/SharedFunctions";
import _ from "lodash";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "../../Shared/Select";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";

export default class FileTypeEditModal extends React.Component {
	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.metadataFieldsRowContextMenuName = `MetadataFieldsRowContextMenu_${uniqueName}`;

		this.state = {
			systemFileTypeOptions: null,
			isGettingSystemFileTypeOptions: false,
			systemFileTypeDefinitionMap: null,
			selectedMetadataRowKeyList: [],
			showMetadataModal: false,
			editingMetadataField: {},
			editingFileTypeDetails: this.props.editingFileTypeDetails ?? false,
			editingFileTypeName: null,
			openShowJsonModal: false
		};

		this.metadataTableColumns = [
			{ fieldName: "DisplayName", displayName: "Display Name" },
			{ fieldName: "FieldName", displayName: "Field Name" },
			{ fieldName: "Tooltip", displayName: "Tooltip" },
			{ fieldName: "StaticValue", displayName: "Static", customValue: (rowObject, fieldName) => rowObject["IsStaticValue"] === true ? rowObject[fieldName] : "---" },
			{ fieldName: "IsRequired", displayName: "Required?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Required" : "Optional" },
			{ fieldName: "RequiredGroupName", displayName: "Required Group" },
			{ fieldName: "CanExtractFromFilename", displayName: "Extract?", customValue: (rowObject, fieldName) => rowObject["IsStaticValue"] === true ? "---" : (rowObject[fieldName] === true ? "Extract" : "No Extract") }];

		this.closeMetadataModal = this.closeMetadataModal.bind(this);
		this.setMetadataField = this.setMetadataField.bind(this);
		this.setSelectedMetadataFieldRowKeys = this.setSelectedMetadataFieldRowKeys.bind(this);
		this.addMetadataField = this.addMetadataField.bind(this);
		this.editMetadataField = this.editMetadataField.bind(this);
		this.deleteMetadataField = this.deleteMetadataField.bind(this);
		this.save = this.save.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleDataChangeBool = this.handleDataChangeBool.bind(this);
		this.reorderMetadataFields = this.reorderMetadataFields.bind(this);
		this.getSystemFileTypeOptions = this.getSystemFileTypeOptions.bind(this);
		this.applySystemFileType = this.applySystemFileType.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingFileTypeDetails
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingFileTypeDetails: this.props.editingFileTypeDetails,
				editingFileTypeName: (this.props.editingFileTypeDetails || {}).Name,
				selectedMetadataRowKeyList: []
			});
		}
	}

	render() {
		const editingFileTypeDetails = this.state.editingFileTypeDetails || {};
		const previousEditingFileTypeDetails = this.props.editingFileTypeDetails || {};
		const fileTypeName = previousEditingFileTypeDetails.Name || "[New]";
		const isExisting = !!previousEditingFileTypeDetails.Name;
		const heading = `File Type: ${fileTypeName}`;
		const metadataTableColumns = [...this.metadataTableColumns];

		if (editingFileTypeDetails.IsFileBundle)
			metadataTableColumns.push({ fieldName: "BundledFileValueMustMatch", displayName: "Bundle Match?", customValue: (rowObject, fieldName) => rowObject["BundledFileValueMustMatch"] === true ? "Must Match" : "---" });

		if (this.props.isSystemFileTypeEntity || editingFileTypeDetails.SyncWithSystemFileType)
			metadataTableColumns.push({
				fieldName: "SyncWithSystemFileType", displayName: `Sync${this.props.isSystemFileTypeEntity ? " Lock" : ""}?`,
				customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? `Sync${this.props.isSystemFileTypeEntity ? " Lock" : ""}`
					: (rowObject[fieldName] === false ? `${this.props.isSystemFileTypeEntity ? "" : "Not Synced"}` : "Sync Lock")
			});

		return (
			<BetterDialog title={heading} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Details</legend>
						<div className="entry-table-inner-container">
							<table>
								<tbody>
									{this.props.isSystemFileTypeEntity ?
										<Fragment /> :
										<EntryRow label="Sync with System File Type?" title={"Indicates if this file type will use the values from the system file type of the same name.\nEnabling this will replace the current definition with data from the system file type when saved, if it exists."}>
											<Checkbox
												name="SyncWithSystemFileType"
												value={editingFileTypeDetails.SyncWithSystemFileType || false}
												previousValue={previousEditingFileTypeDetails.SyncWithSystemFileType || false}
												onChange={this.handleDataChange}
												showDirty={isExisting}
												disabled={this.props.disabled}
											/>
										</EntryRow>
									}
									<EntryRow label={`* ${(this.props.isSystemFileTypeEntity || editingFileTypeDetails.SyncWithSystemFileType) ? "System " : ""}File Type Name`} title={`${(this.props.isSystemFileTypeEntity || editingFileTypeDetails.SyncWithSystemFileType) ? "System " : ""}File Type Name`}>
										<LoadingWrapper isLoading={this.state.isGettingSystemFileTypeOptions} loadingElement={<LinearProgress style={{ width: "60px" }} />}>
											{(editingFileTypeDetails.SyncWithSystemFileType && (!isExisting || this.state.systemFileTypeOptions)) ?
												<Select
													id="FileTypeEditModal_Name"
													name="Name"
													options={this.getSystemFileTypeOptions()}
													value={editingFileTypeDetails.Name || ""}
													previousValue={previousEditingFileTypeDetails.Name || ""}
													showDirty={isExisting}
													onChange={this.handleDataChange}
													autoFocus={this.props.isOpen && !isExisting}
													disabled={this.props.disabled}
												/>
												:
												<Input type="text" maxLength={20}
													id="FileTypeEditModal_Name"
													name="Name"
													value={editingFileTypeDetails.Name || ""}
													previousValue={previousEditingFileTypeDetails.Name || ""}
													showDirty={isExisting}
													onChange={this.handleDataChange}
													autoFocus={this.props.isOpen && !isExisting}
													disabled={this.props.disabled || (editingFileTypeDetails.SyncWithSystemFileType && isExisting)}
												/>
											}
										</LoadingWrapper>
									</EntryRow>
									<EntryRow label="Filename RegEx" title="Filename RegEx">
										<RegExTextArea
											name="FilenameRegEx"
											value={editingFileTypeDetails.FilenameRegEx || ""}
											previousValue={previousEditingFileTypeDetails.FilenameRegEx || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
											testValuesName="FilenameRegExTestValues"
											testValues={editingFileTypeDetails.FilenameRegExTestValues}
											previousTestValues={previousEditingFileTypeDetails.FilenameRegExTestValues}
										/>
									</EntryRow>
									<EntryRow label="Filename Helper Text" title="Filename Helper Text will display on the upload page next to the 'File Type' drop down.">
										<TextArea
											name="FilenameHelperText"
											value={editingFileTypeDetails.FilenameHelperText || ""}
											previousValue={previousEditingFileTypeDetails.FilenameHelperText || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
										/>
									</EntryRow>
									<EntryRow label="Keep Days" title={"Days to keep files of this type before deleting.\n0 to delete at the end of each day.\nblank to keep forever."}>
										<Input type="number"
											name="KeepDays"
											min="-1"
											max="2147483647"
											placeholder="(forever)"
											value={(editingFileTypeDetails.KeepDays === "-1" ? "" : editingFileTypeDetails.KeepDays) ?? ""}
											previousValue={(previousEditingFileTypeDetails.KeepDays === "-1" ? "" : previousEditingFileTypeDetails.KeepDays) ?? ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen && !isExisting}
											disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
										/>
										{editingFileTypeDetails.KeepDays === "0" ? <Fragment><span> (delete files at end of each day)</span></Fragment> : <Fragment />}
										{editingFileTypeDetails.KeepDays > 0 ? <Fragment>
											<span> (delete files {parseInt(editingFileTypeDetails.KeepDays).toLocaleString()} day{editingFileTypeDetails.KeepDays === "1" ? "" : "s"} after upload)</span>
										</Fragment> : <Fragment />}
									</EntryRow>
									<EntryRow label="Allow Duplicate Filenames" title="Allow duplicate filenames to be uploaded.">
										<Checkbox
											name="AllowDuplicateFilenames"
											value={editingFileTypeDetails.AllowDuplicateFilenames || false}
											previousValue={previousEditingFileTypeDetails.AllowDuplicateFilenames || false}
											onChange={this.handleDataChange}
											showDirty={isExisting}
											disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
										/>
									</EntryRow>
									<EntryRow label="File Bundle?" title={"Indicates if this file type is a file bundle that contains files that should be extracted.\nSupported file extension: .zip"}>
										<Checkbox
											name="IsFileBundle"
											value={editingFileTypeDetails.IsFileBundle || false}
											previousValue={previousEditingFileTypeDetails.IsFileBundle || false}
											onChange={this.handleDataChange}
											showDirty={isExisting}
											disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
										/>
									</EntryRow>
									{editingFileTypeDetails.IsFileBundle ?
										<Fragment>
											<EntryRow label="Allowed File Types In Bundle" title={"Allowed File Types In Bundle.\nDisabled file types are valid."}>
												<ComboBox
													name="AllowedFileTypesInBundle"
													value={(editingFileTypeDetails.AllowedFileTypesInBundle || []).map((s) => { return { label: s, value: s } })}
													previousValue={(editingFileTypeDetails.AllowedFileTypesInBundle || []).map((s) => { return { label: s, value: s } })}
													options={(this.props.fileTypeNames || []).map((s) => { return { label: s, value: s } })}
													isClearable={true}
													isMulti={true}
													isSearchable={true}
													closeMenuOnSelect={false}
													backspaceRemovesValue={true}
													onChange={this.handleDataChange}
													showDirty={isExisting}
													placeholder="All enabled file types are allowed"
													disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
												/>
											</EntryRow>
											<EntryRow label="Required File Types In Bundle" title="Required File Types In Bundle">
												<ComboBox
													name="RequiredFileTypesInBundle"
													value={(editingFileTypeDetails.RequiredFileTypesInBundle || []).map((s) => { return { label: s, value: s } })}
													previousValue={(editingFileTypeDetails.RequiredFileTypesInBundle || []).map((s) => { return { label: s, value: s } })}
													options={(this.props.fileTypeNames || []).map((s) => { return { label: s, value: s } })}
													isClearable={true}
													isMulti={true}
													isSearchable={true}
													closeMenuOnSelect={false}
													backspaceRemovesValue={true}
													onChange={this.handleDataChange}
													showDirty={isExisting}
													placeholder="No required file types"
													disabled={this.props.disabled || editingFileTypeDetails.SyncWithSystemFileType}
												/>
											</EntryRow>
										</Fragment>
										: <Fragment />}
									<EntryRow label="Enabled?" title="Indicates if the file type may be used for new uploads.">
										<Radio
											label="Enabled"
											name="Enabled_" // Add underscore to make the name unique from the parent component
											optionValue={true}
											checked={editingFileTypeDetails.Enabled}
											title="This file type may be used for new uploads."
											previousValue={previousEditingFileTypeDetails.Enabled}
											onChange={this.handleDataChangeBool}
											disabled={this.props.disabled}
											showDirty={isExisting} />
										<Radio
											label="Disabled"
											name="Enabled_"
											optionValue={false}
											checked={!editingFileTypeDetails.Enabled}
											title="This file type may NOT be used for new uploads."
											previousValue={!previousEditingFileTypeDetails.Enabled}
											onChange={this.handleDataChangeBool}
											disabled={this.props.disabled}
											showDirty={isExisting} />
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className={!this.props.isNew && previousEditingFileTypeDetails.MetadataFields !== editingFileTypeDetails.MetadataFields ? "is-dirty" : null}>
					<fieldset>
						<legend>Metadata Fields</legend>
						<div className="entry-table-inner-container">
							<SelecTable className="profile-table"
								columns={metadataTableColumns}
								rowObjects={editingFileTypeDetails.MetadataFields}
								getRowKey={(rowData) => rowData.FieldName}
								selectMode={this.props.disabled ? "single" : "multi"}
								contextMenuId={this.metadataFieldsRowContextMenuName}
								setSelectedRowKeys={this.setSelectedMetadataFieldRowKeys}
								onDoubleClick={(...args) => this.editMetadataField(args[2])}
							/>
							{this.props.disabled ? <Fragment /> :
								<div className="button-bar">
									<button onClick={this.addMetadataField}>Add New...</button>
									{editingFileTypeDetails.MetadataFields && editingFileTypeDetails.MetadataFields.length > 1 ? (
										<Fragment>
											<button title="Move selected row(s) up." onClick={() => this.reorderMetadataFields(-1)}>▲</button>
											<button title="Move selected row(s) down." onClick={() => this.reorderMetadataFields(1)}>▼</button>
										</Fragment>)
										: <Fragment />
									}
								</div>
							}
							<ContextMenu id={this.metadataFieldsRowContextMenuName}>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editMetadataField(data.selectedRowKeys); })}
									disabled={this.state.selectedMetadataRowKeyList.length !== 1}>View{this.props.disabled ? "" : "/Edit"}...</MenuItem>
								<MenuItem onClick={(e, data) => _.defer(() => { this.editMetadataField(data.selectedRowKeys, true); })}
									disabled={this.props.disabled || this.state.selectedMetadataRowKeyList.length !== 1}>Duplicate...</MenuItem>
								<MenuItem divider />
								<MenuItem onClick={(e, data) => _.defer(() => { this.deleteMetadataField(data.selectedRowKeys); })}
									disabled={this.props.disabled || this.state.selectedMetadataRowKeyList.length === 0}>Delete</MenuItem>
							</ContextMenu>
							<MetadataFieldEditModal
								isOpen={this.state.showMetadataModal}
								closeModal={this.closeMetadataModal}
								editingMetadataField={this.state.editingMetadataField}
								setMetadataField={this.setMetadataField}
								isFileBundle={editingFileTypeDetails.IsFileBundle}
								isSystemFileTypeEntity={this.props.isSystemFileTypeEntity}
								fileTypeSyncWithSystemFileType={editingFileTypeDetails.SyncWithSystemFileType}
								disabled={this.props.disabled}
							/>
						</div>
					</fieldset>
				</div>
				<BetterDialogButtons>
					{this.props.disabled ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={() => this.setState({ openShowJsonModal: true })} title="Show JSON.">Show JSON</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
				<ShowJsonModal
					heading={`${heading} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.editingFileTypeDetails}
					setValue={(value) => { this.setState({ editingFileTypeDetails: value, openShowJsonModal: false }); }}
					showDirty={!this.props.isNew}
					disabled={this.props.disabled}
				/>

			</BetterDialog>
		);
	}

	getSystemFileTypeOptions(callback) {
		if (this.state.systemFileTypeOptions)
			return this.state.systemFileTypeOptions;

		if (!this.state.isGettingSystemFileTypeOptions) {
			// We need to get a list...
			_.defer(() => this.setState({ isGettingSystemFileTypeOptions: true }));

			const me = this;
			this.apiService.getSystemFileTypes(
				(response) => {
					const systemFileTypeDefinitionMap = response.data.FileTypeDetailsList.reduce(function (map, fileType) {
						map[fileType.Name] = fileType
						return map;
					}, {});

					const fileTypeDefinitions = [{ Name: "" }, ...response.data.FileTypeDetailsList];
					const systemFileTypeOptions = fileTypeDefinitions.map(fileTypeDefinition => { return { value: fileTypeDefinition.Name, label: fileTypeDefinition.Name } });

					this.setState({
						isGettingSystemFileTypeOptions: false,
						systemFileTypeDefinitionMap,
						systemFileTypeOptions
					});

					_.defer(() => {
						if (callback) {
							callback();
						}
						else {
							me.applySystemFileType();
						}
					});
				},
				(error) => {
					handleApiError(error);
					this.setState({ isGettingSystemFileTypeOptions: false, systemFileTypeOptions: [] });

					_.defer(() => {
						if (callback) {
							callback();
						}
						else {
							me.applySystemFileType();
						}
					});
				}
			);
		}

		return []; // default to empty
	}

	closeMetadataModal() {
		this.setState({ showMetadataModal: false });
	}

	setMetadataField(metadataField, originalFieldName) {
		const metadataFields = [
			...(this.state.editingFileTypeDetails.MetadataFields || [])
		];

		if (!metadataField.DisplayName) {
			alert("Display Name cannot be empty.");
			return false;
		}

		if (!metadataField.FieldName) {
			alert("Field Name cannot be empty.");
			return false;
		}

		if (!metadataField.FieldName.match("^[a-zA-Z][a-zA-Z0-9_]*$")) {
			alert("Field Name may only contain letters, numbers, and underscores and must start with a letter. Spaces and special characters are not allowed.");
			return false;
		}

		if (metadataField.AvailableCondition && metadataField.AvailableCondition.trim()) {
			try {
				const testMetadata = {};
				metadataFields.forEach((metadataField) => {
					testMetadata[metadataField.FieldName] = "";
				});
				const valueDeclarations = getValueDeclarations(testMetadata, metadataFields);
				const conditionEval = evaluate(metadataField.AvailableCondition, valueDeclarations);
				if (conditionEval !== true && conditionEval !== false) {
					alert("Invalid 'available condition'.\nCondition must evaluate to a boolean value. (true/false)");
					return false;
				}
			}
			catch (ex) {
				console.error(ex);
				alert("Invalid 'available condition'.\nEvaluating the condition resulted in an error:\n" + ex);
				return false;
			}
		}

		if (metadataField.FieldName !== originalFieldName) {
			// Check for duplicates
			let savedMetadataField = null;
			if (metadataFields.some((thisMetadataField) => {
				savedMetadataField = thisMetadataField;
				return thisMetadataField.FieldName.toLowerCase() === metadataField.FieldName.toLowerCase();
			})) {
				alert(`Duplicate field names are not allowed. Field '${savedMetadataField.FieldName}' already exists.`);
				return false;
			}
		}

		originalFieldName = originalFieldName || metadataField.FieldName;
		let isUpdated = false;

		for (let i = metadataFields.length - 1; i >= 0; i--) {
			const thisMetadataField = metadataFields[i];
			if (thisMetadataField.FieldName === originalFieldName) {
				metadataFields[i] = metadataField;
				isUpdated = true;
				break;
			}
		}

		if (!isUpdated) // Not found in the list. Add it.
			metadataFields.push(metadataField);

		// eslint-disable-next-line
		metadataFields.sort((a, b) => a.SortOrder == b.SortOrder ? 0 : (a.SortOrder < b.SortOrder ? -1 : 1));

		this.setMetadataFields(metadataFields);
		return true;
	}

	handleDataChangeBool(e) {
		this.handleDataChange(e, true);
	}

	handleDataChange(e, isBool) {
		const { name, value } = extractDomElement(e.target, isBool);

		const editingFileTypeDetails = {
			...this.state.editingFileTypeDetails,
			[name]: value
		};

		this.setState({ editingFileTypeDetails });

		const me = this;
		_.defer(() => {
			if (name === "SyncWithSystemFileType") {
				const el = document.getElementById("FileTypeEditModal_Name");
				if (el)
					el.focus();
			}

			if (name === "Name" || name === "SyncWithSystemFileType") {
				me.applySystemFileType();
			}
		});
	}

	applySystemFileType() {
		if (!this.state.editingFileTypeDetails.SyncWithSystemFileType)
			return;

		const me = this;
		const applySystemFileType = () => {
			let fileTypeDetails = (me.state.systemFileTypeDefinitionMap ?? {})[me.state.editingFileTypeDetails.Name];
			let applyDefaults = true;
			if (!fileTypeDetails) {
				if (me.props.editingFileTypeDetails?.SyncWithSystemFileType) {
					applyDefaults = false;
					fileTypeDetails = me.props.editingFileTypeDetails;
				}
				else {
					if (!me.state.editingFileTypeDetails.Name) {
						fileTypeDetails = { Enabled: true };
					}
					else {
						applyDefaults = false;
						fileTypeDetails = me.props.editingFileTypeDetails;
						alert("Invalid system file type name.");
					}
				}
			}

			if (applyDefaults) {
				fileTypeDetails = { ...fileTypeDetails };

				if (fileTypeDetails.MetadataFields) {
					fileTypeDetails.MetadataFields = fileTypeDetails.MetadataFields.map(m => {
						return {
							...m,
							SyncWithSystemFileType: m.SyncWithSystemFileType === true ? null : true
						}
					});
				}

				fileTypeDetails.SyncWithSystemFileType = me.state.editingFileTypeDetails.SyncWithSystemFileType;
			}

			me.setState({ editingFileTypeDetails: fileTypeDetails });
		}

		if (!me.state.systemFileTypeDefinitionMap) {
			me.getSystemFileTypeOptions(applySystemFileType)
		}
		else {
			_.defer(() => {
				applySystemFileType();
			});
		}
	}

	setSelectedMetadataFieldRowKeys(selectedRowKeys) {
		this.setState({ selectedMetadataRowKeyList: Object.getOwnPropertyNames(selectedRowKeys) });
	}

	reorderMetadataFields(direction) {
		let metadataFields = moveArrayItemsByKey(direction, this.state.editingFileTypeDetails.MetadataFields, "FieldName", this.state.selectedMetadataRowKeyList);

		if (metadataFields === this.state.editingFileTypeDetails.MetadataFields)
			return; // same object indicates no changes

		const sortOrderIncrement = 10;
		let sortOrder = sortOrderIncrement;
		metadataFields = metadataFields.map(p => {
			const pluginProfile = { ...p, SortOrder: sortOrder };
			sortOrder += sortOrderIncrement;
			return pluginProfile;
		});

		this.setMetadataFields(metadataFields);
	}

	addMetadataField() {
		this.editMetadataField(null);
	}

	editMetadataField(selectedRowKey, createCopy) {
		if (selectedRowKey && typeof selectedRowKey === "object")
			selectedRowKey = Object.getOwnPropertyNames(selectedRowKey)[0];

		let editingMetadataField = {};
		const editingFileTypeDetails = this.state.editingFileTypeDetails || {};
		if (editingFileTypeDetails.MetadataFields)
			editingMetadataField = editingFileTypeDetails.MetadataFields.filter((metadataField) => metadataField.FieldName === selectedRowKey)[0] || {};

		if (createCopy)
			editingMetadataField = { ...editingMetadataField, FieldName: null };

		this.setState({ editingMetadataField: editingMetadataField, showMetadataModal: true });
	}

	deleteMetadataField(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Delete selected metadata field${selectedRowKeys.length > 1 ? "s" : ""}?`)) {
			const metadataFields = this.state.editingFileTypeDetails.MetadataFields.filter((metadataField) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === metadataField.FieldName));

			this.setMetadataFields(metadataFields);
		}
	}

	setMetadataFields(metadataFields) {
		const editingFileTypeDetails = { ...this.state.editingFileTypeDetails, MetadataFields: metadataFields };

		this.setState({ editingFileTypeDetails });
	}

	save() {
		if (this.props.setFileTypeDetails(this.state.editingFileTypeDetails, this.state.editingFileTypeName))
			this.props.closeModal();
	}
}