import React from "react";
import "./ViasatLogoSpinner.scss";

export default class ViasatLogoSpinner extends React.Component {
	render() {
		const { size, height, style, id, className } = this.props;

		return (
			<div id={id} className={`viasat-logo-spinner-container ${className}`} style={style} >
				<div className="viasat-logo-spinner-container-inner" style={{ "height": `${size ?? height}` }} >
					<div className="viasat-logo-spinner-mask">
						<div className="viasat-logo-spinner-animated-background"></div>
					</div>
				</div>
			</div>
		);
	}
}