import React, { Fragment } from "react";
import ViasatLogoSpinner from "./ViasatLogoSpinner";
import _ from "lodash";
import clsx from "clsx";
import "./PageOverlay.scss";

export default class PageOverlay extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showOverlay: this.props.showOverlay ?? false,
			showSpinner: this.props.showOverlay ?? false,
			showOverlayDim: this.props.showOverlay ?? false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.showOverlay) {
			const showOverlay = this.props.showOverlay || this.state.showOverlay;
			if (showOverlay) {
				this.setState({ showOverlay: true, showSpinner: true });
				_.delay(_.bind(() => {
					this.setState({ showOverlayDim: this.props.showOverlay });
				}, this), 200); // wait this long before starting to dim the UI
			}
		}
		else {
			if (!this.props.showOverlay && prevProps.showOverlay !== this.props.showOverlay) {
				this.setState({ showSpinner: false, showOverlayDim: false });
				_.delay(_.bind(() => { this.setState({ showOverlay: this.props.showOverlay }); }, this), 150);
			}
		}
	}

	render() {
		return (
			<Fragment>
				{this.state.showOverlay
					?
					<div className="overlay" style={this.props.style}>
						<div className={clsx("overlay-cover", this.state.showOverlayDim ? "overlay-opacity" : null)} />
						{this.state.showSpinner
							?
							this.props.children
								?
								(this.props.children)
								:
								<ViasatLogoSpinner size="75px" style={{ position: "fixed", top: "50%", left: "50%", transform: "translateX(-40px) translateY(-37px)" }} />
							:
							<Fragment />
						}
					</div>
					:
					<Fragment />
				}
			</Fragment>
		);
	}
}
