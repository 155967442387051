import React, { Fragment } from "react";
import Input from "../../Shared/Input"
import Radio from "../../Shared/Radio"
import EntryRow from "../../Shared/EntryRow"
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class MainOptions extends React.Component {
	constructor(props) {
		super(props);

		this.verifyState();

		this.handleDataChange = this.handleDataChange.bind(this);
		this.handleDataChangeBool = this.handleDataChangeBool.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		this.verifyState(prevProps, prevState);
	}

	verifyState(prevProps, prevState) {
		if (!prevProps || prevProps.entityProfile !== this.props.entityProfile) {
			// Handle entityProfile change
			const state = {
			};

			if (prevProps)
				this.setState(state);
			else {
				// Ignore warning about setting the state directly:
				// eslint-disable-next-line
				this.state = state; // Called from the constructor
			}
		}
	}

	render() {
		return (
			<div className="entry-table-container entity-profile-main-options" >
				<fieldset>
					<legend>Main</legend>
					<div className="entry-table-inner-container" >
						<table>
							<tbody>
								<EntryRow label="* Entity Name" title="Entity Name">
									<Input type="text" maxLength={200}
										name="Name"
										value={this.props.entityProfile.Name || ""}
										previousValue={this.props.previousEntityProfile.Name || ""}
										showDirty={!this.props.isNew}
										onChange={this.handleDataChange}
										autoFocus={this.props.isNew}
										disabled={this.props.disabled || this.props.isSpecialEntity}
									/>
								</EntryRow>
								<EntryRow label="Entity GUID">
									<span>{this.props.entityProfile.Guid || "<Generated when saved>"}</span>
								</EntryRow>
								{this.props.isSpecialEntity ? <Fragment /> : <Fragment>
									<EntryRow label="File Uploads Enabled?" title="Indicates if file uploads are allowed.">
										<Radio
											label="Enabled"
											name="Enabled"
											optionValue={true}
											checked={this.props.entityProfile.Enabled}
											title="Allow uploads."
											previousValue={this.props.previousEntityProfile.Enabled}
											onChange={this.handleDataChangeBool}
											showDirty={!this.props.isNew}
											disabled={this.props.disabled}
										/>
										<Radio
											label="Disabled"
											name="Enabled"
											optionValue={false}
											checked={!this.props.entityProfile.Enabled}
											title="Do NOT allow uploads."
											previousValue={!this.props.previousEntityProfile.Enabled}
											onChange={this.handleDataChangeBool}
											showDirty={!this.props.isNew}
											disabled={this.props.disabled}
										/>
									</EntryRow>
									<EntryRow label="Virtual Directory Template">
										<Input type="text" title={"Virtual directory (folder) template used to organize files.\nUse 'BundleFilename' as last node to group files uploaded as a bundle.\nExample: PartNumber/SerialNumber"} maxLength={1000}
											className="long-input"
											name="VirtualDirectoryTemplate"
											value={this.props.entityProfile.VirtualDirectoryTemplate || ""}
											previousValue={this.props.previousEntityProfile.VirtualDirectoryTemplate || ""}
											showDirty={!this.props.isNew}
											onChange={this.handleDataChange}
											disabled={this.props.disabled}
										/>
									</EntryRow>
								</Fragment>
								}
							</tbody>
						</table>
					</div>
				</fieldset>
			</div>
		);
	}

	handleDataChangeBool(e) {
		this.handleDataChange(e, true);
	}

	handleDataChange(e, isBool) {
		let { name, value } = extractDomElement(e.target, isBool);

		const entityProfile = {
			...this.props.entityProfile,
			[name]: value
		};

		this.props.setEntityProfile(entityProfile);
	}
}