

export function extractDomElement(element, isBool) {
	let { name, value, type, checked, files } = element;

	name = element.name.replace(/^_+|_+$/g, ''); // Trim begin and end underscores
	value = type === "checkbox" ? checked : type === "file" ? files[0] : value;

	if (isBool)
		value = typeof value === "boolean" ? value : value.toLowerCase() === "true";

	return { name, value, type, checked, files };
}

export function evaluate(code, valueDeclarations) {
	if (!code || !code.trim())
		return true; // no code

	// eslint-disable-next-line
	return eval((valueDeclarations || "") + code);
}

export function getValueDeclarations(metadata, metadataFields) {
	let declarations = "";

	// set value with "" as default
	metadataFields.forEach((metadataField) => {
		declarations += `const ${metadataField.FieldName} = "${(metadata[metadataField.FieldName] || "")}"; `;
	});

	return declarations;
}

export function moveArrayItemsByKey(direction, array, keyPropertyName, selectedRowKeys) {
	if (!selectedRowKeys || selectedRowKeys.length === 0)
		return array;

	let isChanged = false;

	let newArray = [...array];
	let shouldSkipNextMatch = false;
	for (let i = direction === 1 ? newArray.length - 1 : 0; direction === 1 ? i >= 0 : i < newArray.length; i -= direction) {
		const pluginProfile = newArray[i];
		if (selectedRowKeys.includes(pluginProfile[keyPropertyName])) {
			if (direction === 1 ? i >= newArray.length - 1 : i <= 0) {
				shouldSkipNextMatch = true;
				continue; // Already at the bottom/top
			}

			if (shouldSkipNextMatch)
				continue;

			// move up (swap with previous)
			const tempPluginProfile = newArray[i];
			newArray[i] = newArray[i + direction];
			newArray[i + direction] = tempPluginProfile;
			isChanged = true;
		}

		shouldSkipNextMatch = false;
	}

	if (!isChanged)
		return array; // no changes

	return newArray;
}

export function truncate(str, maxLength) {
	if (!maxLength || !str?.length || str.length <= maxLength)
		return str;

	return str.substring(0, maxLength) + "...";
}
