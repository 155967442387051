import React, { Fragment } from "react";
import Input from "../../Shared/Input"
import SelectFileType from "../../Shared/SelectFileType"
import EntryRow from "../../Shared/EntryRow"
import DatePicker from "../../Shared/DatePicker"
import Checkbox from "../../Shared/Checkbox"
import Radio from "../../Shared/Radio"
import Link from "../../Shared/Link";
import ComboBox from "../../Shared/ComboBox";
import TransitionalDiv from "../../Shared/TransitionalDiv";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class SearchCriteria extends React.Component {
	constructor(props) {
		super(props);

		this.NO_TAGS = "[no tags]";

		this.wildcardText = "Wild card characters allowed: * = multiple characters, ? = single character";
		this.listSearchText = "Separate multiple search terms with commas.";

		const initialView = props.path === "**" ? "flat" : "directories";

		this.state = {
			searchCriteria: { metadataFields: {}, view: initialView },
			previousSearchCriteria: { metadataFields: {}, view: initialView },
			show: false
		};

		this.handleSearchCriteriaChange = this.handleSearchCriteriaChange.bind(this);
		this.handleSearchCriteriaChange_Metadata = this.handleSearchCriteriaChange_Metadata.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.setSearchCriteria = this.setSearchCriteria.bind(this);
		this.handleShowHide = this.handleShowHide.bind(this);

		this.setSearchCriteria(false);
	}

	render() {
		return (
			<div className="entry-table-container search-criteria" onKeyPress={this.onKeyPress}>
				<fieldset>
					<legend><span><Link onClick={this.handleShowHide}>Search Criteria <i className={this.state.show ? "arrow up" : "arrow down"}></i></Link></span></legend>
					<TransitionalDiv show={this.state.show} transitions={{ maxHeight: {}, opacity: {} }}>
						<table>
							<tbody>
								<EntryRow label="Filename" title={`Filename\n${this.wildcardText}\n${this.listSearchText}`}>
									<Input type="text"
										className="long-input"
										name="filename"
										value={this.state.searchCriteria.filename || ""}
										previousValue={this.state.previousSearchCriteria.filename || ""}
										showDirty={true}
										placeholder="*.*"
										onChange={this.handleSearchCriteriaChange}
									/>
								</EntryRow>
								<EntryRow label="File Type" title="File Type">
									<SelectFileType name="fileTypeName"
										value={this.state.searchCriteria.fileTypeName || ""}
										previousValue={this.state.previousSearchCriteria.fileTypeName || ""}
										showDirty={true}
										onChange={this.handleSearchCriteriaChange}
										fileTypeNames={this.props.fileTypeNames}
									/>
								</EntryRow>
								{this.props.showEntitySearch === true ?
									<Fragment>
										<EntryRow label="Include entity tags" title="Entity tags to include." innerDivStyle={{ overflow: "visible" }}>
											<ComboBox
												name="includeEntityTags"
												value={(this.state.searchCriteria.includeEntityTags || []).map((tag) => { return { label: tag, value: tag } })}
												options={[this.NO_TAGS, ...(this.props.entityDetails.Tags ?? [])].map((tag) => { return { label: tag, value: tag } })}
												isClearable={true}
												isMulti={true}
												isSearchable={true}
												closeMenuOnSelect={true}
												backspaceRemovesValue={true}
												onChange={this.handleSearchCriteriaChange}
												showDirty={!this.props.isNew}
												disabled={this.props.disabled}
												isValidNewOption={false}
												className="combo-box-inline"
											/>
										</EntryRow>
										<EntryRow label="Exclude entity tags" title="Entity tags to exclude." innerDivStyle={{ overflow: "visible" }}>
											<ComboBox
												name="excludeEntityTags"
												value={(this.state.searchCriteria.excludeEntityTags || []).map((tag) => { return { label: tag, value: tag } })}
												options={[this.NO_TAGS, ...(this.props.entityDetails.Tags ?? [])].map((tag) => { return { label: tag, value: tag } })}
												isClearable={true}
												isMulti={true}
												isSearchable={true}
												closeMenuOnSelect={true}
												backspaceRemovesValue={true}
												onChange={this.handleSearchCriteriaChange}
												showDirty={!this.props.isNew}
												disabled={this.props.disabled}
												isValidNewOption={false}
												className="combo-box-inline"
											/>
										</EntryRow>
									</Fragment>
									: <Fragment />
								}

								{this.getMetadataFieldRows(this.props.allFileTypeMetadataFields, this.state.searchCriteria.fileTypeName)}

								<EntryRow label="Uploaded By" title={`Uploader domain\\username\n${this.wildcardText}\n${this.listSearchText}`}>
									<Input type="text"
										className="long-input"
										name="uploaderUsername"
										value={this.state.searchCriteria.uploaderUsername || ""}
										previousValue={this.state.previousSearchCriteria.uploaderUsername || ""}
										showDirty={true}
										placeholder="domain*\username*"
										onChange={this.handleSearchCriteriaChange}
									/>
								</EntryRow>
								<EntryRow label="Upload Date" title="Upload date range.">
									<DatePicker
										name="uploadDateStart"
										className="date-picker"
										value={this.state.searchCriteria.uploadDateStart || ""}
										previousValue={this.state.previousSearchCriteria.uploadDateStart || ""}
										showDirty={true}
										title="Upload date start"
										onChange={this.handleSearchCriteriaChange}
									/>
									<span> - </span>
									<DatePicker
										name="uploadDateEnd"
										className="date-picker"
										value={this.state.searchCriteria.uploadDateEnd || ""}
										previousValue={this.state.previousSearchCriteria.uploadDateEnd || ""}
										showDirty={true}
										title="Upload date end"
										onChange={this.handleSearchCriteriaChange}
									/>
								</EntryRow>
								<EntryRow label="Download Date" title="First download date range.">
									<DatePicker
										name="downloadDateStart"
										className="date-picker"
										value={this.state.searchCriteria.downloadDateStart || ""}
										previousValue={this.state.previousSearchCriteria.downloadDateStart || ""}
										showDirty={true}
										title="First download date start"
										onChange={this.handleSearchCriteriaChange}
										disabled={this.state.searchCriteria.notDownloaded}
									/>
									<span> - </span>
									<DatePicker
										name="downloadDateEnd"
										className="date-picker"
										value={this.state.searchCriteria.downloadDateEnd || ""}
										previousValue={this.state.previousSearchCriteria.downloadDateEnd || ""}
										showDirty={true}
										title="First download date end"
										onChange={this.handleSearchCriteriaChange}
										disabled={this.state.searchCriteria.notDownloaded}
									/>
									&nbsp;&nbsp;<Checkbox
										label="Only show files not downloaded"
										name="notDownloaded"
										title="Only show files that have not been downloaded"
										value={this.state.searchCriteria.notDownloaded || false}
										onChange={this.handleSearchCriteriaChange}
										previousValue={this.state.previousSearchCriteria.notDownloaded || false}
										showDirty={true} />
								</EntryRow>
								<EntryRow label="View" title="How to view the matching files.">
									<Radio
										label="Folders"
										name="view"
										title="Organize files into virtual folders based on metadata, if configured for this entity."
										optionValue="directories"
										value={this.state.searchCriteria.view}
										previousValue={this.state.previousSearchCriteria.view || ""}
										onChange={this.handleSearchCriteriaChange}
										showDirty={true} />
									<Radio
										label="Flat"
										name="view"
										title="Show files in a flat list without folders."
										optionValue="flat"
										value={this.state.searchCriteria.view}
										previousValue={this.state.previousSearchCriteria.view || ""}
										onChange={this.handleSearchCriteriaChange}
										showDirty={true} />
								</EntryRow>
								<EntryRow label="&nbsp;">
									<button onClick={this.setSearchCriteria}>Refresh</button>
								</EntryRow>
							</tbody>
						</table>
					</TransitionalDiv>
				</fieldset>
			</div >
		);
	}

	handleShowHide() {
		this.setState({ show: !this.state.show });
	}

	onKeyPress(e) {
		if (e && e.key === "Enter") {
			this.setSearchCriteria();
			e.preventDefault();
			return false;
		}
	}

	handleSearchCriteriaChange(e) {
		const { name, value } = extractDomElement(e.target);

		const searchCriteria = {
			...this.state.searchCriteria,
			[name]: value
		};

		this.setState({ searchCriteria: searchCriteria });
	}

	handleSearchCriteriaChange_Metadata(e) {
		const { name, value } = extractDomElement(e.target);

		const metadataFields = {
			...this.state.searchCriteria.metadataFields,
			[name]: value
		};

		const searchCriteria = {
			...this.state.searchCriteria,
			metadataFields: metadataFields
		};

		this.setState({ searchCriteria: searchCriteria });
	}


	getMetadataFieldRows(fileTypeMetadataFields, selectedFileType) {
		if (fileTypeMetadataFields === undefined)
			return (<Fragment />);

		let selectedFileTypeMetadataFields = null;
		if (selectedFileType)
			selectedFileTypeMetadataFields = this.props.fileTypeDetailsMap[selectedFileType].MetadataFields;
		const self = this;
		return fileTypeMetadataFields.map((fileTypeDetail) => {
			const disabled = !(!selectedFileType || selectedFileTypeMetadataFields.some(selected => selected.FieldName === fileTypeDetail.FieldName));
			let tooltip = null;
			if (fileTypeDetail.Tooltip) {
				tooltip = fileTypeDetail.Tooltip;
			}
			tooltip = tooltip || "";
			if (disabled) {
				tooltip += (tooltip ? " " : "") + "(Not valid for the selected file type)";
			}
			else {
				tooltip += (tooltip ? "\n" : "") + this.wildcardText + "\n" + this.listSearchText;
			}
			return (
				<EntryRow key={fileTypeDetail.FieldName} label={fileTypeDetail.DisplayName} title={tooltip}>
					<Input type="text"
						className="long-input"
						name={fileTypeDetail.FieldName}
						value={self.state.searchCriteria.metadataFields[fileTypeDetail.FieldName] || ""}
						previousValue={self.state.previousSearchCriteria.metadataFields[fileTypeDetail.FieldName] || ""}
						showDirty={true}
						placeholder="*"
						onChange={self.handleSearchCriteriaChange_Metadata}
						disabled={disabled}
					/>
				</EntryRow>
			);
		});
	}

	setSearchCriteria(setPreviousSearchCriteria) {
		this.props.setSearchCriteria({ ...this.state.searchCriteria });
		if (setPreviousSearchCriteria !== false)
			this.setState({ previousSearchCriteria: this.state.searchCriteria });
	}
}
