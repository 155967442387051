import React, { Fragment } from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import EntryRow from "../../Shared/EntryRow";
import Input from "../../Shared/Input";
import TextArea from "../../Shared/TextArea";
import RegExTextArea from "../../Shared/RegExTextArea";
import Checkbox from "../../Shared/Checkbox";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import getFieldNameFromDisplayName from "../../Helpers/getFieldNameFromDisplayName";
import { extractDomElement } from "../../Helpers/SharedFunctions";

export default class MetadataFieldEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editingMetadataField: this.props.editingMetadataField,
			openShowJsonModal: false,
			editingMetadataFieldForShowJson: null
		};

		this.handleDataChange = this.handleDataChange.bind(this);
		this.setFieldNameFromDisplayName = this.setFieldNameFromDisplayName.bind(this);
		this.save = this.save.bind(this);
		this.openShowJsonModal = this.openShowJsonModal.bind(this);
		this.setEditingMetadataFromShowJson = this.setEditingMetadataFromShowJson.bind(this);

		this.requiredGroupTooltip = "Required field group name. Leave blank for none.\n"
			+ "Enter the same group name for all fields in the required group.\n"
			+ "Special behavior can be specified by adding a colon ':' followed by the number of required fields.\n"
			+ "Example group names and resulting behavior (enter group names without quotes):\n"
			+ "   'Group A' - Require exactly 1 field in the group to be populated. (default behavior)\n"
			+ "   'Group B: 2' - Require exactly 2 fields in the group to be populated.\n"
			+ "   'Group C: 2+' - Require 2 or more fields in the group to be populated.";

		this.availableConditionTooltip = "Only show this field if the provided condition evaluates to true.\n"
			+ "For basic conditions: == for equals, != for not equal\n"
			+ "For complex conditions: && for 'and', || for 'or'\n"
			+ "Parentheses may be used to group sections.\n"
			+ "\n"
			+ "Examples:\n"
			+ "    OtherField1 != \"value1\"\n"
			+ "    OtherField2 == \"value2\" && OtherField3 != \"value3\"\n"
			+ "    OtherField4 == \"value4\" || OtherField5 != \"value5\"\n"
			+ "    (OtherField4 == \"value4\" || OtherField5 != \"value5\") && OtherField1 != \"value1\"\n"
			+ "\n"
			+ "Note: Required field setting will be ignored if the field evaluates to NOT available.\n"
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingMetadataField
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingMetadataField: this.props.editingMetadataField,
				editingMetadataFieldName: this.props.editingMetadataField.FieldName
			});
		}
	}

	render() {
		const editingMetadataField = this.state.editingMetadataField || {};
		const previousEditingMetadataField = this.props.editingMetadataField || {};
		const fieldName = previousEditingMetadataField.FieldName || "[New]";
		const isExisting = !!previousEditingMetadataField.FieldName;
		const heading = `Metadata Field: ${fieldName}`;
		const isLocked = this.props.fileTypeSyncWithSystemFileType && !this.props.isSystemFileTypeEntity && editingMetadataField.SyncWithSystemFileType === null;
		const disabled = this.props.disabled || isLocked || (this.props.fileTypeSyncWithSystemFileType && editingMetadataField.SyncWithSystemFileType === true && !this.props.isSystemFileTypeEntity);

		return (
			<BetterDialog title={heading} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Details</legend>
						<div className="entry-table-inner-container">
							<table>
								<tbody>
									{(this.props.fileTypeSyncWithSystemFileType || this.props.isSystemFileTypeEntity) ?
										<EntryRow label={this.props.isSystemFileTypeEntity ? "Sync Lock Field?" : "Sync with System File Type?"}
											title={this.props.isSystemFileTypeEntity ?
												"Indicates if this metadata field will be sync locked (no changes) when applied to entities."
												: "Indicates if this metadata field will use the values from the containing system file type of the same name.\nEnabling this will replace the current definition with data from the system file type metadata field when saved, if it exists."}>
											<Checkbox
												name="SyncWithSystemFileType"
												value={editingMetadataField.SyncWithSystemFileType || editingMetadataField.SyncWithSystemFileType === null || false}
												previousValue={previousEditingMetadataField.SyncWithSystemFileType || editingMetadataField.SyncWithSystemFileType === null || false}
												onChange={this.handleDataChange}
												showDirty={isExisting}
												disabled={this.props.disabled || isLocked}
											/>
											{isLocked ? <span>Locked by the system file type.</span> : <Fragment />}
										</EntryRow>
									: <Fragment />
									}
									<EntryRow label="* Display Name" title="Display Name visible to upload and download users.">
										<Input type="text" maxLength={50}
											name="DisplayName"
											value={editingMetadataField.DisplayName}
											previousValue={previousEditingMetadataField.DisplayName}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen && !isExisting}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label={`* ${(this.props.isSystemFileTypeEntity || (editingMetadataField.SyncWithSystemFileType ?? true)) ? "System " : ""}Field Name`} title={"Field Name used internally to identify the metadata field.\nAlpha-numeric characters only."}>
										<Input type="text" maxLength={20}
											name="FieldName"
											pattern="^[A-Za-z_][A-Za-z0-9_]*$"
											value={editingMetadataField.FieldName}
											previousValue={previousEditingMetadataField.FieldName}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											onFocus={this.setFieldNameFromDisplayName}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label="Tooltip" title="Tooltip displayed to the user on the upload page.">
										<TextArea
											maxLength={1024}
											name="Tooltip"
											value={editingMetadataField.Tooltip}
											previousValue={previousEditingMetadataField.Tooltip}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label="Required?" title="Indicates if this metadata field is required.">
										<Checkbox
											name="IsRequired"
											value={editingMetadataField.IsRequired || false}
											previousValue={previousEditingMetadataField.IsRequired || false}
											onChange={this.handleDataChange}
											disabled={disabled}
											showDirty={isExisting} />
									</EntryRow>
									<EntryRow label="Required Field Group" title={this.requiredGroupTooltip}>
										<Input type="text"
											maxLength={50}
											name="RequiredGroupName"
											value={editingMetadataField.RequiredGroupName}
											previousValue={previousEditingMetadataField.RequiredGroupName}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label="Available Condition" title={this.availableConditionTooltip}>
										<TextArea
											maxLength={8000}
											name="AvailableCondition"
											value={editingMetadataField.AvailableCondition}
											previousValue={previousEditingMetadataField.AvailableCondition}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label="Field RegEx" title="Regular expression used to validate field values.">
										<RegExTextArea
											name="FieldRegEx"
											value={editingMetadataField.FieldRegEx}
											previousValue={previousEditingMetadataField.FieldRegEx}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
											testValuesName="FieldRegExTestValues"
											testValues={editingMetadataField.FieldRegExTestValues}
											previousTestValues={previousEditingMetadataField.FieldRegExTestValues}
										/>
									</EntryRow>
									<EntryRow label="Is Static Value?" title="Indicates if this field will contains a static value that cannot be changed by the uploader.">
										<Checkbox
											name="IsStaticValue"
											value={editingMetadataField.IsStaticValue || false}
											previousValue={previousEditingMetadataField.IsStaticValue || false}
											onChange={this.handleDataChange}
											disabled={disabled}
											showDirty={isExisting} />
									</EntryRow>
									<EntryRow label="Static Value" title="Static value will always be set as the initial field value for new uploads." visible={editingMetadataField.IsStaticValue === true}>
										<Input type="text" maxLength={1024}
											name="StaticValue"
											value={editingMetadataField.StaticValue}
											previousValue={previousEditingMetadataField.StaticValue}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
										/>
									</EntryRow>
									<EntryRow label="Extract From Filename?" title="Extract From Filename (The filename regular expression must use named matching)" visible={!editingMetadataField.IsStaticValue}>
										<Checkbox
											name="CanExtractFromFilename"
											value={editingMetadataField.CanExtractFromFilename || false}
											previousValue={previousEditingMetadataField.CanExtractFromFilename || false}
											onChange={this.handleDataChange}
											disabled={disabled}
											showDirty={isExisting} />
									</EntryRow>
									<EntryRow label="Value Options" visible={editingMetadataField.ValueOptions} title={"A list of valid values to show as a drop down list for this metadata field.\nOnly values in this list will be accepted from an uploading user.\nEnter one value per line or a list of values separated by commas.\nExtra leading and trailing spaces will be removed."}>
										<TextArea
											maxLength={8000}
											name="ValueOptions"
											value={editingMetadataField.ValueOptions}
											previousValue={previousEditingMetadataField.ValueOptions}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											disabled={disabled}
										/>
									</EntryRow>
									{this.props.isFileBundle ?
										<EntryRow label="Bundled file value must match" title="If enabled, the metadata field value extracted from a bundle file must match for all files contained in the bundle.">
											<Checkbox
												name="BundledFileValueMustMatch"
												value={editingMetadataField.BundledFileValueMustMatch || false}
												previousValue={previousEditingMetadataField.BundledFileValueMustMatch || false}
												onChange={this.handleDataChange}
												disabled={disabled}
												showDirty={isExisting} />
										</EntryRow>
										: <Fragment />
									}
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<ShowJsonModal
					heading={`${heading} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.editingMetadataFieldForShowJson}
					setValue={this.setEditingMetadataFromShowJson}
					showDirty={isExisting}
					disabled={disabled}
				/>
				<BetterDialogButtons>
					{(this.props.disabled || (disabled && isLocked)) ? <Fragment /> : <button onClick={this.save}>OK</button>}
					<button onClick={this.openShowJsonModal} title="Show JSON.">Show JSON</button>
					<button onClick={this.props.closeModal}>{(this.props.disabled || (disabled && isLocked)) ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);

		const editingMetadataField = {
			...this.state.editingMetadataField,
			[name]: value
		};

		this.setState({ editingMetadataField });
	}

	setFieldNameFromDisplayName() {
		const cleanFieldName = getFieldNameFromDisplayName(this.state.editingMetadataField.DisplayName, this.state.editingMetadataField.FieldName);

		if (cleanFieldName === this.state.editingMetadataField.FieldName)
			return;

		const editingMetadataField = {
			...this.state.editingMetadataField,
			FieldName: cleanFieldName
		};

		this.setState({ editingMetadataField });
	}

	save() {
		if (this.props.setMetadataField(this.state.editingMetadataField, this.state.editingMetadataFieldName))
			this.props.closeModal();
	}

	openShowJsonModal() {
		this.setState({
			openShowJsonModal: true,
			editingMetadataFieldForShowJson: { ...this.state.editingMetadataField }
		});
	}

	setEditingMetadataFromShowJson(editingMetadataField) {
		editingMetadataField = {
			...editingMetadataField
		};

		this.setState({ editingMetadataField, openShowJsonModal: false });
	}
}