import React from "react";
import { Redirect } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { useApiService } from "../../Hooks/useApiService";
import { UserContext } from "../../Contexts/UserContext";
import LoadingWrapper from "../../Shared/LoadingWrapper";
import { handleApiError } from "../../Shared/ErrorHandlers";
import EntityFilterLinkList from "../../Shared/EntityFilterLinkList";
import _ from "lodash";

export default class EntityProfileChooseEntity extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);

		this.LINK_URL_BASE = "/entityProfile/";

		this.state = {
			isInitialized: false,
			isLoading: true,
			entities: null,
			filteredEntities: null,
			selectedEntity: null
		};

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	initialize() {
		this.apiService.getEntities({ permissionName: "View Entity Profile", includeDisabledRecords: true, includeAllEntities: true },
			(response) => {
				const entities = response.data.Entities;
				// If there is only 1 entity then set it immediately
				let selectedEntity = !!entities && entities.length === 1 ? entities[0] : null;

				this.setState({
					isLoading: false,
					entities: entities.sort((a, b) => a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1),
					selectedEntity: selectedEntity
				});
			},
			(error) => {
				this.setState({ isLoading: false });
				handleApiError(error);
			}
		);
		this.setState({ isInitialized: true });
	}

	render() {
		if (this.context.getWebUserSession() && !this.state.isInitialized)
			_.defer(this.initialize.bind(this));

		if (this.state.selectedEntity) {
			const redirectUrl = `${this.LINK_URL_BASE}${this.state.selectedEntity.Guid}`;
			return (<Redirect to={redirectUrl} />);
		}

		return (
			<Layout config={this.props.config} subtitle="Choose entity to view/edit profile">
				<LoadingWrapper isLoading={this.state.isLoading}>
					<h1>Choose an entity to view/edit profile</h1>
					<EntityFilterLinkList
						entities={this.state.entities}
						urlBase={this.LINK_URL_BASE}
					/>
				</LoadingWrapper>
			</Layout>
		);
	}
}
