import React from "react";
import BetterDialog, { BetterDialogButtons } from "../../Shared/BetterDialog";
import Checkbox from "../../Shared/Checkbox";
import Select from "../../Shared/Select";
import SettingDefinitionsList from "./SettingDefinitionsList";
import Input from "../../Shared/Input";
import TextArea from "../../Shared/TextArea";
import EntryRow from "../../Shared/EntryRow";
import { extractDomElement } from "../../Helpers/SharedFunctions";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import ShowJsonModal from "../../Shared/ShowJsonModal";
import _ from "lodash";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";

export default class PluginDefinitionEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			editingPlugin: this.props.editingPlugin,
			editingPluginName: null,
			openShowJsonModal: false
		};

		this.save = this.save.bind(this);
		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);
		this.handleDataChange = this.handleDataChange.bind(this);
		this.setSettingDefinitions = this.setSettingDefinitions.bind(this);
		this.openShowJsonModal = this.openShowJsonModal.bind(this);
		this.getPluginDefinitionForShowJson = this.getPluginDefinitionForShowJson.bind(this);
		this.setPluginDefinitionFromShowJson = this.setPluginDefinitionFromShowJson.bind(this);
		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		// When opened, save the editingPlugin
		if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
			this.setState({
				editingPlugin: this.props.editingPlugin,
				editingPluginName: this.props.editingPlugin.PluginName,
				openShowJsonModal: false
			});
		}
	}

	render() {
		const editingPlugin = this.state.editingPlugin || { PluginS3Bucket: {} };
		const previousEditingPlugin = this.props.editingPlugin || { PluginS3Bucket: {} };
		const pluginName = previousEditingPlugin.PluginName || "[New]";
		const isExisting = !!previousEditingPlugin.PluginName;
		const regionEndpointOptions = [
			{ value: null },
			{ value: "us-gov-west-1", label: "us-gov-west-1 - AWS GovCloud (US West)" },
			{ value: "us-gov-east-1", label: "us-gov-east-1 - AWS GovCloud (US East)" }
		];
		const formatPocLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}

		return (
			<BetterDialog title={`Plugin Name: ${pluginName}`} open={this.props.isOpen}>
				<div className="entry-table-container">
					<fieldset>
						<legend>Plugin Definition</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="Plugin ID" style={{ display: "none" }}>
										<span>{previousEditingPlugin.PluginId || "[New]"}</span>
									</EntryRow>
									<EntryRow label="* Plugin Name" title="Plugin Name">
										<Input type="text" maxLength={100}
											name="PluginName"
											value={editingPlugin.PluginName || ""}
											previousValue={previousEditingPlugin.PluginName || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
											autoFocus={this.props.isOpen && isExisting}
										/>
									</EntryRow>
									<EntryRow label="* S3 Bucket Name" title="S3 Bucket Name">
										<Input type="text" maxLength={100}
											name="PluginS3Bucket.Name"
											value={editingPlugin.PluginS3Bucket.Name || ""}
											previousValue={previousEditingPlugin.PluginS3Bucket.Name || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="* S3 Bucket Region" title="S3 Region Endpoint">
										<Select
											name="PluginS3Bucket.RegionEndpointName"
											value={editingPlugin.PluginS3Bucket.RegionEndpointName || ""}
											previousValue={previousEditingPlugin.PluginS3Bucket.RegionEndpointName || ""}
											showDirty={isExisting}
											options={regionEndpointOptions}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Copy Uploaded Files to S3" title="Copy uploaded files to S3. Bundles and non-bundles but not extracted files. If disabled, only the file details file will be sent to the bucket.">
										<Checkbox
											name="CopyFileToPluginS3Bucket"
											value={editingPlugin.CopyFileToPluginS3Bucket || ""}
											previousValue={previousEditingPlugin.CopyFileToPluginS3Bucket || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Copy Extracted Files to S3" title={"Copy uploaded non-bundle files and files extracted from uploaded bundle files to S3.\nWill not copy uploaded bundle files that files were extracted from."}>
										<Checkbox
											name="CopyExtractedFilesToPluginS3Bucket"
											value={editingPlugin.CopyExtractedFilesToPluginS3Bucket || ""}
											previousValue={previousEditingPlugin.CopyExtractedFilesToPluginS3Bucket || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Create Details For Extracted" title={"When a bundle file is uploaded, create an individual 'details' file for each extracted file in addition to the for the bundle file's 'details' file.\nThe ExtractedFiles array will still be present in the bundle file's details."}>
										<Checkbox
											name="CreateDetailsFileForExtractedFiles"
											value={editingPlugin.CreateDetailsFileForExtractedFiles || ""}
											previousValue={previousEditingPlugin.CreateDetailsFileForExtractedFiles || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<div className="entry-table-container">
					<fieldset>
						<legend>Plugin Information</legend>
						<div className="entry-table-inner-container" >
							<table>
								<tbody>
									<EntryRow label="Description" title="Description">
										<TextArea
											name="Description"
											value={editingPlugin.Description || ""}
											previousValue={previousEditingPlugin.Description || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Code repository URL" title="Code repository URL">
										<Input type="text" maxLength={200}
											name="CodeRepoUrl"
											value={editingPlugin.CodeRepoUrl || ""}
											previousValue={previousEditingPlugin.CodeRepoUrl || ""}
											pattern="^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$"
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="Security scorecard URL" title={"URL to the security scorecard for this plugin.\nLearn more here: https://wiki.viasat.com/display/SECENG/Security+Scorecard"}>
										<Input type="text" maxLength={200}
											name="SecurityScorecardUrl"
											value={editingPlugin.SecurityScorecardUrl || ""}
											previousValue={previousEditingPlugin.SecurityScorecardUrl || ""}
											pattern="^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$"
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
									<EntryRow label="POC" title="Point of contact - author or primary contact for this plugin">
										<ComboBoxAsync
											className="poc-combo-box"
											name="POC"
											value={editingPlugin.POC}
											previousValue={previousEditingPlugin.POC}
											showDirty={isExisting}
											loadOptions={_.debounce(this.loadUsernameOptions, 250)}
											isSearchable={true}
											onChange={this.handleDataChange}
											noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
											formatOptionLabel={formatPocLabel}
											isClearable={true}
											disabled={this.props.disabled}
										/>
									</EntryRow>
									<EntryRow label="Reusability Description" title="Reusability description">
										<TextArea
											name="ReusabilityDescription"
											value={editingPlugin.ReusabilityDescription || ""}
											previousValue={previousEditingPlugin.ReusabilityDescription || ""}
											showDirty={isExisting}
											onChange={this.handleDataChange}
										/>
									</EntryRow>
								</tbody>
							</table>
						</div>
					</fieldset>
				</div>
				<SettingDefinitionsList
					title="Entity Setting Definitions"
					name="EntitySettingDefinitions"
					settingDefinitions={editingPlugin.EntitySettingDefinitions || ""}
					previousValue={previousEditingPlugin.EntitySettingDefinitions || ""}
					isExisting={isExisting}
					className={isExisting && editingPlugin.EntitySettingDefinitions !== previousEditingPlugin.EntitySettingDefinitions ? "is-dirty" : null}
					setSettingDefinitions={this.setSettingDefinitions}
				/>
				<SettingDefinitionsList
					title="User Setting Definitions"
					name="UserSettingDefinitions"
					settingDefinitions={editingPlugin.UserSettingDefinitions || ""}
					previousValue={previousEditingPlugin.UserSettingDefinitions || ""}
					isExisting={isExisting}
					className={isExisting && editingPlugin.UserSettingDefinitions !== previousEditingPlugin.UserSettingDefinitions ? "is-dirty" : null}
					setSettingDefinitions={this.setSettingDefinitions}
				/>
				<ShowJsonModal
					heading={`${pluginName} (JSON)`}
					isOpen={this.state.openShowJsonModal}
					closeModal={() => this.setState({ openShowJsonModal: false })}
					value={this.state.pluginDefinitionForShowJson}
					setValue={this.setPluginDefinitionFromShowJson}
					showDirty={isExisting}
					disabled={this.props.disabled}
				/>
				<BetterDialogButtons>
					<button onClick={this.save}>Save</button>
					<button onClick={this.openShowJsonModal} title="Show JSON.">Show JSON</button>
					<button onClick={this.props.closeModal}>{this.props.disabled ? "Close" : "Cancel"}</button>
				</BetterDialogButtons>
			</BetterDialog>
		);
	}

	openShowJsonModal() {
		this.setState({
			openShowJsonModal: true,
			pluginDefinitionForShowJson: this.getPluginDefinitionForShowJson(this.state.editingPlugin)
		});
	}

	getPluginDefinitionForShowJson(pluginDefinition) {
		pluginDefinition = { ...pluginDefinition };

		// Exclude the following properties from the JSON view since they may not transfer between environments well
		delete pluginDefinition.PluginId;
		delete pluginDefinition.PluginS3Bucket;

		return pluginDefinition;
	}

	setPluginDefinitionFromShowJson(pluginDefinition) {
		const editingPlugin = {
			...this.state.editingPlugin,
			...this.getPluginDefinitionForShowJson(pluginDefinition)
		};

		this.setState({
			openShowJsonModal: false,
			editingPlugin: editingPlugin,
			editingPluginName: editingPlugin.PluginName
		});
	}

	setSettingDefinitions(settingDefinitions, propertyName) {
		const editingPlugin = {
			...this.state.editingPlugin,
			[propertyName]: settingDefinitions
		};

		this.setState({ editingPlugin });
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers("Viasat", inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					const value = `${domainUser.FirstName || ""} ${domainUser.LastName || ""} <${domainUser.EmailAddress}>`;
					return {
						value: value,
						label: value,
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
				this.setState({ isGettingDomainOptions: false });
			}
		);
	}

	handleDataChange(e) {
		const { name, value } = extractDomElement(e.target);
		// Handle object properties with a dot (.) separator
		const names = name.split(".");

		const name1 = names[0];
		const name2 = names[1];

		const editingPlugin = {
			...this.state.editingPlugin,
		};

		editingPlugin[name1] = name2 ? { ...editingPlugin[name1] } : value;
		if (name2) {
			editingPlugin[name1][name2] = value;
		}

		this.setState({ editingPlugin });
	}

	save() {
		const editingPlugin = this.state.editingPlugin

		if (!editingPlugin.PluginName) {
			alert("Plugin name is required!");
			return;
		}
		if (!editingPlugin.PluginS3Bucket.Name || !editingPlugin.PluginS3Bucket.RegionEndpointName) {
			alert("S3 bucket information is required!");
			return;
		}
		const me = this;
		this.props.savePluginDefinition(editingPlugin, () => { me.props.closeModal(); });
	}
}
