import axios from "axios";

export function createAxiosApi(apiBaseUrl) {
	const api = axios.create({
		baseURL: `${apiBaseUrl}`,
		withCredentials: true // include the web user session cookie
	});

	const headerSpinnerId = "#HeaderSpinner";
	window.$(headerSpinnerId).hide();

	let activeCounter = 0;
	const setApiActive = (isActive) => {
		activeCounter += isActive ? 1 : -1;

		if (activeCounter === 1) {
			window.$(headerSpinnerId).show();
		}
		else if (activeCounter <= 0) {
			window.$(headerSpinnerId).hide();
		}
	}

	// Add a request interceptor
	api.interceptors.request.use(function (config) {
		setApiActive(true);
		return config;
	}, function (error) {
		setApiActive(false);
		return Promise.reject(error);
	});

	// Add a response interceptor
	api.interceptors.response.use(function (response) {
		setApiActive(false);
		return response;
	}, function (error) {
		setApiActive(false);
		return Promise.reject(error);
	});

	return api;
}
