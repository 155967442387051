import React from "react";
import Layout from "../../Layout/Layout";
import FileList from "./FileList";
import FileListItem from "./FileListItem";

export default class UploadResources extends React.Component {
	render() {
		const apiBaseUrl = `${this.props.config.apiBaseUrl}/StaticDownloads/`;

		const files = [
			{ filename: "ViasatCurlUploadBash.zip", description: "Bash script that uses curl to upload to Viasat OSQRE." },
			{ filename: "ViasatUploader.zip", description: "Cross-platform application that can be used to upload files to Viasat OSQRE. Includes a batch file template that can be used to batch upload files. (requires .NET Core 2.1+)" },
			{ filename: "ViasatUploaderSource.zip", description: "C# source code for the ViasatUploader application. Application may be customized as needed. Implements the Viasat uploader client library." },
			{ filename: "ViasatUploaderClientLibrary.zip", description: "Viasat UploaderClient library used to integrate Viasat OSQRE uploads to a 3rd party application (.NET Standard 2.0 DLL with XML documentation." },
			{ filename: "ViasatUploaderClientLibraryDocumentation.zip", description: "Viasat UploaderClient library documentation in HTML and CHM format." },
		];

		return (
			<Layout config={this.props.config} subtitle="Upload Resources" requireWebUserSession={false}>
				<h1>Upload Resources</h1>
				<div>
					The following resources are provided by Viasat to provide information and sample solutions for uploading to Viasat's OSQRE system.
				</div>
				<div>
					<FileList>
						{files.map((file) => <FileListItem key={file.filename} filename={file.filename} url={`${apiBaseUrl}${file.filename}`} description={file.description} />)}
					</FileList>
				</div>
			</Layout>
		);
	}
}
