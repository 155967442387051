import React, { Fragment } from "react";
import { UserContext } from "../../Contexts/UserContext";
import SelecTable from "../../Shared/SelecTable";
import ComboBoxAsync from "../../Shared/ComboBoxAsync";
import { ContextMenu, MenuItem } from "react-contextmenu";
import "../../Content/react-contextmenu.scss";
import clsx from "clsx";
import _ from "lodash";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";

export default class UserGroupUsersList extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);

		const uniqueName = Math.random().toString(36).substring(2);
		this.usersListRowContextMenuName = `UsersListRowContextMenu_${uniqueName}`;

		this.state = {
			selectedUsersRowKeyList: [],
			setFocusToComboBox: false
		};

		this.userTableColumns = [
			{ fieldName: "DomainUsername", displayName: "Username" },
			{ fieldName: "Name", displayName: "Name", customValue: (rowObject) => `${rowObject["FirstName"] || ""} ${rowObject["LastName"] || ""}` },
			{ fieldName: "Email", displayName: "Email", customValue: (rowObject) => `${rowObject["EmailAddress"] || ""}` },
			{ fieldName: "Enabled", displayName: "Enabled?", customValue: (rowObject, fieldName) => rowObject[fieldName] === true ? "Enabled" : "Disabled" }
		];

		this.setSelectedUserRowKeys = this.setSelectedUserRowKeys.bind(this);
		this.loadUsernameOptions = this.loadUsernameOptions.bind(this);
		this.addUser = this.addUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.users
			&& prevProps.users
			&& this.props.users !== prevProps.users
			&& this.props.users.length > prevProps.users.length) { // We just added a user 
			this.setState({ disableComboBox: true, setFocusToComboBox: false }); // disable and reset the focus to combo-box
			_.defer(() => { this.setState({ disableComboBox: false, setFocusToComboBox: true }); }); // enable and show with focus to combo-box
		}
		else if (this.state.setFocusToComboBox) {
			this.setState({ setFocusToComboBox: false }); // reset the focus
		}
	}

	render() {
		const formatOptionLabel = (option, context) => {
			return context.context === "value" ? option.value : option.label;
		}

		return (
			<div>
				<div className={clsx("list-table-container", "entity-profile-user-list", this.props.usersClassName)} >
					<fieldset>
						<legend>{"Group Member Users"}</legend>
						{!this.props.domainName ? <span>Select a domain to add users.</span> :
							<Fragment>
								<div className="entry-table-inner-container" >
									<SelecTable className="profile-table"
										columns={this.userTableColumns}
										rowObjects={this.props.users}
										getRowKey={(rowData) => rowData.DomainUsername}
										selectMode={this.props.disabled ? "single" : "multi"}
										setSelectedRowKeys={this.setSelectedUserRowKeys}
										contextMenuId={this.usersListRowContextMenuName}
									/>
									{this.props.disabled ?
										!!this.props.ldapGroupName ?
											<Fragment>
												<br />
												<span>Users cannot be edited when "Sync LDAP group" is set.</span>
											</Fragment>
											:
											<Fragment />
										:
										<Fragment>
											<br />
											<ComboBoxAsync
												className="combo-box-user-group-user-with-placholder"
												name="Username"
												loadOptions={_.debounce(this.loadUsernameOptions, 250)}
												isSearchable={true}
												onChange={this.addUser}
												noOptionsMessage={(context) => context.inputValue ? "No matching users!" : "Start typing to show options..."}
												formatOptionLabel={formatOptionLabel}
												autoFocus={this.state.setFocusToComboBox}
												disabled={this.props.disabled || this.state.disableComboBox}
											// placeholder="Choose a user to add..." -- placeholder not supported. Added in CSS via className content
											/>

											<ContextMenu id={this.usersListRowContextMenuName}>
												<MenuItem onClick={(e, data) => _.defer(() => { this.deleteUser(data.selectedRowKeys); })}
													disabled={this.props.disabled || this.state.selectedUsersRowKeyList.length === 0}>Delete</MenuItem>
											</ContextMenu>
										</Fragment>
									}
								</div>
							</Fragment>
						}
					</fieldset>
				</div>
			</div>
		);
	}

	setSelectedUserRowKeys(selectedUserRowKeys) {
		this.setState({ selectedUsersRowKeyList: Object.getOwnPropertyNames(selectedUserRowKeys) });
	}

	loadUsernameOptions(inputValue, callback) {
		this.apiService.getDomainUsers(this.props.domainName, inputValue,
			(response) => {
				const domainUserOptions = response.data.DomainUsers.map(domainUser => {
					return {
						value: domainUser.Username,
						label: `${domainUser.FirstName || ""} ${domainUser.LastName || ""} (${domainUser.Username})`,
						data: domainUser
					};
				});
				callback(domainUserOptions);
			},
			(error) => {
				handleApiError(error);
			}
		);
	}

	addUser(e) {
		const { data } = e.target;
		let users = this.props.users || [];
		if (users.some((user) => user.DomainUsername.toLowerCase() === data.DomainUsername.toLowerCase())) {
			alert(`User '${data.DomainUsername}' is already in the list.`);
			return;
		}

		users = [...users, data];

		this.props.setUsers(users);
	}

	deleteUser(selectedRowKeys) {
		selectedRowKeys = Object.getOwnPropertyNames(selectedRowKeys);

		if (window.confirm(`Remove selected user${selectedRowKeys.length > 1 ? "s" : ""} from the user group?`)) {
			const users = (this.props.users || []).filter((userEntityProfile) => !selectedRowKeys.some((selectedRowKey) => selectedRowKey === userEntityProfile.DomainUsername));

			this.props.setUsers(users);
		}
	}
}