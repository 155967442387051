import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import Link from "../Shared/Link";
import { UserContext } from "../Contexts/UserContext";
import ViasatLogoSpinner from "../Shared/ViasatLogoSpinner";
import Auth from "../Shared/Auth";
import "./Header.scss";

export default class Header extends React.Component {
	static contextType = UserContext;

	render() {
		let webUserSession = null;
		if (this.props.useApi !== false)
			webUserSession = this.context.getWebUserSession();

		if (this.props.disableUserPermissions && webUserSession) {
			webUserSession = { ...webUserSession };
			webUserSession.UserDetails = { ...webUserSession.UserDetails, EntityPermissionsList: [] };
		}

		// redirectIfSingleMenuItem
		const permittedMenuItems = this.getPermittedMenuItems(webUserSession)
		if (this.props.redirectIfSingleMenuItem && permittedMenuItems && permittedMenuItems.length === 1 && permittedMenuItems[0].menuItems.length === 1)
			return (<Redirect to={permittedMenuItems[0].menuItems[0].path} />);

		const userDisplayName = this.getUserDisplayName(webUserSession);

		return (
			<div className="fw">
				{/* HEADER */}
				<div className="hdr-bg"></div>
				<div className="hdr fw-vp">
					<div className="hdr-brand">
						<a href="/"><img src={require("../ViasatUx/images/viasat-grd-logo.png")} className="" alt="Viasat Logo : Click to go back to homepage" /></a>
						<ViasatLogoSpinner id="HeaderSpinner" size="17px" style={{ position: "absolute", top: "18px", left: "78px", display: "none" }} />
						<Auth className="app-title">{this.props.config.appName}</Auth>
					</div>
					{this.getLogOutLink(userDisplayName)}
					{this.props.showCuiBanner ?
						(<div className="header-cui-banner">This site contains CUI material. Access is restricted.</div>)
						: <Fragment />}
				</div>
				{/* TOP NAVBAR */}
				<div className="hdr-navbar">
					<div className="navbar yamm fw-vp">
						<div className="navbar-header">
							<div className="hdr-brand hide-desktop">
								<a href="/"><img src={require("../ViasatUx/images/viasat-grd-logo.png")} className="" alt="Viasat Logo : Click to go back to homepage" /></a>
								<Auth className="app-title">{this.props.config.appName}</Auth>
								{this.props.showCuiBanner ?
									(<div className="header-cui-banner-mobile">This site contains CUI material. Access is restricted.</div>)
									: <Fragment />}
							</div>
							{webUserSession ? (
								<div data-toggle="collapse" data-target="#navbar-collapse-grid" className="navbar-toggle collapsed" tabIndex="0" touchstart="">
									<div className="menu-text">Menu</div>
									<div className="menu-icon">
										<span className="icon-bar top-bar"></span>
										<span className="icon-bar middle-bar"></span>
										<span className="icon-bar bottom-bar"></span>
									</div>
								</div>
							) : <Fragment />}
						</div>
						<div id="navbar-collapse-grid" className="navbar-collapse collapse">
							<ul className="nav navbar-nav" role="tablist">
								{/* Navigation items */}
								{this.menuItems(permittedMenuItems)}

								<li className="hide-desktop">
									<Link to="/logout" className="utility-nav-mobile utility-nav-logout">{userDisplayName} (Logout)</Link>
								</li>
							</ul>
						</div>
					</div >
				</div >
				{/* ** TOP NAVBAR ** */}
			</div >
		);
	}

	getLogOutLink(userDisplayName) {
		if (!userDisplayName)
			return (<Fragment />);

		return (
			<div className="hdr-utility">
				<ul className="nav navbar-nav navbar-right">
					<li className="btn btn-hdr avatar-container">
						<Link to="/logout" className="logout-link">{userDisplayName} (Logout)</Link>
					</li>
				</ul>
			</div>
		);
	}

	menuItems(permittedMenuItems) {
		if (!permittedMenuItems)
			return (<Fragment />);

		return (
			<Fragment>
				{permittedMenuItems.map((titleMenuItem) => {
					return (
						<li key={titleMenuItem.title} className="dropdown yamm classic "><Link to={titleMenuItem.path ? titleMenuItem.path : " "} data-toggle="dropdown" className="dropdown-toggle" aria-expanded="false" touchstart="">{titleMenuItem.title} <span className="nav-arrow"></span></Link>
							<ul className="dropdown-menu">
								{titleMenuItem.menuItems.map((menuItem) => {
									return (
										<li key={menuItem.title}>
											<Link to={menuItem.path}>{menuItem.title}</Link>
										</li>
									);
								})}
							</ul>
						</li>
					);
				})}
			</Fragment>
		);
	}

	getPermittedMenuItems(webUserSession) {
		if (!webUserSession)
			return null;

		const menuItems = this.getAllMenuItems();

		// Remove menu items the user does not have permission to (iterate in reverse since removing items would change the index of higher indexes)
		for (let i = menuItems.length - 1; i >= 0; i--) {
			const topMenu = menuItems[i];
			for (let j = topMenu.menuItems.length - 1; j >= 0; j--) {
				const menuItem = topMenu.menuItems[j];
				let hasPermission = false;
				if (menuItem.requiredPermissions) {
					for (const requiredPermission of menuItem.requiredPermissions) {
						if (!requiredPermission || this.context.hasPermission("*", requiredPermission))
							hasPermission = true;
						break;
					}
				}

				if (!hasPermission)
					topMenu.menuItems.splice(j, 1); // remove the menu option since the user does not have permission
			}
			if (topMenu.menuItems.length === 0)
				menuItems.splice(i, 1); // remove the top level menu since there are no valid options available
		}

		return menuItems;
	}

	getAllMenuItems() {
		return [{
			title: "Files",
			menuItems: [{
				title: "Download Files",
				requiredPermissions: ["Download Entity Files"],
				path: "/download"
			}, {
				title: "Upload Files",
				requiredPermissions: ["Upload Entity Files"],
				path: "/upload"
			}, {
				title: "Resources for Uploading",
				requiredPermissions: ["Download Static Files"],
				path: "/uploadResources"
			}]
		}, {
			title: "Entities",
			menuItems: [{
				title: "New Entity...",
				requiredPermissions: ["Create Entities"],
				path: "/entityProfile/new"
			}, {
				title: "View/Edit Entity Profile",
				requiredPermissions: ["View Entity Profile"],
				path: "/entityProfile"
			}]
		}, {
			title: "Users",
			menuItems: [{
				title: "Users",
				requiredPermissions: ["View User Profiles"],
				path: "/userProfile"
			}, {
				title: "User Groups",
				requiredPermissions: ["View User Groups"],
				path: "/userGroups"
			}]
		}, {
			title: "Personalize",
			menuItems: [{
				title: "Plugins / Notifications",
				requiredPermissions: ["Change Own Plugin Settings"],
				path: "/userPlugins"
			}, {
				title: "Self Audit",
				requiredPermissions: ["Self Audit"],
				path: "/selfAudit"
			}]
		}, {
			title: "Plugins",
			menuItems: [{
				title: "Plugin Definitions",
				requiredPermissions: ["Change Plugin Definitions"],
				path: "/pluginDefinitions"
			}]
		}];
	}

	getUserDisplayName(webUserSession) {
		if (!webUserSession || !webUserSession.UserDetails)
			return null;

		let userDisplayName = `${webUserSession.UserDetails.FirstName} ${webUserSession.UserDetails.LastName}`;
		if (!userDisplayName || userDisplayName === " ")
			userDisplayName = webUserSession.UserDetails.Userame;

		return userDisplayName;
	}
}
