import React from "react";
import { Link as ReactLink } from "react-router-dom";

export default class Link extends React.Component {
	render() {
		let { to, onClick, children, ...rest } = this.props;
		if (to === undefined || to === null || to === " ") {
			to = " ";
			if (onClick) {
				const onClickOriginal = onClick;
				onClick = (e, a, b, c) => {
					e.preventDefault(); // Do not change the route if "to" is not set
					onClickOriginal(e);
				}
			}
		}

		return (
			<ReactLink to={to} onClick={onClick} {...rest}>
				{children}
			</ReactLink>
		);
	}
}