import React from "react";

export default class FileList extends React.Component {
	render() {
		return (
			<div>
				<h4><a href={this.props.url}>{this.props.filename}</a></h4>
				<span>{this.props.description}</span>
			</div>
		);
	}
}
