import React, { Fragment } from "react";

export default class EntryRow extends React.Component {
	render() {
		const { visible, label, title, children, labelTdStyle, innerDivStyle, ...rest } = this.props;

		if (visible === false)
			return (<Fragment />)

		return (
			<tr {...rest} title={title}>
				<td style={labelTdStyle}><div>{label}</div></td>
				<td>
					<div style={innerDivStyle}>
						{children}
					</div>
				</td>
			</tr >
		);
	}
}