import React, { Fragment } from "react";
import Link from "../../Shared/Link";

export default class PathBreadcrumbs extends React.Component {
	constructor(props) {
		super(props);

		this.getPathLinks = this.getPathLinks.bind(this);
	}

	render() {
		const label = this.props.label ? `${this.props.label}: ` : "";
		return (
			<Fragment>
				{label}
				{this.getPathLinks(this.props.path, this.props.baseRouteAlias)}
			</Fragment>
		);
	}

	getPathLinks(path, baseRouteAlias) {
		let builtPath = this.props.baseRoute;
		const pathNodeNames = [baseRouteAlias].concat(path.split("/")).filter((a)=> a !== "");

		let i = 0;
		return pathNodeNames.map((pathNodeName) => {
			i++;
			if (!(i === 1 && baseRouteAlias))
				builtPath += `/${pathNodeName}`;

			if (i === pathNodeNames.length) // The last node does not need to be a link
				return (<span key={i}>{i > 1 ? " / " : ""}{pathNodeName}</span>);

			return (<span key={i}>{i > 1 ? " / " : ""}<Link to={builtPath}>{pathNodeName}</Link></span>);
		});
	}
}
