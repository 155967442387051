import React, { Fragment } from "react";
import Link from "../../Shared/Link";
import LoadingWrapper from"../../Shared/LoadingWrapper";
import Layout from"../../Layout/Layout";
import { UserContext } from "../../Contexts/UserContext";
import { useApiService } from "../../Hooks/useApiService";
import { handleApiError } from "../../Shared/ErrorHandlers";

export default class Logout extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLogoutSuccess: false,
			errorMessage: null
		};

		this.apiService = useApiService(props.config.apiBaseUrl);
	}

	componentDidMount() {
		this.apiService.deleteWebUserSession(
			() => {
				this.context.setWebUserSession(null);

				this.setState({ isLoading: false, isLogoutSuccess: true });
			},
			(error) => {
				this.setState({ isLoading: false });
				handleApiError(error);
			}
		);
	}

	render() {
		return (
			<Layout config={this.props.config} subtitle="Log Out" requireWebUserSession={false}>
				<h1>Log Out</h1>
				<LoadingWrapper isLoading={this.state.isLoading} loadingMessage="Logging out...">
					{this.state.isLogoutSuccess === true ? (
						<Fragment>
							<p>You have been logged out.</p>
							<p><Link to="/login">Log in again</Link></p>
						</Fragment>
					) : (
						<p>Error logging out: {this.state.errorMessage}</p>
					)}
				</LoadingWrapper>
			</Layout>
		);
	}
}
